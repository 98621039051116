;(function (angular) {
   'use strict'

   angular.module('app').factory('addressService', addressService)

   function addressService($q, addressData) {
      //================================================================================
      // Helper Methods
      //================================================================================

      function isInternational(address) {
         return address.country && address.country.toUpperCase() !== 'UNITED STATES'
      }

      function filterNonInternational(addresses) {
         return addresses.filter(function (address) {
            return !isInternational(address)
         })
      }

      // Shipping addresses are those within the U.S.
      function getUserShippingAddresses(userId) {
         return addressData.getUserAddresses(userId).then(filterNonInternational)
      }

      function getUserPrimaryShippingAddress(userId) {
         return getUserShippingAddresses(userId).then(function (addresses) {
            if (addresses && addresses.length) {
               return addresses[0]
            }
         })
      }

      function getUserPrimaryAddress(userId) {
         return addressData.getUserAddresses(userId).then(function (addresses) {
            if (addresses && addresses.length) {
               return addresses[0]
            }
         })
      }

      var _minAddressKeys = ['street-address', 'postal-code', 'locality', 'region']

      function isSameAddress(address1, address2, onlyConsiderMiniumKeys) {
         if (!address1 || !address2) {
            return false
         }
         var keys = onlyConsiderMiniumKeys ? _minAddressKeys : _minAddressKeys.concat(['name', 'phone', 'country'])
         return keys.every(function (key) {
            return address1[key] === address2[key]
         })
      }

      function addressToString(address) {
         return _minAddressKeys
            .map(function (key) {
               return address[key]
            })
            .join(' ')
      }

      function stripCountryFromPhone(num) {
         if (num && num.startsWith('+1 ')) {
            return num.slice(3)
         } else {
            return num
         }
      }

      function addCountryToPhone(num) {
         if (num && num.startsWith('+1 ')) {
            return num
         } else {
            return '+1 ' + num
         }
      }

      function cleanPhone(num) {
         var phoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/
         if (num && !phoneRegex.test(num) && !num.startsWith('+1 ')) {
            num = num.replace(/[^0-9]/g, '') // strip non digit chars
            return num.slice(0, 11) // only use the first ten digits
         } else {
            return num
         }
      }

      //================================================================================
      // Data Update Methods
      //================================================================================

      function addAddress(userId, address) {
         return addressData.addAddress(userId, address)
      }

      function updateAddress(userId, address) {
         return addressData.updateAddress(userId, address.id, address)
      }

      function deleteAddress(userId, addressId) {
         return addressData.deleteAddress(userId, addressId)
      }

      function makeAddressPrimary(userId, addressId) {
         return getUserPrimaryAddress(userId).then(function (currentPrimaryAddress) {
            return $q.all([
               // Make selected primary
               addressData.updateAddress(userId, addressId, {
                  preference: currentPrimaryAddress.preference || 1,
               }),
               // Make primary not primary
               addressData.updateAddress(userId, currentPrimaryAddress.id, {
                  preference: 0,
               }),
            ])
         })
      }

      //================================================================================

      return {
         getUserPrimaryAddress: getUserPrimaryAddress,
         getUserPrimaryShippingAddress: getUserPrimaryShippingAddress,
         isSameAddress: isSameAddress,
         addressToString: addressToString,
         isInternational: isInternational,
         stripCountryFromPhone: stripCountryFromPhone,
         addCountryToPhone: addCountryToPhone,
         cleanPhone: cleanPhone,

         // Data Update Methods
         addAddress: addAddress,
         updateAddress: updateAddress,
         deleteAddress: deleteAddress,
         makeAddressPrimary: makeAddressPrimary,
      }
   }
})(angular)
