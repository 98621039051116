;(function (angular) {
   'use strict'

   angular.module('app').directive('asAlerts', alerts)

   function alerts() {
      return {
         templateUrl: 'partials/alerts.htm',
      }
   }
})(angular)
