;(function (angular) {
   'use strict'

   angular.module('app').factory('emailService', emailService)

   function emailService($q, util, emailData, personService) {
      function addEmail(userId, emailToAdd, password) {
         return emailData.addEmail(userId, emailToAdd, password)
      }

      function deleteEmail(userId, emailId) {
         return emailData.deleteEmail(userId, emailId)
      }

      function getCurrentPrimaryEmail(userId) {
         return emailData.getUserEmails(userId).then(util.first)
      }

      function clearEmailError(email, pw) {
         return emailData.updateEmail(
            email.person,
            email.id,
            {
               error: '',
            },
            pw
         )
      }

      function makeEmailPrimary(emailToMakePrimary, pw) {
         var userId = emailToMakePrimary.person
         return getCurrentPrimaryEmail(userId)
            .then(function (currentPrimaryEmail) {
               // Swap preference values for the old and new primary emails
               // Set other params to send in request

               return $q.all([
                  // Make primary not primary
                  emailData.updateEmail(
                     userId,
                     currentPrimaryEmail.id,
                     {
                        preference: emailToMakePrimary.preference,
                     },
                     pw
                  ),

                  // Make selected primary
                  emailData.updateEmail(
                     userId,
                     emailToMakePrimary.id,
                     {
                        preference: currentPrimaryEmail.preference,
                     },
                     pw
                  ),
               ])
            })
            .then(function () {
               // Changing the primary email address will change the `email` property on
               // the person object
               personService.personChangedExternal(userId)
            })
      }

      //================================================================================

      return {
         addEmail: addEmail,
         deleteEmail: deleteEmail,
         clearEmailError: clearEmailError,
         makeEmailPrimary: makeEmailPrimary,
      }
   }
})(angular)
