;(function (angular) {
   'use strict'

   angular.module('app').directive('asOrderTotalBox', asOrderTotalBox)

   function asOrderTotalBox() {
      return {
         controller: asOrderTotalBoxController,
      }
   }

   function asOrderTotalBoxController($scope, $timeout, $state, appState, productData, util) {
      //================================================================================
      // Sales flyer
      //================================================================================

      function showAddSalesFlyer() {
         return (
            $scope.orderState.order &&
            ($scope.orderState.isActiveCanShop || $scope.orderState.order.ensureRetailSalesFlyer) &&
            ($scope.orderState.order.isStatusPlaced || $state.includes('checkout')) &&
            !$scope.orderState.order.containsProductCode(productData.productCodes.salesFlyer) &&
            !appState.userState.isWholesale
         )
      }

      function calculateRoutesNextCutoffMonth() {
         var order = $scope.orderState.order
         if (showAddSalesFlyer() && !order.isParcelCarrier) {
            order.tripView.getNextTripOnRoute().then(function (trip) {
               // It's possible that no next trip exists yet (in which case `trip` here will be `undefined`)
               if (trip) {
                  $scope.orderTotalBoxState.routesNextCutoffMonth = util.getMonthFromDate(new Date(trip.cutoff))
               }
            })
         }
      }

      calculateRoutesNextCutoffMonth()

      $scope.showAddSalesFlyer = showAddSalesFlyer

      $scope.salesFlyerCheckboxChangeHandler = function () {
         $scope.orderState.updateEnsureRetailSalesFlyer($scope.orderTotalBoxState.ensureRetailSalesFlyer)
      }

      //================================================================================
      // Rewards
      //================================================================================

      function getTotalPriceMinusPromoCodeDiscounts() {
         return util.pennyRound($scope.orderState.order.totalPrice - $scope.orderState.order.totalPromoCodeDiscounts)
      }

      function getMaxAllocation() {
         return Math.min($scope.orderTotalBoxState.rewardsAvailable, getTotalPriceMinusPromoCodeDiscounts())
      }

      $scope.getMaxAllocation = getMaxAllocation
      $scope.showRewardsEditUi = false

      $scope.getDetailsRewardsAllocation = function () {
         return (
            ($scope.orderState.order.hasShipped
               ? $scope.orderState.order.rewardsUsed
               : Math.min($scope.orderState.order['rewards-allocation'], getTotalPriceMinusPromoCodeDiscounts())) || 0
         )
      }

      $scope.getCheckoutRewardsAllocation = function () {
         return $scope.orderTotalBoxState.rewardsToAllocateInvalid
            ? 0
            : Math.min(getTotalPriceMinusPromoCodeDiscounts(), $scope.orderTotalBoxState.rewardsToAllocate) || 0
      }

      $scope.openRewardsEditor = function () {
         $scope.showRewardsEditUi = true

         // Ensure that the editor value is set (needed on order detail where there are two instances of this directive)
         $scope.orderTotalBoxState.rewardsToAllocate = $scope.orderState.order['rewards-allocation']

         // Focus input
         $timeout(function () {
            document.getElementById('js-rewardsAllocatedEditUi').focus()
         })
      }

      $scope.closeRewardsEditor = function () {
         $scope.showRewardsEditUi = false
      }

      $scope.submitRewardsEditor = function () {
         if ($scope.orderTotalBoxState.rewardsToAllocateInvalid) {
            return
         }

         $scope.closeRewardsEditor()
         // If no change, do not update
         if ($scope.orderTotalBoxState.rewardsToAllocate === $scope.orderState.order['rewards-allocation']) {
            return
         }
         $scope.orderState.updateRewardsAllocation($scope.orderTotalBoxState.rewardsToAllocate, true)
      }

      //================================================================================
      // Watchers
      //================================================================================

      $scope.$watchGroup(['orderTotalBoxState.rewardsToAllocate', 'orderTotalBoxState.rewardsAvailable'], function () {
         $scope.orderTotalBoxState.rewardsToAllocateInvalid =
            $scope.orderTotalBoxState.rewardsToAllocate > $scope.orderTotalBoxState.rewardsAvailable
      })

      $scope.$watch('orderState.order.trip', function (newTrip, oldTrip) {
         if (newTrip !== oldTrip) {
            calculateRoutesNextCutoffMonth()
         }
      })
   }
})(angular)
