;(function (angular) {
   'use strict'

   /**
    * @ngdoc directive
    * @name app.directive:asProductCarousel
    * @description
    * # asProductCarousel
    * Display a carousel of products
    * @uses asProductGridItem
    */

   angular.module('app').directive('asProductCarousel', productCarousel)

   function productCarousel() {
      return {
         controller: productCarouselController,
         link: productCarouselLink,
         scope: {
            products: '=?',
            productList: '@?',
            productIds: '=?',
         },
         templateUrl: 'inlineTemplates/productCarousel.htm',
      }
   }

   function productCarouselController($q, $scope, $attrs, appState, viewService, util) {
      var productsPromise
      if ($scope.products) {
         productsPromise = $q.resolve($scope.products)
      } else {
         var productIds = $scope.productIds || $attrs.asProductCarousel.split(',')
         var productIdsAndCodes = productIds.map(function (productId) {
            return {id: productId}
         })
         productsPromise = viewService.getProductViews(productIdsAndCodes, {
            priceSettings: appState.userState.priceSettings,
         })
      }

      $scope.productsPromise = productsPromise.then(util.compact)
   }

   function productCarouselLink(scope, element) {
      scope.productsPromise.then(function (productViews) {
         scope.productViews = productViews

         var flickity

         var deregister = scope.$watch(
            function () {
               var ProductGridItem = element.find('.ProductGridItem')
               if (ProductGridItem.length === scope.productViews.length) {
                  return true
               }
            },
            function (value) {
               if (value) {
                  deregister()
                  scope.$evalAsync(function () {
                     var $carousel = element.find('.ProductCarousel')
                     var settings = element.data('settings') || {}

                     settings = angular.extend(
                        {
                           draggable: true,
                           wrapAround: true,
                           prevNextButtons: settings.azureShowNavAndCount ? false : true,
                           pageDots: settings.azureShowNavAndCount ? false : true,
                        },
                        settings
                     )

                     // Turn off arrows if only a single item
                     if (scope.productViews.length <= 1) {
                        settings.pageDots = false
                        settings.prevNextButtons = false
                        settings.azureNavButtons = false
                        settings.azureShowNavAndCount = false
                     }

                     scope.withFlickityArrows = settings.pageDots || settings.prevNextButtons

                     // For equal-height cell hack
                     // This adds the class `flickity-resized` when finished resizing,
                     // which we use in the CSS to make the items 100% height).
                     // See: https://github.com/metafizzy/flickity/issues/534#issuecomment-328859144
                     window.Flickity.prototype._createResizeClass = function () {
                        this.element.classList.add('flickity-resized')
                     }
                     window.Flickity.createMethods.push('_createResizeClass')
                     var resize = window.Flickity.prototype.resize
                     window.Flickity.prototype.resize = function () {
                        this.element.classList.remove('flickity-resized')
                        resize.call(this)
                        this.element.classList.add('flickity-resized')
                     }

                     flickity = new window.Flickity($carousel[0], settings)

                     // Handle nav
                     if (settings.azureShowNavAndCount) {
                        scope.showNavAndCount = true

                        scope.pressedNavPrev = function () {
                           flickity.previous(true)
                           scope.selectedIndex = flickity.selectedIndex
                        }
                        scope.pressedNavNext = function () {
                           flickity.next(true)
                           scope.selectedIndex = flickity.selectedIndex
                        }
                     }

                     scope.$broadcast('flickityLoaded')

                     setTimeout(function () {
                        flickity.resize()
                     })
                  })
               }
            }
         )

         scope.$on('$destroy', function () {
            if (flickity) {
               flickity.destroy()
            }
         })
      })
   }
})(angular)
