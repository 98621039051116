/* jshint -W106 */
;(function (angular, undefined) {
   'use strict'

   /**
    * @ngdoc directive
    * @name app.directive:asDropFinder
    * @description
    * # asDropFinder
    * Display and control the drop finder.
    */

   angular.module('app').directive('asDropFinder', dropFinder)

   function dropFinder() {
      return {
         templateUrl: 'partials/drop_finder.htm',
         scope: {
            bindToUrl: '=?',
            mapQuery: '=?',
            initAtUsersLocation: '=?',
            loadClosestDrop: '=?',
            cta: '@', // "call to action" options: "join" || "selectForShipment"
            stickyUiPanel: '@', // optionally always show the info panel (regardless of whether a drop is selected)
            singleModeDropId: '@?',
            noUiPanel: '@',
            onMapDropSelect: '&',
            showShippingFeeAsPercent: '=?',
            selectedDropsShippingFee: '=?',
            noStartOwnDropCta: '=?',
            onSelectedDropCleared: '&?',
            d2hState: '=?',
         },
         controller: dropFinderController,
      }
   }

   function dropFinderController(
      $scope,
      $rootScope,
      $q,
      $http,
      $window,
      $state,
      $filter,
      util,
      addressService,
      viewService,
      dropData,
      dropService,
      modalService,
      locationData,
      alerts,
      mapboxService,
      appState,
      config
   ) {
      var _mapboxgl
      var _map
      var _geojsonDrops
      var _geojsonDropsAll
      var _selectedDropId
      var _searchResultMarker
      var _d2hFeeLabelMarkers = []
      var _viewportXs = $window.matchMedia('(max-width: 480px)').matches
      var _minZoomToAllowDropClicks = 7
      var _uiPanelWidth = $scope.singleModeDropId ? 290 : 330
      var _uiPanelWidthMobile = 290 // includes offest from left edge of map container
      var _clickedDropsLayer = false
      var _initializing = true
      var _userDropIds = []
      var _usersGeojsonDrops = []
      var _dropRegex = /^D(\d+)$/i
      var _zoomMax = $scope.singleModeDropId ? 17.05 : 16.99
      var _zoomDropMatch = 15
      var _selectedDropCircleStrokeWidth = 8
      var _layerColorBlue = 'hsl(211, 84.2%, 47%)'
      var _layerColorGreen = 'hsl(84, 43%, 39%)'
      var _layerColorRed = 'hsl(7, 65%, 66%)'
      var _layerColorYellow = 'hsl(45, 100%, 72%)'
      var _layerColorNoHomeDelivery = '#797979'
      var _layerColorHasHomeDelivery = _layerColorBlue
      var _selectedDropOutlineColor = '#00f900'
      var _showSuperBtn = !$scope.singleModeDropId && appState.userState.superUserId
      if ($scope.initAtUsersLocation === undefined) {
         $scope.initAtUsersLocation = true
      }
      $scope.mapQuery = $state.params.q || ''
      var _localState = {
         queryInput: $scope.mapQuery,
         showingFullDetails: false,
         mapBusy: false,
         selectedDrop: undefined, // the drop selected for preview
         selectedDropRoutes: undefined,
         mostRelevantNotJoinedDropId: undefined,
         uiPanelToggledClosed: false,
         uiPanelBusy: false,
         dropMatchId: undefined,
         browserNotSupported: undefined,
         superMode: _showSuperBtn, // on by default
         showSuperBtn: _showSuperBtn,
         countNonClosedDrops: undefined,
         countAllDrops: undefined,
         showStartOwnDropCta:
            $scope.noStartOwnDropCta !== undefined ? false : !$rootScope.viewportSm && !$scope.singleModeDropId,
         d2hSelectedDropEstimate: undefined,
      }
      $scope.localState = _localState

      //================================================================================
      // Scope functions
      //================================================================================

      // Apparently it's possible to have an active drop with no upcoming trips...
      // The UI should not allow the user to select such a drop, since there are
      // no trips on it for them to assign their order.
      $scope.noNextShoppableTrip = function () {
         return (
            _localState.selectedDrop &&
            _localState.selectedDrop.stopViews &&
            !_localState.selectedDrop.nextShoppableStopView(appState.userState.superUserId)
         )
      }

      $scope.toggleUiPanel = function () {
         if (_localState.uiPanelToggledClosed) {
            openUiPanel()
         } else {
            closeUiPanel()
         }
      }

      function superModeOn() {
         _localState.superMode = true
         _map.setLayoutProperty('superDropsLayer', 'visibility', 'visible')
         _map.setLayoutProperty('dropsLayer', 'visibility', 'none')
      }

      function superModeOff() {
         _localState.superMode = false
         _map.setLayoutProperty('superDropsLayer', 'visibility', 'none')
         _map.setLayoutProperty('dropsLayer', 'visibility', 'visible')
      }

      $scope.toggleSuperMode = function () {
         if (_localState.superMode) {
            superModeOff()
         } else {
            superModeOn()
         }
         setDropLayerEventHandlers()
         if (_selectedDropId) {
            setDropToIsSelectedState(_selectedDropId)
         }
      }

      $scope.infoPanelShowing = function () {
         return _localState.selectedDrop || _localState.uiPanelBusy || $scope.stickyUiPanel
      }

      function selectedDropForPickupOrD2h(drop) {
         $scope.onMapDropSelect({drop: drop}).then(uiPanelBusyTrue).catch(angular.noop)
      }

      $scope.clickedPickUpAtDrop = selectedDropForPickupOrD2h

      $scope.clickedDeliverD2hViaDrop = selectedDropForPickupOrD2h

      $scope.clickedJoinDrop = function (drop) {
         var pending = !appState.userState.superUserId && _localState.selectedDrop.exclusivity !== 'open'

         var maybeConfirm
         if (drop.exclusivity === 'closed') {
            maybeConfirm = modalService.confirmJoiningClosedDrop()
         } else {
            maybeConfirm = $q.resolve()
         }

         return maybeConfirm
            .then(function () {
               uiPanelBusyTrue()
               return dropService
                  .addDropMembership(appState.userState.id, drop.id, pending)
                  .then(function () {
                     $state.go('account.drops', {}, {reload: true})
                  })
                  .catch(alerts.error)
                  .finally(uiPanelBusyFalse)
            })
            .catch(angular.noop)
      }

      $scope.submitSearch = function () {
         if (!_localState.queryInput) {
            return
         }
         if ($scope.bindToUrl) {
            $state.go('.', {q: _localState.queryInput})
         } else {
            $scope.mapQuery = _localState.queryInput
         }
      }

      $scope.shippingFee = function () {
         var nextShoppableStopView =
            _localState.selectedDrop && _localState.selectedDrop.nextShoppableStopView(appState.userState.superUserId)

         if (!nextShoppableStopView) {
            return
         }

         var fee
         var shippingFeePercent = nextShoppableStopView.stopDropShippingFeePercent

         if (shippingFeePercent === 0) {
            fee = 'Free'
         } else if ($scope.showShippingFeeAsPercent || appState.activeOrderState.order.isEmpty) {
            fee = shippingFeePercent + '%'
         } else {
            fee = $filter('dollars')((shippingFeePercent * appState.activeOrderState.order.linePrice) / 100)
         }

         $scope.selectedDropsShippingFee = fee

         return fee
      }

      //================================================================================

      function mapBusyTrue() {
         _localState.mapBusy = true
      }

      function mapBusyFalse() {
         _localState.mapBusy = false
      }

      function openUiPanel() {
         _localState.uiPanelToggledClosed = false
      }

      function closeUiPanel() {
         _localState.uiPanelToggledClosed = true
      }

      function uiPanelBusyTrue() {
         _localState.uiPanelBusy = true
      }

      function uiPanelBusyFalse() {
         _localState.uiPanelBusy = false
      }

      function clearSelectedDrop() {
         _localState.selectedDrop = undefined
         unselectSelectedDrop()
         if ($scope.onSelectedDropCleared) {
            $scope.onSelectedDropCleared()
         }
      }

      function unselectSelectedDrop() {
         if (_selectedDropId) {
            _map.setFeatureState({source: getCurrentDropsSource(), id: _selectedDropId}, {isSelected: false})
            _selectedDropId = undefined
         }
      }

      function removeSearchResultMarker() {
         if (_searchResultMarker) {
            _searchResultMarker.remove()
         }
      }

      function d2hAddFeeMarker(estimate) {
         var dropMapFeatureOfEstimate = getCurrentGeojsonDrops().find(function (feature) {
            return feature.id === estimate.dropId
         })
         var parentEl = document.createElement('div')
         // NOTE: The `cost` property here is the D2H fee plus the drop shipping fee (if there is one).
         parentEl.innerHTML = '<div class="MapMarker-d2hFee font-mono">' + $filter('currency')(estimate.cost) + '</div>'

         _d2hFeeLabelMarkers.push(
            new _mapboxgl.Marker(parentEl).setLngLat(dropMapFeatureOfEstimate.geometry.coordinates).addTo(_map)
         )
      }

      function d2hRemoveFeeMarkers() {
         _d2hFeeLabelMarkers.forEach(function (marker) {
            marker.remove()
         })
         _d2hFeeLabelMarkers = []
      }

      function noResultAlert() {
         return alerts.info({
            message: 'No results found for “' + _localState.queryInput + '”',
            autoClose: true,
            closeBtn: false,
         })
      }

      function getCurrentGeojsonDrops() {
         return _localState.superMode ? _geojsonDropsAll : _geojsonDrops
      }

      function getCurrentDropsSource() {
         return _localState.superMode ? 'superDropsSource' : 'dropsSource'
      }

      function getUsersLocation() {
         return addressService.getUserPrimaryShippingAddress(appState.userState.id).then(function (address) {
            if (address) {
               return {
                  address: address,
               }
            } else {
               // retrieve the user's location based on Ip address.
               return locationData.getIpLocation().catch(function () {
                  // could not get the location from the ip address
                  alerts.warningMessage("We could not detect your location. Please use the map's search box.")
               })
            }
         })
      }

      function loadSelectedDrop(dropId) {
         uiPanelBusyTrue()
         var promises = [
            viewService.getDropView(dropId, {
               stopViews: {
                  tripView: {},
               },
            }),
         ]
         if (appState.userState.superUserId) {
            promises.push(dropData.getDropRoutes(dropId))
         }

         return $q.all(promises).then(function (results) {
            var drop = results[0]
            _localState.selectedDropRoutes = results[1]

            drop.joined = _userDropIds.includes(drop.id)

            // Ensure that `nextShoppableStopView` has resolved before setting the busy indicator to false (it's resolved when `drop.stopViewsPromise` has resolved)
            return drop.stopViewsPromise
               .then(function () {
                  _localState.selectedDrop = drop
                  _localState.selectedDrop.directionsUrl =
                     'https://maps.apple.com/maps?daddr=' + drop.geo.latitude + ',' + drop.geo.longitude
               })
               .finally(uiPanelBusyFalse)
         })
      }

      function setDropToIsSelectedState(dropId) {
         _selectedDropId = dropId

         // This check prevents a runtime error that's thrown if
         // calling `setFeatureState` before the drop source has loaded.
         if (_map.getSource(getCurrentDropsSource())) {
            _map.setFeatureState({source: getCurrentDropsSource(), id: _selectedDropId}, {isSelected: true})
         }
      }

      function selectDropInMap(dropId, shouldOpenPanel) {
         if (shouldOpenPanel === undefined) {
            shouldOpenPanel = true
         }

         // Only allow one drop to be selected at a time
         unselectSelectedDrop()

         if ($scope.cta === 'selectForD2h') {
            _localState.d2hSelectedDropEstimate = $scope.d2hState.getEstimateForSelectedAddressByDropId(dropId)
         } else {
            _localState.d2hSelectedDropEstimate = undefined
         }

         loadSelectedDrop(dropId)
         if (shouldOpenPanel) {
            openUiPanel()
         }

         setDropToIsSelectedState(dropId)
      }

      function selectDropInMapSingleMode(coordinates, drop) {
         loadSelectedDrop(drop.id)
         addResultMarkerToMap({
            place_name: drop.name,
            center: coordinates,
         })
      }

      function handleDropSelectOnInit(dropId) {
         selectDropInMap(dropId)
         _map.once('load', function () {
            setDropToIsSelectedState(dropId)
         })
      }

      function addResultMarkerToMap(feature) {
         // Only allow one search result marker at a time
         removeSearchResultMarker()

         var markerLabelText = feature.place_name
            ? feature.place_name.replace(', United States', '')
            : _localState.queryInput

         var parentEl = document.createElement('div')
         parentEl.setAttribute('class', 'MapMarker-wrap--searchResult')
         parentEl.innerHTML =
            '<svg class="MapMarker-searchResult" width="30" height="60" viewBox="0 0 30 60" fill="none" xmlns="http://www.w3.org/2000/svg"><ellipse cx="14.9905" cy="57.8318" rx="7" ry="2" fill="#C4C4C4" fill-opacity="0.2"/><path d="M30 15C30 25.1314 15 32.3915 15 57.8318C15 32.3006 0 25.0912 0 15C0 4.9088 6.71573 0 15 0C23.2843 0 30 6.71573 30 15Z"/><circle class="MapMarker-searchResultPinCircle" cx="15" cy="14.075" r="6.9905"/></svg>' +
            '<div class="MapMarker-searchResultLabel">' +
            markerLabelText +
            '</div>'

         _searchResultMarker = new _mapboxgl.Marker(parentEl).setLngLat(feature.center).addTo(_map)
      }

      function checkForDropMatchByIdOrExactName(query) {
         // If the query begins with a `d` or `D`, check for exact drop ID match
         // Note: The reasoning for the "d" prefix is to avoid conflicts with
         // zip codes (e.g. `19511`) without having to prioritize either query (drop id vs zip code).
         // In other words, this lets us support lookup by id for all drops and lookup by zip code for all zip codes.
         if (_dropRegex.test(query)) {
            var dropId = parseInt(query.replace(_dropRegex, '$1'))
            return getCurrentGeojsonDrops().find(function (feature) {
               return feature.id === dropId
            })
         }
         // Check for exact drop name match
         else {
            return getCurrentGeojsonDrops().find(function (feature) {
               return feature.properties.name.toUpperCase().trim() === query.toUpperCase().trim()
            })
         }
      }

      function handleFeatureMatch(feature) {
         if (!feature) {
            return
         }
         var placeTypesToLoadDrop = ['address', 'postcode', 'neighborhood', 'place']
         if (placeTypesToLoadDrop.includes(feature.place_type[0])) {
            return goToMostRelevantUnjoinedDrop(feature)
         } else {
            return goToFeature(feature)
         }
      }

      function checkForFuzzyDropMatch(query) {
         function normalizeToFuzzy(str) {
            return str.replace(/[-'\s]+/g, '').toUpperCase()
         }

         return getCurrentGeojsonDrops().find(function (drop) {
            return normalizeToFuzzy(drop.properties.name).includes(normalizeToFuzzy(query))
         })
      }

      function searchForDropOrFeature(query) {
         var result = {query: query}
         if (!query) {
            return $q.resolve(result)
         }

         var maybeExactDropNameMatch = checkForDropMatchByIdOrExactName(query)

         if (maybeExactDropNameMatch) {
            result.drop = maybeExactDropNameMatch
            return $q.resolve(result)
         }

         return queryMapbox(query).then(function (feature) {
            if (feature) {
               result.feature = feature
            } else {
               result.drop = checkForFuzzyDropMatch(query)
            }
            return result
         })
      }

      function goToSearchResult(result) {
         if (result.drop) {
            return handleDropMatch(result.drop)
         } else if (result.feature) {
            return handleFeatureMatch(result.feature)
         } else {
            return noResultAlert()
         }
      }

      function executeSearch(query) {
         if (!query) {
            return
         }

         alerts.clear()
         unselectSelectedDrop()
         removeSearchResultMarker()

         mapBusyTrue()
         return searchForDropOrFeature(query).then(goToSearchResult).catch(alerts.error).finally(mapBusyFalse)
      }

      function queryMapbox(query) {
         return $http
            .get(
               config.mapbox.geocodeUrl +
                  encodeURIComponent(query) +
                  '.json?country=us&access_token=' +
                  config.mapbox.token
            )
            .then(function (response) {
               var data = response.data

               if (!data.features.length) {
                  return
               }

               // For all potential `place_type` values, see: https://docs.mapbox.com/api/search/#data-types
               var placeTypeWhitelist = ['address', 'country', 'neighborhood', 'place', 'postcode', 'region']

               var feature = data.features[0]
               if (!placeTypeWhitelist.includes(feature.place_type[0])) {
                  return
               }

               return $q.resolve(feature)
            })
      }

      function setOffset(hasDropMatch) {
         if ($scope.noUiPanel || (!hasDropMatch && !$scope.stickyUiPanel) || _viewportXs) {
            return [0, 0]
         } else if ($rootScope.viewportSm) {
            return [_uiPanelWidthMobile / 2, 0]
         } else {
            return [_uiPanelWidth / 2, 0]
         }
      }

      function handleDropMatch(drop) {
         // Note that `drop` here is formatted from the geojson file
         uiPanelBusyTrue()
         _localState.dropMatchId = drop.id

         _map.flyTo({
            center: drop.geometry.coordinates,
            offset: setOffset(true),
            zoom: _zoomDropMatch,
         })

         handleDropSelectOnInit(drop.id)
      }

      function goToMostRelevantUnjoinedDrop(feature) {
         /* 
         "Most relevant drop" is defined as:
         - A drop that the user is not yet a member of.
         - If NOT Drop-to-Home, closest (in terms of distance) to the feature's center.
         - If Drop-to-Home, the drop with the lowest fee.
         */

         var numberOfDropsToGet = _userDropIds.length ? _userDropIds.length + 1 : 1
         return dropData
            .getClosestActiveDropIds(feature.center, numberOfDropsToGet, true)
            .then(function (dropIds) {
               addResultMarkerToMap(feature)

               var mostRelevantNotJoinedDropId
               if ($scope.cta === 'selectForD2h') {
                  // Note that this returns the lowest-fee option (which might not be the closest).
                  // This is because the drop IDs are sorted by fee (lowest to highest, via `orderData.getD2hQualifyingEstimates`)
                  // and `Array.find` returns the first element that satisfies the condition.
                  var d2hDropIdsForSelectedAddress = $scope.d2hState.getDropIdsForSelectedAddress()
                  mostRelevantNotJoinedDropId =
                     d2hDropIdsForSelectedAddress &&
                     d2hDropIdsForSelectedAddress.find(function (dropId) {
                        return !_userDropIds.includes(dropId)
                     })
               } else {
                  mostRelevantNotJoinedDropId = dropIds.find(function (dropId) {
                     return !_userDropIds.includes(dropId)
                  })
               }

               // Note: Though we already have the drop coordinates here from the above `dropData` layer response,
               // there seem to be some inconsistencies in these and those from the geojson file.
               // Using the geojson coordinates as the source of truth here prevents the selected
               // drop marker from being "off" (i.e. being close to the drop but not exactly over it).
               var geojsonDrop = getCurrentGeojsonDrops().find(function (feature) {
                  return feature.id === mostRelevantNotJoinedDropId
               })

               mapBusyFalse()

               // Handle no geojsonDrop being found.
               // This is an edge case that should not be possible.
               // Currently this IS possible for users with superuser privileges (since the `drops` endpoint returns `exclusivity: closed` drops for these users)
               if (!geojsonDrop) {
                  clearSelectedDrop()
                  _map.flyTo({center: feature.center})
                  return
               }

               uiPanelBusyTrue()

               _localState.mostRelevantNotJoinedDropId = mostRelevantNotJoinedDropId
               var closestDropLngLat = geojsonDrop.geometry.coordinates
               var bounds = new _mapboxgl.LngLatBounds(
                  closestDropLngLat,
                  // By including in the bounds the mirror image of the drop coordinates
                  // with respect to lat/lng, we ensure that lat/lng remains centered.
                  [feature.center[0] * 2 - closestDropLngLat[0], feature.center[1] * 2 - closestDropLngLat[1]]
               )

               var baseOffset = 100
               var leftOffset
               if (_viewportXs) {
                  leftOffset = 40
               } else if ($rootScope.viewportSm) {
                  leftOffset = baseOffset + _uiPanelWidthMobile
               } else {
                  leftOffset = baseOffset + _uiPanelWidth
               }

               _map.fitBounds(bounds, {
                  padding: {
                     top: baseOffset,
                     bottom: baseOffset,
                     left: leftOffset,
                     right: _viewportXs || $rootScope.viewportSm ? 40 : baseOffset,
                  },
                  maxZoom: _zoomDropMatch,
               })

               handleDropSelectOnInit(mostRelevantNotJoinedDropId)
            })
            .catch(function (error) {
               console.error(error)
               return $q.reject(error)
            })
      }

      function goToFeature(feature) {
         clearSelectedDrop()
         addResultMarkerToMap(feature)
         mapBusyFalse()
         var bounds = feature.bbox || _map.getBounds()
         var fitBoundsOptions = {
            center: feature.center,
            offset: setOffset(),
         }
         _map.fitBounds(bounds, fitBoundsOptions)
      }

      //================================================================================
      // Map event handlers
      //================================================================================

      function getActiveDropsLayer() {
         return _localState.superMode ? 'superDropsLayer' : 'dropsLayer'
      }

      function handleDropLayerClick(e) {
         if (_map.getZoom() < _minZoomToAllowDropClicks) {
            return
         }

         selectDropInMap(e.features[0].id, true)

         // Maybe pan
         if (!_viewportXs) {
            // Pixel coordinates, relative to the map's container, that correspond to the drop's geographic location
            var dropXY = _map.project(e.features[0].geometry.coordinates)
            var dropX = dropXY.x
            var dropY = dropXY.y

            var mapDimensions = _map.getContainer().getBoundingClientRect()
            var panXIfLessThan = ($rootScope.viewportSm ? _uiPanelWidthMobile : _uiPanelWidth) + 40
            var panX
            var circleRadius = e.features[0].layer.paint['circle-radius'] + 2 * _selectedDropCircleStrokeWidth

            switch (true) {
               // Case: overflowing to left
               case dropX < panXIfLessThan:
                  panX = -(panXIfLessThan - dropX)
                  break
               // Case: Overflowing to right
               case dropX > mapDimensions.width - circleRadius:
                  panX = dropX - mapDimensions.width - circleRadius * -1
                  break
               default:
                  panX = 0
            }

            var panY
            switch (true) {
               // Case: overflowing to top
               case dropY < 65:
                  panY = dropY - circleRadius
                  break
               // Case: overflowing to bottom
               case dropY > mapDimensions.height - circleRadius:
                  panY = dropY - mapDimensions.height - circleRadius * -1
                  break
               default:
                  panY = 0
            }

            _map.panBy([panX, panY])
         }
      }

      function setDropLayerEventHandlers() {
         if (!$scope.singleModeDropId) {
            _map.on('click', getActiveDropsLayer(), handleDropLayerClick)

            if (!$scope.singleModeDropId) {
               _map.on('mouseenter', getActiveDropsLayer(), function () {
                  if (_map.getZoom() > _minZoomToAllowDropClicks) {
                     _map.getCanvas().style.cursor = 'pointer'
                     _clickedDropsLayer = true
                  }
               })

               _map.on('mouseleave', getActiveDropsLayer(), function () {
                  _map.getCanvas().style.cursor = ''
                  _clickedDropsLayer = false
               })
            }
         }
      }

      //================================================================================
      // Functions for setting shared drop layer properties
      //================================================================================

      // prettier-ignore
      var _baseDropLayerPaintProps = {
         // Note: These arrays are Mapbox "expressions" (Mapbox's approach to allowing data-driven/state-based styling)
         // Guide: https://docs.mapbox.com/help/tutorials/mapbox-gl-js-expressions/
         // Docs: https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions
         'circle-opacity': [
            'interpolate', ['linear'], ['zoom'],
            4, 1, // at zoom value, set circle-opacity value
            12, 0.43,
         ],
         'circle-stroke-color': ["case",
            ["boolean", ["feature-state", "isSelected"], false],
            _selectedDropOutlineColor,
            '#ffffff'
         ],
         'circle-stroke-width': ["case",
            ["boolean", ["feature-state", "isSelected"], false],
            _selectedDropCircleStrokeWidth,
            1
         ],
         'circle-radius': [
            'interpolate', ['exponential', 2], ['zoom'], // on `exponential` here, see: https://github.com/mapbox/mapbox-gl-function/pull/18
            4, 3, // at zoom value, set circle-radius value
            5, 5.75,
            8.5, 23,
            12, 30,
            15, ['case', ['get', 'geoObscured'], 100, 40], // at zoom value 15, if `geoObscured`, set circle-radius to 100, else set circle-radius to 40
            _zoomMax, ['case', ['get', 'geoObscured'], 400, 22],
         ],
      }

      //================================================================================
      // Initialization Handlers
      //================================================================================

      function initMapboxGl(initAt) {
         return mapboxService.load().then(function () {
            _mapboxgl = $window.mapboxgl
            if (!_mapboxgl.supported()) {
               _localState.browserNotSupported = true
               mapBusyFalse()
               console.log(_mapboxgl.notSupportedReason())
               return $q.reject()
            }
            _mapboxgl.accessToken = config.mapbox.token
            function setInitAtCenter() {
               if ($rootScope.viewportSm) {
                  return initAt || [-98.5795, 39.8283] // center of US (not including AK and HI)
               } else {
                  return initAt || [-118.719174, 47.64778] // center of US (including AK and HI)
               }
            }
            _map = new _mapboxgl.Map({
               container: 'js-dropMap', // HTML container id
               style: 'mapbox://styles/mapbox/streets-v12?optimize=true', // style URL
               attributionControl: false,
               center: setInitAtCenter(),
               zoom: initAt ? 14 : 2.2,
               maxZoom: _zoomMax, // to prevent being able to zoom in too close to negate the usage of blurring exact locations
            })
            _map.on('load', function () {
               // -------------------------------------------------------
               // Maybe load drops layer

               if (!$scope.singleModeDropId) {
                  _map
                     .addSource('dropsSource', {
                        type: 'geojson',
                        // Geojson example: https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson
                        // On the quesion of whether to point to the file (`/drops.geojson`) or the object (`_geojsonDrops`):
                        // https://github.com/mapbox/mapbox-gl-js/issues/1762
                        data: {
                           type: 'FeatureCollection',
                           features: _geojsonDrops,
                        },
                     })
                     .addSource('usersdropsSource', {
                        type: 'geojson',
                        data: {
                           type: 'FeatureCollection',
                           features: _usersGeojsonDrops,
                        },
                     })

                  if ($scope.cta === 'selectForD2h') {
                     d2hRemoveFeeMarkers()
                     var d2hEstimatesForSelectedAddress = $scope.d2hState.getEstimatesForSelectedAddress()
                     if (d2hEstimatesForSelectedAddress) {
                        d2hEstimatesForSelectedAddress.forEach(d2hAddFeeMarker)
                     }

                     var d2hDropIdsForSelectedAddress = $scope.d2hState.getDropIdsForSelectedAddress()

                     var isInD2hDropIdOptionsExpression = ['match', ['get', 'id']]

                     if (d2hDropIdsForSelectedAddress) {
                        d2hDropIdsForSelectedAddress.forEach((id) => {
                           // Prevent duplicate entries
                           if (!isInD2hDropIdOptionsExpression.includes(id)) {
                              isInD2hDropIdOptionsExpression.push(id, _layerColorHasHomeDelivery)
                           }
                        })
                     }
                     isInD2hDropIdOptionsExpression.push(_layerColorNoHomeDelivery) // The fallback value

                     _map.addLayer({
                        id: 'dropsLayer',
                        type: 'circle',
                        'symbol-z-order': 'source',
                        source: 'dropsSource',
                        paint: angular.extend({}, _baseDropLayerPaintProps, {
                           'circle-color': isInD2hDropIdOptionsExpression,
                        }),
                     })
                  } else {
                     _map
                        .addLayer({
                           id: 'dropCheckmark',
                           type: 'symbol',
                           source: 'usersdropsSource',
                           'symbol-z-order': 'source',
                           layout: {
                              'text-field': '✔',
                              'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                              'text-size': {
                                 stops: [
                                    [4, 2],
                                    [12, 20],
                                 ],
                              },
                           },
                           paint: {
                              'text-color': '#fff',
                           },
                        })
                        .addLayer(
                           {
                              id: 'dropsLayer',
                              type: 'circle',
                              'symbol-z-order': 'source',
                              source: 'dropsSource',
                              paint: angular.extend({}, _baseDropLayerPaintProps, {
                                 'circle-color': _layerColorBlue,
                              }),
                           },
                           'dropCheckmark'
                        )
                  }

                  if (_showSuperBtn) {
                     _map
                        .addSource('superDropsSource', {
                           type: 'geojson',
                           // Geojson example: https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson
                           // On the quesion of whether to point to the file (`/drops.geojson`) or the object (`_geojsonDrops`):
                           // https://github.com/mapbox/mapbox-gl-js/issues/1762
                           data: {
                              type: 'FeatureCollection',
                              features: _geojsonDropsAll,
                           },
                        })
                        .addLayer(
                           {
                              id: 'superDropsLayer',
                              type: 'circle',
                              'symbol-z-order': 'source',
                              source: 'superDropsSource',
                              // prettier-ignore
                              paint: angular.extend({}, _baseDropLayerPaintProps, {
                                 'circle-color': [
                                    'match',
                                       ['get', 'exclusivity'], // condition
                                       // conditions
                                       'open', _layerColorGreen, // if open, set to green
                                       'closed', _layerColorRed, // if closed, set to red
                                       _layerColorYellow, // else, is semi-open, set to yellow
                                 ]
                              })
                           },
                           $scope.cta !== 'selectForD2h' ? 'dropCheckmark' : undefined
                        )
                     // Set initial superuser map state
                     superModeOn()
                  }
               }

               // -------------------------------------------------------
               // Init map handlers and controls

               _map.addControl(new _mapboxgl.AttributionControl({compact: true}), 'bottom-left')

               _map.on('click', function (e) {
                  var currentZoom = _map.getZoom()
                  if (currentZoom < _minZoomToAllowDropClicks) {
                     _map.easeTo({
                        zoom: currentZoom + 1,
                        around: e.lngLat,
                     })
                  }
                  // If the click was not on a drop circle
                  if (_selectedDropId && !_clickedDropsLayer && !$scope.singleModeDropId) {
                     clearSelectedDrop()
                     $scope.$digest()
                  }
               })

               setDropLayerEventHandlers()

               if (!$rootScope.viewportSm && !$scope.singleModeDropId) {
                  _map.addControl(
                     new _mapboxgl.NavigationControl({
                        showCompass: false,
                     }),
                     'bottom-right'
                  )

                  _map.addControl(
                     new _mapboxgl.FullscreenControl({
                        container: document.getElementById('js-dropFinderContainer'),
                     }),
                     'bottom-right'
                  )
               }

               // -------------------------------------------------------

               mapBusyFalse()
               $scope.$digest()
            })
         })
      }

      function initMapWithQuery(query) {
         _localState.queryInput = query

         return searchForDropOrFeature(query).then(function (result) {
            var initAtCoordinates
            if (result.drop) {
               initAtCoordinates = result.drop.geometry.coordinates
            } else if (result.feature) {
               initAtCoordinates = result.feature.center
            }
            return initMapboxGl(initAtCoordinates).then(function () {
               return goToSearchResult(result)
            })
         })
      }

      function initSingleMode() {
         var promises = [
            dropData.getDrop($scope.singleModeDropId),
            dropData.getUserActiveDropIds(appState.userState.id),
         ]
         return $q.all(promises).then(function (results) {
            var drop = results[0]
            var lngLat = [drop.geo.longitude, drop.geo.latitude]
            _userDropIds = results[1]
            return initMapboxGl(lngLat).then(function () {
               _map.flyTo({
                  center: lngLat,
                  offset: setOffset(true),
                  duration: 0,
               })
               selectDropInMapSingleMode(lngLat, drop)
            })
         })
      }

      function loadAndSetGeojsonSource(userDrops) {
         var promises = [$http.get('/_data-dropsNotClosed.geojson')]
         var userWithClosedDrop =
            userDrops &&
            userDrops.find(function (userDrop) {
               return userDrop.exclusivity === 'closed'
            })

         if (userWithClosedDrop || _showSuperBtn) {
            promises.push($http.get('/_data-dropsClosed.geojson'))
         }

         return $q.all(promises).then(function (response) {
            var dropsNotClosed = response[0].data.features
            var dropsClosed = response[1] && response[1].data.features

            _geojsonDrops = dropsNotClosed
            _localState.countNonClosedDrops = dropsNotClosed.length
            if (_showSuperBtn) {
               _geojsonDropsAll = dropsNotClosed.concat(dropsClosed)
               _localState.countAllDrops = _geojsonDropsAll.length
            }

            if (!userDrops) {
               if (dropsClosed) {
                  _geojsonDrops.push(dropsClosed)
               }
               return $q.resolve()
            }

            _userDropIds = util.mapToIds(userDrops)

            function getDropFromGeojsonById(geojson, dropId) {
               return geojson.find(function (drop) {
                  return drop.id === dropId
               })
            }

            userDrops.forEach(function (drop) {
               var dropFromGeojson =
                  drop.exclusivity === 'closed'
                     ? getDropFromGeojsonById(dropsClosed, drop.id)
                     : getDropFromGeojsonById(dropsNotClosed, drop.id)

               if (dropFromGeojson) {
                  // Modify some properties. For the user's drops, we want the exact coordinates
                  dropFromGeojson.geometry.coordinates = [drop.geo.longitude, drop.geo.latitude]
                  dropFromGeojson.properties.geoObscured = false

                  _usersGeojsonDrops.push(dropFromGeojson)
               }
            })

            _usersGeojsonDrops.forEach(function (userGeojsonDrop) {
               var modifyAtIndex = _geojsonDrops.findIndex(function (drop) {
                  return drop.id === userGeojsonDrop.id
               })
               if (modifyAtIndex !== -1) {
                  _geojsonDrops[modifyAtIndex] = userGeojsonDrop
               } else {
                  _geojsonDrops.push(userGeojsonDrop)
               }
            })

            return $q.resolve()
         })
      }

      function initMultiMode() {
         return dropData
            .getUserActiveDrops(appState.userState.id)
            .then(loadAndSetGeojsonSource)
            .then(function () {
               // Init map with query
               if ($state.params.q) {
                  initMapWithQuery($state.params.q)
               }
               // If in d2h mode, init map at selected address
               else if ($scope.cta === 'selectForD2h') {
                  _localState.queryInput = $filter('addressString')($scope.d2hState.memStoreOfSelectedAddress, true)
                  initMapWithQuery(_localState.queryInput)
               }
               // Or, init map at user's location
               else if (appState.userState.id && $scope.initAtUsersLocation) {
                  getUsersLocation().then(function (response) {
                     if (!response) {
                        initMapboxGl()
                     } else if (response.address) {
                        _localState.queryInput = $filter('addressString')(response.address, true)
                        initMapWithQuery(_localState.queryInput)
                     } else if (response.geo) {
                        var lngLat = response.geo.longitude + ', ' + response.geo.latitude
                        _localState.queryInput = lngLat
                        initMapWithQuery(lngLat)
                     }
                  })
               }
               // Or, simply init map
               else {
                  initMapboxGl()
               }

               $scope.$watch('mapQuery', function (query) {
                  // Prevent watcher from firing on init
                  if (_initializing) {
                     _initializing = false
                     return
                  }

                  executeSearch(query)
               })
            })
      }

      //================================================================================
      // Initialization
      //================================================================================

      function init() {
         mapBusyTrue()

         if ($scope.singleModeDropId) {
            setTimeout(initSingleMode)
         } else {
            initMultiMode()
         }
      }

      init()
   }
})(angular)
