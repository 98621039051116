;(function (angular) {
   'use strict'

   angular.module('app').directive('asSuperuserOnlyIcon', superuserOnlyIcon)

   function superuserOnlyIcon() {
      return {
         templateUrl: 'partials/superuser_only_icon.htm',
      }
   }
})(angular)
