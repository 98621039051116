;(function (angular) {
   'use strict'

   angular.module('app').directive('asSearch', search)

   function search() {
      return {
         controller: searchController,
      }
   }

   function searchController(
      $rootScope,
      $scope,
      $q,
      $transitions,
      $state,
      $attrs,
      $timeout,
      appState,
      brandData,
      categoryData,
      searchService,
      googleTagManagerService,
      util
   ) {
      $scope.query = {}
      var _featurePath = $scope.featurePath + ':search'
      var _searchInputId = $attrs.searchInputId
      var _selectedIndex
      var _queryPrefix
      var _keyedInSearch = false

      //================================================================================
      // Search Input
      //================================================================================

      function focusSearchInput() {
         document.getElementById(_searchInputId).focus()
      }

      $scope.toggleSearchInputFocus = function () {
         if ($scope.showSearchResultsDropdown) {
            clearOrResetSearch()
         } else {
            focusSearchInput()
         }
      }

      function resetSearch(clearQuery) {
         if (clearQuery) {
            $scope.query.q = undefined
         }
         _selectedIndex = undefined
         _queryPrefix = undefined
         _keyedInSearch = false
         $scope.selectedIndex = _selectedIndex
         $scope.showSearchResultsDropdown = false
         $rootScope.removeUnderlayClass()
      }

      $scope.resetSearch = resetSearch

      function clearOrResetSearch() {
         $scope.$evalAsync(function () {
            if (_keyedInSearch) {
               clearSearch()
            } else {
               resetSearch()
            }
         })
      }

      function clearSearch() {
         resetSearch(true)
      }

      function cleanQuery() {
         if (!$scope.query.q) {
            return ''
         }
         return $scope.query.q.trim().toLowerCase()
      }

      // Note: From testing this doesn't seem to working reliably on mobile devices
      $scope.clearSearchAndFocus = function () {
         clearSearch()
         focusSearchInput()
      }

      function submitSearch() {
         var query = cleanQuery()

         if (!query) {
            return
         }

         searchService.saveOrUpdateSearchQuery(appState.userState.id, query)

         var queryForGaEvent
         var featurePathForGa

         // Search submitted via non autocomplete
         if (_selectedIndex === undefined || !$scope.searchQueries[_selectedIndex]) {
            queryForGaEvent = query
            featurePathForGa = _featurePath
         }
         // Search submitted via autocomplete
         else {
            queryForGaEvent = $scope.searchQueries[_selectedIndex].query
            featurePathForGa = _featurePath + ':autocomplete'
         }

         // Fires this GA4 built-in "recommended" event: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#search
         googleTagManagerService.pushEvent({
            event: 'search',
            search_term: queryForGaEvent,
            featurePath: featurePathForGa,
         })

         resetSearch()
         return searchService.shopSearch(query)
      }

      $scope.submitSearch = submitSearch

      //================================================================================
      // Search Autocomplete (realtime results, not submitted view)
      //================================================================================

      $scope.searchQueries = []
      var _minimumQueryLength = 1
      var _searchQueriesMinimum = 10
      var _searchQueriesLimit = 10
      var _searchQueryMaxWords = 4
      var _digitsOnlyRegex = /^\d+$/
      $scope.searchQueriesLimit = _searchQueriesLimit

      function wordCount(str) {
         return str.replace('-', ' ').split(' ').length
      }

      function wordCountAscComparer(str1, str2) {
         return wordCount(str1) - wordCount(str2)
      }

      function numberDescComparer(number1, number2) {
         return number2 - number1
      }

      function isHashTagQuery() {
         return _queryPrefix.startsWith('#')
      }

      function queryPrefixComparer(query1, query2) {
         // If the user's search query does not start with '#', then treat the comparison queries as if they don't start
         // with '#' either.
         if (!isHashTagQuery()) {
            var hashTag = /^#/
            query1 = query1.replace(hashTag, '')
            query2 = query2.replace(hashTag, '')
         }
         var query1PrefixMatches = query1.startsWith(_queryPrefix)
         var query2PrefixMatches = query2.startsWith(_queryPrefix)
         if (query1PrefixMatches && !query2PrefixMatches) {
            return -1
         }
         if (!query1PrefixMatches && query2PrefixMatches) {
            return 1
         }
         return 0
      }

      function queryPrefixAndWordCountComparer(query1, query2) {
         var result = queryPrefixComparer(query1, query2)
         if (result === 0) {
            result = wordCountAscComparer(query1, query2)
         }
         return result
      }

      function searchQueryComparer(searchQuery1, searchQuery2) {
         var result = queryPrefixComparer(searchQuery1.query, searchQuery2.query)
         if (result === 0) {
            result = numberDescComparer(searchQuery1.savedCount, searchQuery2.savedCount)
         }
         if (result === 0) {
            result = numberDescComparer(searchQuery1.score, searchQuery2.score)
         }
         return result
      }

      // This calculates a coefficient between 0 and 1 to be multiplied with
      // `category.featured`, based on the number of words in the query (which is either
      // the category name or a keyword).
      // A single word query results in a coefficient of 1. Additional words decreases the
      // coefficient. This has the effect of reducing the scores of queries with more
      // words. The formula can be tweaked to affect the ordering
      function categoryQueryCoefficient(query) {
         var queryWordCount = wordCount(query)
         // The word count that results in a score of zero is slightly greater than
         // `_searchQueryMaxWords`, thus giving queries with the maximum number of words
         // a very low score
         var zeroScoreWordCount = _searchQueryMaxWords + 0.01
         var exponent = 0.5

         return Math.pow(Math.log1p(zeroScoreWordCount - queryWordCount) / Math.log(zeroScoreWordCount), exponent)
      }

      function searchCategories() {
         return categoryData.searchCategories(
            _queryPrefix,
            {
               attributesToHighlight: '',
               restrictSearchableAttributes: 'name,keywords',
               attributesToRetrieve: 'name,keywords,featured,depth',
               hitsPerPage: 100,
            },
            false
         )
      }

      function searchBrands() {
         return brandData.searchBrands(
            _queryPrefix,
            {
               attributesToHighlight: '',
               attributesToRetrieve: 'name',
               hitsPerPage: 10,
            },
            false
         )
      }

      function findCategory(categories, query) {
         if (!categories) {
            return
         }

         var matchingCategories = categories.filter(function (category) {
            return (
               category.name.toLowerCase() === query ||
               (category.keywords &&
                  category.keywords.some(function (keyword) {
                     return keyword.toLowerCase() === query
                  }))
            )
         })
         util.sortByPropertyDesc('featured', matchingCategories)
         return matchingCategories[0]
      }

      function findBrand(brands, query) {
         if (!brands) {
            return
         }

         return brands.find(function (brand) {
            return brand.name.toLowerCase() === query
         })
      }

      function autocompleteSearchQueries() {
         var queriesWithSavedCounts = searchService.findSearchQueries(appState.userState.id, _queryPrefix)

         var maybeCategoriesAndBrandsPromise
         var categories
         var brands

         if (
            Object.keys(queriesWithSavedCounts).length < _searchQueriesMinimum &&
            _queryPrefix.length >= _minimumQueryLength &&
            // If the query prefix is only digits, it is most likey a barcode
            // being scanned, so don't query the categories/brands in this case
            !_digitsOnlyRegex.test(_queryPrefix)
         ) {
            maybeCategoriesAndBrandsPromise = addCategoriesQueries().then(maybeAddBrandQueries)
         }

         return $q.resolve(maybeCategoriesAndBrandsPromise).then(function () {
            $scope.searchQueries = compileSearchQueries()
         }, angular.noop)

         function maybeAddCategoryQuery(category) {
            // Exclude root categories and categories with 0 featured score
            if (category.depth === 0 || category.featured === 0) {
               return
            }

            // The category name and its keywords are possible candidates to be used as a
            // query suggestion.
            // Strings with certain characters like '&' and ',' do not make good query
            // suggestions, so exclude them.
            var queryCandidates
            if (category.keywords) {
               queryCandidates = category.keywords.reduce(function (candidates, keyword) {
                  if (!keyword.includes('&')) {
                     candidates.push(keyword.toLowerCase())
                  }
                  return candidates
               }, [])
            } else {
               queryCandidates = []
            }

            if (!category.name.includes('&') && !category.name.includes(',')) {
               queryCandidates.unshift(category.name.toLowerCase())
            }

            // Choose the best candidate to include based on:
            // - if it begins with the current query prefix
            // - has the fewest words
            queryCandidates.sort(queryPrefixAndWordCountComparer)

            var query = queryCandidates[0]

            if (
               query &&
               !queriesWithSavedCounts.hasOwnProperty(query) &&
               // exclude queries with more words than `_searchQueryMaxWords`
               wordCount(query) <= _searchQueryMaxWords
            ) {
               queriesWithSavedCounts[query] = 0
            }
         }

         function addCategoriesQueries() {
            return searchCategories().then(function (categoriesResult) {
               if (categoriesResult.query !== _queryPrefix) {
                  return $q.reject()
               }

               categories = categoriesResult.hits
               categories.forEach(maybeAddCategoryQuery)
            })
         }

         function maybeAddBrandQuery(brand) {
            var brandName = brand.name.toLowerCase()
            if (!queriesWithSavedCounts.hasOwnProperty(brandName)) {
               queriesWithSavedCounts[brandName] = 0
            }
         }

         function maybeAddBrandQueries() {
            if (Object.keys(queriesWithSavedCounts).length >= _searchQueriesMinimum || isHashTagQuery()) {
               return
            }

            return searchBrands().then(function (brandsResult) {
               if (brandsResult.query !== _queryPrefix) {
                  return $q.reject()
               }
               brands = brandsResult.hits
               brands.forEach(maybeAddBrandQuery)
            })
         }

         function compileSearchQueries() {
            var searchQueries = Object.keys(queriesWithSavedCounts).map(function (query) {
               var searchQuery = {
                  query: query,
                  savedCount: queriesWithSavedCounts[query],
                  score: 0,
               }
               var category = findCategory(categories, query)
               if (category) {
                  searchQuery.score = categoryQueryCoefficient(query) * category.featured
               }
               if (searchQuery.savedCount > 0) {
                  searchQuery.source = 'local'
               } else if (category) {
                  searchQuery.source = 'category'
               } else {
                  var brand = findBrand(brands, query)
                  if (brand) {
                     searchQuery.source = 'brand'
                     // Brand score below was chosen so that brands appear above very
                     // low-scoring category queries (e.g. with 4 words)
                     searchQuery.score = 1000
                  }
               }
               return searchQuery
            })

            searchQueries.sort(searchQueryComparer)

            return searchQueries
         }
      }

      //================================================================================
      // Search Input Event Handling
      //================================================================================

      // Note: We're effectively using a custom debounce approach here (instead of something
      // like `ng-model-options="{debounce: 300}"` in the template) because we want the value
      // of `$scope.query.q` to always be up to date (without being debounced).
      // This allows things like "enter" and "escape" key presses to happen immediately while
      // still preventing excess calls to Algolia.

      var _searchQueryTimeout
      $scope.searchQueryChanged = function () {
         $timeout.cancel(_searchQueryTimeout)
         _searchQueryTimeout = $timeout(function () {
            return handleSearchQueryChange()
         }, 300)
      }
      function handleSearchQueryChange() {
         var q = $scope.query.q

         if (q === undefined) {
            return
         }

         q = q.toLowerCase()

         // If there is a selected index but the corresponding query no longer
         // matches, reset the selected index to undefined.
         if (
            _selectedIndex !== undefined &&
            $scope.searchQueries[_selectedIndex] &&
            $scope.searchQueries[_selectedIndex].query !== q
         ) {
            _selectedIndex = undefined
            $scope.selectedIndex = _selectedIndex
         }

         if (_selectedIndex === undefined) {
            _queryPrefix = q
            autocompleteSearchQueries()
         }
      }

      $scope.onSearchKeydown = function ($event) {
         if ($event.key === 'Escape') {
            $event.preventDefault() // Prevents escape key closing cart modal when search is focused
            clearSearch()
            $rootScope.blur()
            return
         }

         if ($event.key === 'Enter') {
            submitSearch()
            return
         }

         var whitelist = ['ArrowDown', 'ArrowUp']

         if (!whitelist.includes($event.key)) {
            _keyedInSearch = true
            return
         }

         $event.stopPropagation()
         $event.preventDefault()

         var searchQueriesLength = Math.min($scope.searchQueries.length, _searchQueriesLimit)
         if (_selectedIndex === undefined) {
            _selectedIndex = $event.key === 'ArrowDown' ? 0 : searchQueriesLength - 1
         } else {
            _selectedIndex = $event.key === 'ArrowDown' ? _selectedIndex + 1 : _selectedIndex - 1
            if (_selectedIndex < 0 || _selectedIndex === searchQueriesLength) {
               _selectedIndex = undefined
            }
         }
         if (_selectedIndex === undefined || !$scope.searchQueries[_selectedIndex]) {
            $scope.query.q = _queryPrefix
         } else {
            $scope.query.q = $scope.searchQueries[_selectedIndex].query
         }
         $scope.selectedIndex = _selectedIndex
      }

      $scope.onSearchFocus = function () {
         $scope.searchQueries = []
         _queryPrefix = $scope.query.q || ''
         autocompleteSearchQueries()
         $scope.showSearchResultsDropdown = true
      }

      //================================================================================
      // Search autocomplete event handling
      //================================================================================

      $scope.removeSearchQuery = function (query) {
         searchService.removeSearchQuery(appState.userState.id, query)
         _selectedIndex = undefined
         $scope.selectedIndex = _selectedIndex
         focusSearchInput()
         autocompleteSearchQueries()
      }

      $scope.onAutocompleteSearchClick = function (searchQuery) {
         searchService.saveOrUpdateSearchQuery(appState.userState.id, searchQuery.query)
         resetSearch()
         return searchService.shopSearch(searchQuery.query)
      }

      //================================================================================

      function maybeSetQuery() {
         if ($state.is('shop.search')) {
            $scope.query.q = $state.params.query
         }
      }

      //================================================================================
      // Init
      //================================================================================

      function init() {
         maybeSetQuery()
      }

      init()

      //================================================================================
      // On State Change
      //================================================================================

      $scope.$on(
         '$destroy',
         $transitions.onSuccess({}, function () {
            resetSearch(true)
            maybeSetQuery()
         })
      )
   }
})(angular)
