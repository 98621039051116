;(function (angular) {
   'use strict'

   angular.module('app').directive('asDropdown', dropdown)

   function dropdown() {
      return {
         link: dropdownLink,
      }
   }

   function dropdownLink(scope, element) {
      var _offClickCloseEnabled = false

      function toggle() {
         if (element.hasClass('is-open')) {
            close()
         } else {
            element.addClass('is-open')
            _offClickCloseEnabled = false
            document.body.addEventListener('click', bodyClickCloseHandler)
         }
      }

      function close() {
         element.removeClass('is-open')
         document.body.removeEventListener('click', bodyClickCloseHandler)
      }

      function bodyClickCloseHandler() {
         if (_offClickCloseEnabled && element.hasClass('is-open')) {
            close()
         } else {
            _offClickCloseEnabled = true
         }
      }

      //================================================================================
      // Event Handlers
      //================================================================================

      element.on('click', toggle)

      element.on('keyup', function ($event) {
         $event.stopPropagation()
         switch ($event.key) {
            case 'Enter':
               toggle()
               break
            case 'Escape':
               close()
               break
         }
      })

      scope.$on('$destroy', close)
   }
})(angular)
