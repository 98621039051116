;(function (angular) {
   'use strict'

   angular.module('app').directive('asProductAddOrEdit', productAddOrEdit)

   function productAddOrEdit() {
      return {
         controller: productAddOrEditController,
         templateUrl: 'inlineTemplates/productAddOrEdit.htm',
         scope: {
            product: '=',
            filterDescription: '=',
            pack: '=',
            buttonClick: '&',
            editorAlignment: '@?',
            parentFeaturePath: '@',
         },
      }
   }

   function productAddOrEditController($scope, $timeout, $rootScope, appState, modalService) {
      $scope.customQuantity = null

      //================================================================================
      // Scope functions
      //================================================================================

      $scope.openOptionsSelectModal = function () {
         if ($scope.buttonClick) {
            $scope.buttonClick()
         }

         modalService
            .packagingOptions($scope.product, $scope.filterDescription, $scope.parentFeaturePath)
            .result.catch(angular.noop)
      }

      $scope.addToCart = function (maybeQty) {
         if ($scope.pack.busy) {
            return
         }

         if ($scope.buttonClick) {
            $scope.buttonClick()
         }

         var featurePath = $scope.parentFeaturePath

         var qty = maybeQty ? maybeQty : 1

         if ($scope.showQuickQty) {
            featurePath += ':quickQty'
            if (qty > 3) {
               featurePath += ':customQty'
            }
            featurePath += ':add' + qty + 'ToCart'
            $scope.closeQuickQty()
         } else {
            featurePath += ':addToCart'
         }

         appState.activeOrderState.addItem($scope.pack, qty, false, featurePath)
      }

      $scope.openQuickQty = function () {
         $scope.showQuickQty = true
         $scope.customQuantity = null
         $timeout(function () {
            document.getElementById('js-quickQtyInput').focus()
         })
      }

      $scope.closeQuickQty = function () {
         $scope.showQuickQty = false
         $rootScope.removeUnderlayClass()
      }
   }
})(angular)
