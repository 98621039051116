;(function (angular) {
   'use strict'

   angular.module('app').factory('personService', personService)

   function personService(personData, pubSub) {
      //================================================================================
      // Events
      //================================================================================

      var _events = Object.freeze({
         personChanged: 'person:changed',
      })

      function personChanged(personId) {
         return pubSub.publish(_events.personChanged, personId)
      }

      function personChangedExternal(personId) {
         personData.clearPerson(personId)
         return personChanged(personId)
      }

      //================================================================================
      // Helper Methods
      //================================================================================

      //================================================================================
      // Data Update Methods
      //================================================================================

      function updatePerson(personId, updateData) {
         return personData.updatePerson(personId, updateData).then(personChanged)
      }

      function updatePersonNotification(personId, type, channels) {
         var notificationsData = {}
         notificationsData[type] = channels
         return personData.updatePersonNotifications(personId, notificationsData).then(personChanged)
      }

      function removeTextNotifications(personId) {
         return personData.getPerson(personId).then(function (person) {
            var notifications = angular.copy(person.notifications)
            for (var type in notifications) {
               var index = notifications[type].indexOf('sms')
               if (index > -1) {
                  notifications[type].splice(index, 1)
               }
            }
            return personData.updatePersonNotifications(personId, notifications).then(personChanged)
         })
      }

      function updateInvoiceDelivery(personId, email, paper) {
         return updatePerson(personId, {
            'invoice-delivery-email': email,
            'invoice-delivery-paper': paper,
         })
      }

      function getHomePageVersion(person) {
         if (person && person['price-level'] === 'wholesale') {
            return 'wholesale'
         } else if (person) {
            return 'retail'
         } else {
            return 'visitor'
         }
      }

      //================================================================================

      return {
         // Data Update Methods
         updatePerson: updatePerson,
         updatePersonNotification: updatePersonNotification,
         removeTextNotifications: removeTextNotifications,
         updateInvoiceDelivery: updateInvoiceDelivery,
         getHomePageVersion: getHomePageVersion,

         // Events
         personChangedExternal: personChangedExternal,
         events: _events,
         subscribe: pubSub.subscribe,
      }
   }
})(angular)
