;(function (angular) {
   'use strict'

   angular.module('app').factory('paymentMethodService', paymentMethodService)

   function paymentMethodService(paymentMethodData, appState, $window) {
      function deletePaymentMethod(userId, paymentMethodId) {
         return paymentMethodData.deletePaymentMethod(userId, paymentMethodId)
      }

      function buildStepOneFormRedirectUrl() {
         var params = {}

         // Pass through any initial params to the redirect URL
         Object.keys($window.searchOnLoad).forEach(function (key) {
            params[key] = $window.searchOnLoad[key]
         })

         // if new user is set, add that to the params, too
         // we will use it on page load to keep the value of userState.newUser
         // after the page redirect
         if (appState.userState.newUser) {
            params['is-new-user'] = '1'
         }

         if (appState.userState.superUserFor) {
            params['superuser-for'] = appState.userState.superUserFor
         } else {
            delete params['superuser-for']
         }

         var redirectPath = location.pathname

         var paramsKeys = Object.keys(params)
         if (paramsKeys.length) {
            var paramsArray = paramsKeys.map(function (key) {
               return key + '=' + params[key]
            })
            redirectPath += '?' + paramsArray.join('&')
         }

         return redirectPath
      }

      function completeVaultStepThree(tokenId) {
         return paymentMethodData.attemptNmiStepThree(appState.userState.id, tokenId)
      }

      function getTokenId(andDelete) {
         var tokenId = $window.searchOnLoad['token-id']
         if (andDelete) {
            delete $window.searchOnLoad['token-id']
         }
         return tokenId
      }

      //================================================================================

      return {
         deletePaymentMethod: deletePaymentMethod,
         completeVaultStepThree: completeVaultStepThree,
         getTokenId: getTokenId,
         buildStepOneFormRedirectUrl: buildStepOneFormRedirectUrl,
      }
   }
})(angular)
