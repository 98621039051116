;(function (angular, Azure, undefined) {
   'use strict'

   angular.module('app').factory('sessionService', sessionService)

   function sessionService(AzureAPI, $q, pubSub, util, personData, cookieService) {
      var retryOnce = util.asyncRetryOnce

      //================================================================================
      // Events
      //================================================================================

      var _events = Object.freeze({
         loggedIn: 'session:loggedIn',
         loggedOut: 'session:loggedOut',
         registered: 'session:registered',
         superUserSessionStarted: 'session:superUserSessionStarted',
         superUserSessionEnded: 'session:superUserSessionEnded',
      })

      function loggedIn(args) {
         return pubSub.publish(_events.loggedIn, args)
      }

      function loggedOut() {
         return pubSub.publish(_events.loggedOut)
      }

      function registered(args) {
         return pubSub.publish(_events.registered, args)
      }

      function superUserSessionStarted(userId, superUserFor) {
         return pubSub.publish(_events.superUserSessionStarted, {
            userId: userId,
            superUserFor: superUserFor,
         })
      }

      function superUserSessionEnded() {
         return pubSub.publish(_events.superUserSessionEnded)
      }

      //================================================================================
      // Errors
      //================================================================================

      var _errors = Object.freeze({
         SUPERUSER_PERMISSION_DENIED: 1,
      })

      //================================================================================
      // Session Variables
      //================================================================================

      var _session

      //================================================================================
      // Authentication
      //================================================================================

      function saveSession(session) {
         _session = session
         if (typeof _session.person === 'object') {
            personData.cachePerson(_session.person)

            // If the session is a guest user who has already placed an order,
            // pretend like they don't exist.
            if (session.person.guest && session.person['first-order-placed']) {
               delete _session.person
            } else {
               _session.person = _session.person.id
            }
         }
         return _session
      }

      function getSession() {
         if (!_session) {
            if (Azure.sessionRequest) {
               var sessionRequest = Azure.sessionRequest
               Azure.sessionRequest = undefined
               _session = util
                  .resolveXhr(sessionRequest)
                  .then(function (responseText) {
                     return saveSession(JSON.parse(responseText))
                  })
                  .catch(function () {
                     // retry
                     _session = undefined
                     return getSession()
                  })
            } else {
               _session = retryOnce(AzureAPI.session.get, {
                  inline: Azure.sessionInline,
               })
                  .then(util.cleanItem)
                  .then(saveSession)
                  .catch(function (error) {
                     _session = undefined
                     return $q.reject(error)
                  })
            }
         }

         return $q.resolve(_session)
      }

      function login(credentials, args) {
         return retryOnce(AzureAPI.login, credentials, {
            inline: Azure.sessionInline,
         }).then(function (response) {
            saveSession(response.data)
            var loggedInArgs = angular.extend({}, args, {
               userId: _session.person,
            })
            loggedIn(loggedInArgs)
            return _session
         })
      }

      function logout(args) {
         var userId = _session && _session.person
         if (!userId) {
            return
         }
         // refresh analytics cookies just before logging out
         return retryOnce(AzureAPI.session.get).then(function () {
            return retryOnce(AzureAPI.logout).then(function (response) {
               saveSession(response.data)
               loggedOut()
               return _session
            })
         })
      }

      function autoLoginOrLogout(args) {
         return retryOnce(AzureAPI.session.get).then(function (session) {
            var userId = _session && _session.person
            if (session.person !== userId) {
               saveSession(session)
               if (_session.person) {
                  loggedIn(angular.extend({}, args, {userId: _session.person}))
               } else {
                  loggedOut()
               }
            }
            return _session
         })
      }

      //================================================================================
      // SuperUser
      //================================================================================

      function checkSuperUserPermission(userId, superUserFor) {
         var permissionDeniedError = {
            reason: _errors.SUPERUSER_PERMISSION_DENIED,
            message:
               'You are not allowed to access SuperUser mode. Please contact your administrator if you need access.',
         }
         return personData
            .getPeopleByIds([userId, superUserFor])
            .then(function (people) {
               var superUser = util.findById(people, userId)
               if (superUser && superUser.permissions && superUser.permissions.includes('update-person')) {
                  return $q.resolve()
               }
               return $q.reject(permissionDeniedError)
            })
            .catch(function (error) {
               if (error.status === 403) {
                  // assume that a 403 means that the user does not have permission to enter SuperUser mode.
                  return $q.reject(permissionDeniedError)
               } else {
                  // there was some other error
                  return $q.reject(error)
               }
            })
      }

      function startSuperUserFor(userId, superUserFor) {
         return checkSuperUserPermission(userId, superUserFor).then(function () {
            return superUserSessionStarted(userId, superUserFor)
         })
      }

      function endSuperUser() {
         return superUserSessionEnded()
      }

      //================================================================================
      // Registration
      //================================================================================

      function register(data, args) {
         if (Azure.canReadCookies) {
            var papVisitorId = cookieService.get('PAPVisitorId')
            var papAffiliateId = cookieService.get('PAPAffiliateId')
            if (papVisitorId && papAffiliateId) {
               var papBannerId = cookieService.get('PAPBannerId')
               data['post-affiliate-pro'] = {
                  referrer: papAffiliateId,
                  visitor: papVisitorId,
                  banner: papBannerId,
               }
            }
         }

         var registerInline = Azure.sessionInline
            .split(',')
            .map(function (param) {
               return 'session.' + param
            })
            .join()
         var params = {inline: registerInline}
         return retryOnce(AzureAPI.register, data, params).then(function (response) {
            saveSession(response.data.session)
            if (_session.person) {
               var registerArgs = angular.extend({}, args, {
                  userId: _session.person,
               })
               registered(registerArgs)
            }
            return _session
         })
      }

      //================================================================================
      // Password Reset
      //================================================================================

      function resetPassword(baseUrl, email) {
         return retryOnce(AzureAPI.resetPassword, {
            'base-url': baseUrl,
            email: email,
         })
      }

      function resetPasswordConfirm(token, password) {
         return retryOnce(AzureAPI.resetPasswordConfirm, {
            token: token,
            password: password,
         })
      }

      //================================================================================

      return {
         // Authentication
         getSession: getSession,
         login: login,
         logout: logout,
         autoLoginOrLogout: autoLoginOrLogout,

         // SuperUser
         startSuperUserFor: startSuperUserFor,
         endSuperUser: endSuperUser,

         // Registration
         register: register,

         // Password Reset
         resetPassword: resetPassword,
         resetPasswordConfirm: resetPasswordConfirm,

         // Events
         events: _events,
         subscribe: pubSub.subscribe,

         // Errors
         errors: _errors,
      }
   }
})(angular, window.Azure)
