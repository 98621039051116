;(function (angular) {
   'use strict'

   angular.module('app').directive('asProductGridFromIdsAndCodes', productGridFromIdsAndCodes)

   function productGridFromIdsAndCodes($q, appState, viewService) {
      return {
         link: productGridFromIdsAndCodesLink,
         restrict: 'E',
         scope: {
            productIdsAndCodes: '@',
            parentFeaturePath: '@?',
            productList: '@?',
         },
         replace: true,
         templateUrl: 'inlineTemplates/productGridFromIdsAndCodes.htm',
      }
      function productGridFromIdsAndCodesLink(scope) {
         return viewService
            .getProductViews(JSON.parse(scope.productIdsAndCodes), {
               priceSettings: appState.userState.priceSettings,
            })
            .then(function (productViews) {
               scope.productViews = productViews
            })
      }
   }
})(angular)
