;(function (angular) {
   'use strict'

   angular.module('app').directive('asParcelOptionBox', parcelOptionBox)

   function parcelOptionBox() {
      return {
         replace: true,
         templateUrl: 'partials/parcel_option_box.htm',
         scope: {
            options: '=',
            selectedOptions: '=',
            climate: '@',
            handleSelection: '=',
            howToPackage: '@',
            orderState: '=?',
         },
      }
   }
})(angular)
