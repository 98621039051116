;(function (angular) {
   'use strict'

   angular.module('app').directive('asToggleStopViewsBtn', toggleStopViewsBtn)

   function toggleStopViewsBtn() {
      return {
         templateUrl: 'partials/btn.toggle_stop_views.htm',
         scope: {
            stopCountToShow: '=',
            stopCount: '=',
            selectedStopIndex: '=',
         },
         controller: toggleStopViewsCtrl,
      }
   }

   function toggleStopViewsCtrl($scope) {
      var defaultStopsToShow = 3

      $scope.stopCountToShow = $scope.selectedStopIndex + 1 > defaultStopsToShow ? $scope.stopCount : defaultStopsToShow
      $scope.countDefault = defaultStopsToShow

      $scope.toggleStopViews = function (toggleTo) {
         $scope.stopCountToShow = toggleTo
      }
   }
})(angular)
