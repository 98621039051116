;(function (angular) {
   'use strict'

   angular.module('app').directive('dropFees', dropFees)

   function dropFees() {
      return {
         templateUrl: 'partials/drop_fees.htm',
         scope: {
            fee: '=',
         },
      }
   }
})(angular)
