/* jshint -W003 */
;(function (angular, undefined) {
   'use strict'

   angular.module('app').factory('appState', appState)

   function appState(
      $q,
      $state,
      $interval,
      $templateRequest,
      $filter,
      pubSub,
      alerts,
      sessionService,
      orderService,
      viewService,
      addressService,
      accountEntryData,
      rewardData,
      addressData,
      dropData,
      favoriteData,
      notificationData,
      orderData,
      personData,
      stopData,
      tripData,
      emailData,
      paymentMethodData,
      cookieService,
      modalService,
      personService,
      googleTagManagerService,
      util
   ) {
      //================================================================================
      // Events
      //================================================================================

      var _events = Object.freeze({
         orderRefreshed: 'state:orderRefreshed',
         orderActivated: 'state:orderActivated',
         userChanged: 'state:userChanged',
      })

      function orderRefreshed(orderId) {
         return pubSub.publish(_events.orderRefreshed, orderId)
      }

      function orderActivated(orderId) {
         return pubSub.publish(_events.orderActivated, orderId)
      }

      function userChanged(userId) {
         return pubSub.publish(_events.userChanged, userId)
      }

      //================================================================================
      // Order State Prototype
      //================================================================================

      var _orderStatePrototype = {
         get busy() {
            return (
               this.refreshing ||
               this.updatingOrderLines ||
               this.placing ||
               this.deleting ||
               this.changingShipment ||
               this.updatingParcelCarrierServices ||
               this.changingPaymentMethod ||
               this.updatingPackingRequest ||
               this.updatingHomeDeliveryInstructions ||
               this.updatingCustomerPo ||
               this.updatingEnsureRetailSalesFlyer ||
               this.changingHoldShipment ||
               this.updatingRewardsAllocation ||
               this.updatingPromoCode ||
               this.lateUpdatingToD2h
            )
         },
         get promise() {
            return $q.resolve(this._refreshPromise || this)
         },
         get refreshStockDataPromise() {
            return $q.resolve(this.order && this.order.refreshStockDataPromise)
         },
         get isActive() {
            return this.id === _activeOrderId
         },
         get canShop() {
            return this.order && this.order.canShop(_userState.superUserId)
         },
         get isActiveCanShop() {
            return this.isActive && this.canShop
         },
         // TODO: For better UX, open orders should always be shoppable. However, this is not yet the case.
         // Currently an open order may pass its cutoff and become unshoppable. Soon after cutoff, the backend will
         // automatically bump the shipment to the next trip, but there is no mechanism to notify the front end when
         // this happens to refresh the cached order.
         // Despite this, we sometimes want to treat open orders as shoppable in the UI, making canShopOrOpen useful.
         // For example, on the Orders page, unresolved orders are separated into "Current Orders" (canShopOrOpen)
         // and "Orders processing" (!canShopOrOpen)
         get canShopOrOpen() {
            return this.canShop || this.order.isStatusOpen
         },
         get cutoffDate() {
            return this.order && this.order.cutoffDate
         },
         get pickDate() {
            return this.order && this.order.pickDate
         },
         get lastApiUpdateDate() {
            return this.order && new Date(this.order.lastApiUpdate)
         },
         get noun() {
            return this.order.isStatusOpen ? 'cart' : 'order'
         },
         refresh: function (options, clearOrderCache) {
            var _this = this
            _this.refreshing = true
            _this._refreshPromise = $q
               .resolve(_this._refreshPromise)
               .then(function () {
                  if (clearOrderCache) {
                     orderData.clearOrder(_this.id)
                  }
                  var orderViewOptions
                  if (_this.options) {
                     orderViewOptions = options || _this.options
                  } else {
                     _unresolvedOrderViewOptions = angular.merge(
                        {},
                        _defaultUnresolvedOrderViewOptions,
                        options || _unresolvedOrderViewOptions
                     )
                     if (_this.isActive) {
                        _activeOrderViewOptions = angular.merge(
                           {
                              lineViews: {
                                 productViews: {
                                    refreshStockData: !_userState.isVisitor,
                                 },
                              },
                           },
                           _defaultActiveOrderViewOptions,
                           options || _activeOrderViewOptions
                        )
                        orderViewOptions = _activeOrderViewOptions
                     } else {
                        orderViewOptions = _unresolvedOrderViewOptions
                     }
                  }
                  orderViewOptions.priceSettings = _userState.priceSettings
                  return viewService
                     .getOrderView(_this.id, orderViewOptions)
                     .then(function (orderView) {
                        _this.order = orderView
                        var isEmpty = orderView.isEmpty
                        if (_this.isActive && isEmpty !== undefined) {
                           cookieService.set('cart', isEmpty ? 'n' : 'y')
                        }
                        if (_this.order.isPastCutoff) {
                           _this.refreshedAfterCutoff = true
                        }
                        orderRefreshed(_this.id)
                        return _this
                     })
                     .catch(function (error) {
                        // If this order was previously loaded, but upon refreshing we encounter a 403 or 404 status,
                        // it means the order was deleted outside of this instance of the app.
                        if (_this.order && (error.status === 403 || error.status === 404)) {
                           orderDeleted(undefined, _this.id)
                        }
                        return $q.reject(error)
                     })
               })
               .finally(function () {
                  delete _this._refreshPromise
                  delete _this.refreshing
               })

            return _this.promise
         },
         changeShipment: function (destination) {
            var _this = this

            var changeShipmentYesOrNoPromise
            // If changing a placed non-parcel carrier order to parcel carrier, re-open the order
            if (
               _this.order.isStatusPlaced &&
               !_this.order.isParcelCarrier &&
               destination.parcelCarrierDeliveryAddress
            ) {
               destination.status = orderData.statuses.open
               changeShipmentYesOrNoPromise = modalService.orderStatusChangePrompt().result.then(
                  function () {
                     return true
                  },
                  function () {
                     return false
                  }
               )
            } else {
               changeShipmentYesOrNoPromise = $q.resolve(true)
            }

            return changeShipmentYesOrNoPromise.then(function (changeShipmentYesOrNo) {
               if (!changeShipmentYesOrNo) {
                  return
               }

               _this.changingShipment = true
               var hadDestination = _this.order.hasDestination
               _this.changeShipmentPromise = orderService
                  .changeShipment(_this.id, destination, true)
                  .then(function (response) {
                     if (hadDestination) {
                        alerts.successMessage('Shipping method successfully updated.')
                     }
                     alertWarningMessage(response)
                  })
                  .catch(function (error) {
                     // Error - CHANGE_SHIPMENT_ITEM_REMOVAL (changing this shipment will remove order items)
                     if (error.reason === orderService.errors.CHANGE_SHIPMENT_ITEM_REMOVAL) {
                        return modalService.shipmentChangeItemRemovalPrompt(error).result.then(function () {
                           var action = orderService.changeShipment.bind(_this, _this.id, destination, false)
                           return doAction(action)
                        }, angular.noop)
                     }
                     // Error - CHANGE_SHIPMENT_ITEM_PRICE_CHANGES (changing the shipment will change the price of items in the order)
                     if (error.reason === orderService.errors.CHANGE_SHIPMENT_ITEM_PRICE_CHANGES) {
                        return modalService.itemPriceChangesPrompt(error.data.orderLines).result.then(function () {
                           var action = orderService.changeShipment.bind(_this, _this.id, destination, false)
                           return doAction(action)
                        }, angular.noop)
                     }
                     alertErrorMessage(error)
                     return $q.reject(error)
                  })
                  .finally(function () {
                     delete _this.changingShipment
                     delete _this.changeShipmentPromise
                  })
               return _this.changeShipmentPromise
            })
         },
         changeShipmentToPrimaryAddress: function () {
            var _this = this
            _this.changingShipment = true
            var action = addressService.getUserPrimaryShippingAddress.bind(_this, _userState.id)
            _this.changeShipmentPromise = doAction(action)
               .then(function (primaryAddress) {
                  return _this.changeShipment({
                     address: primaryAddress,
                  })
               })
               .finally(function () {
                  delete _this.changingShipment
                  delete _this.changeShipmentPromise
               })
            return _this.changeShipmentPromise
         },
         changeOpenOrderShipmentToNextTrip: function () {
            var _this = this
            _this.changingShipment = true
            _this.changeShipmentPromise = orderService
               .changeOpenOrderShipmentToNextTrip(_this.id, _this.order.drop)
               .catch(function (error) {
                  _this.errorChangingShipmentToNextTrip = true
                  return $q.reject(error)
               })
               .finally(function () {
                  delete _this.changingShipment
                  delete _this.changeShipmentPromise
               })
            return _this.changeShipmentPromise
         },
         changePaymentMethod: function (paymentMethod) {
            var _this = this
            _this.changingPaymentMethod = true
            var action = orderService.changePaymentMethod.bind(_this, _this.id, paymentMethod.id)
            return doAction(action).finally(function () {
               delete _this.changingPaymentMethod
            })
         },
         deleteOrder: function () {
            var _this = this
            _this.deleting = true
            var action = orderService.deleteOrder.bind(this, this.id)
            return doAction(action)
               .then(function () {
                  alerts.successMessage('Order successfully deleted.')
               })
               .finally(function () {
                  delete _this.deleting
               })
         },
         updatePackingRequest: function (packingRequest) {
            var _this = this
            _this.updatingPackingRequest = true
            var action = orderService.updatePackingRequest.bind(_this, _this.id, packingRequest)
            return doAction(action).finally(function () {
               delete _this.updatingPackingRequest
            })
         },
         updateHomeDeliveryInstructions: function (homeDeliveryInstructions) {
            var _this = this
            _this.updatingHomeDeliveryInstructions = true
            var action = orderService.updateHomeDeliveryInstructions.bind(_this, _this.id, homeDeliveryInstructions)
            return doAction(action).finally(function () {
               delete _this.updatingHomeDeliveryInstructions
            })
         },
         updateCustomerPo: function (customerPo) {
            var _this = this
            _this.updatingCustomerPo = true
            var action = orderService.updateCustomerPo.bind(_this, _this.id, customerPo)
            return doAction(action).finally(function () {
               delete _this.updatingCustomerPo
            })
         },
         updateParcelCarrierServices: function (parcelCarrierServices, perishableShippingWarningAccepted) {
            var _this = this
            _this.updatingParcelCarrierServices = true
            var action = orderService.updateParcelCarrierServices.bind(
               _this,
               _this.id,
               parcelCarrierServices,
               perishableShippingWarningAccepted
            )
            return doAction(action).finally(function () {
               delete _this.updatingParcelCarrierServices
            })
         },
         modalDeleteOrder: function () {
            return modalService.confirmDelete('order').result.then(this.deleteOrder.bind(this), angular.noop)
         },
         modalLateUpdateToD2h: function (d2hEstimates) {
            if (!d2hEstimates) {
               return
            }

            var _this = this

            return modalService.lateUpdateToD2h(d2hEstimates).result.then(function (selectedEstimate) {
               _this.lateUpdatingToD2h = true

               orderData
                  .lateUpdateToD2hDelivery(
                     _this.id,
                     selectedEstimate.dropId,
                     selectedEstimate.tripId,
                     selectedEstimate.dropToHomeDeliveryAddress.id
                  )
                  .then(function () {
                     return _this.refresh(undefined, true).then(function () {
                        alerts.success({
                           message: 'Your order has been updated to Drop-to-Home delivery.',
                           autoClose: false,
                           closeBtn: true,
                        })
                     })
                  })
                  .catch(function () {
                     alerts.error()
                  })
                  .finally(function () {
                     _this.lateUpdatingToD2h = false
                  })
            }, angular.noop)
         },
         orderPlaceIssuePrompt: function (actionTypeNumber) {
            if (!_userState.id || _userState.superUserId) {
               return false
            }

            var orderPlaceIssue = _userState.user && _userState.user['order-place-issue']
            if (!orderPlaceIssue || orderPlaceIssue === 'outstanding-balance-warn') {
               return false
            }

            if (actionTypeNumber !== 5 && this.order.isStatusOpen) {
               return false
            }

            // Note: Correct usage of these strings relies on them following this formula:
            // verb + ' your ' + noun
            var actionStringDictionary = {
               1: 'edit your order',
               2: 'edit your payment info',
               3: 'edit your shipping info',
               4: 'edit your packing request',
               5: 'place your order',
               6: 'edit your customer po',
            }

            modalService.settleOutstandingBalance(actionStringDictionary[actionTypeNumber]).result.catch(angular.noop)
            return true
         },
         modalEditPaymentMethod: function () {
            var _this = this
            if (_this.orderPlaceIssuePrompt(2)) {
               return $q.reject()
            }

            return modalService.selectPaymentMethod(_this).result.then(function (paymentMethod) {
               return _this.changePaymentMethod(paymentMethod)
            }, angular.noop)
         },
         modalEditShipment: function (parentFeaturePath) {
            var featurePath = parentFeaturePath + ':modalEditShipment'
            var _this = this
            // Note: we are refreshing the order here to be sure the user sees the current shipment information
            // This helps in case a change was made to the order outside this instance of the app
            _this.refresh(undefined, true)

            if (_this.orderPlaceIssuePrompt(3)) {
               return
            }

            modalService
               .selectShipment({
                  parentFeaturePath: featurePath,
                  orderState: _this,
                  context: 'editOrder',
               })
               .result.catch(angular.noop)
         },
         modalEditPackingRequest: function () {
            var _this = this
            if (_this.orderPlaceIssuePrompt(4)) {
               return
            }

            var params = {
               heading: 'Packing Request',
               placeholder: 'Enter packing request for this order here...',
               maybeText: _this.order.notes,
            }

            modalService.textarea(params).result.then(_this.updatePackingRequest.bind(_this), angular.noop)
         },
         modalEditDropToHomeInstructions: function () {
            var _this = this
            modalService
               .textarea({
                  heading: 'Instructions for Drop-to-Home Driver',
                  placeholder: 'Enter instructions here for the delivery driver of your Drop-to-Home order...',
                  maybeText: _this.order.homeDeliveryInstructions,
                  maxLength: 400,
               })
               .result.then(_this.updateHomeDeliveryInstructions.bind(_this), angular.noop)
         },
         modalEditCustomerPo: function () {
            var _this = this
            if (_this.orderPlaceIssuePrompt(6)) {
               return
            }

            modalService
               .textInput({
                  heading: 'Customer PO',
                  initialValue: _this.order.customerPo,
               })
               .result.then(_this.updateCustomerPo.bind(_this), angular.noop)
         },
         modalSimilarProducts: function (productId, code, parentFeaturePath, inStockOnly) {
            var featurePath = parentFeaturePath + ':modalSimilarProducts'
            modalService
               .productSubstitutions(
                  {
                     productId: productId,
                     code: code,
                     inStockOnly: inStockOnly,
                     pickDate: this.pickDate,
                     heading: 'Similar Products',
                  },
                  featurePath
               )
               .result.catch(angular.noop)
         },
         deleteOrderLine: function (orderLine, parentFeaturePath) {
            var _this = this
            _this.updatingOrderLines = true
            var featurePath = parentFeaturePath + ':deleteOrderLine'
            var action = orderService.deleteOrderLine.bind(_this, orderLine.order, orderLine.id)
            return doAction(action)
               .then(function () {
                  googleTagManagerService.removeFromCartEvent(
                     orderLine.productView,
                     orderLine.packView,
                     orderLine['quantity-ordered'],
                     featurePath
                  )
               })
               .finally(function () {
                  delete _this.updatingOrderLines
               })
         },
         updateOrderLineQuantity: function (orderLine, desiredQuantity, parentFeaturePath) {
            var _this = this
            var featurePath = parentFeaturePath + ':updateOrderLineQuantity'
            var oldQuantity = orderLine['quantity-ordered']

            if (oldQuantity === desiredQuantity) {
               return $q.reject()
            }
            if (isNaN(desiredQuantity)) {
               if (event) {
                  event.currentTarget.value = oldQuantity
               }
               return $q.reject()
            }
            if (_this.orderPlaceIssuePrompt(1)) {
               return $q.reject()
            }

            _this.updatingOrderLines = true
            var action = orderService.updateOrderLineQuantity.bind(
               _this,
               orderLine.order,
               orderLine.id,
               desiredQuantity
            )
            return doAction(action)
               .then(function (response) {
                  var updatedQuantity = response.updatedOrderLine['quantity-ordered']

                  maybeShowOrderlineChangesWarning(response)

                  if (response.updatedOrderLine.warnings) {
                     if (updatedQuantity === oldQuantity) {
                        alerts.errorMessage(response.updatedOrderLine.warnings[0])
                     } else {
                        alerts.warningMessage(response.updatedOrderLine.warnings[0])
                     }
                  }

                  if (updatedQuantity > oldQuantity) {
                     maybeShowLowerPricePerUnitGoodNewsAlert(
                        response.oldUnitPrice,
                        response.newUnitPrice,
                        response.updatedOrderLine.name
                     )

                     googleTagManagerService.addToCartEvent(
                        orderLine.productView,
                        orderLine.packView,
                        updatedQuantity - oldQuantity,
                        featurePath
                     )
                  } else if (updatedQuantity < oldQuantity) {
                     googleTagManagerService.removeFromCartEvent(
                        orderLine.productView,
                        orderLine.packView,
                        oldQuantity - updatedQuantity,
                        featurePath
                     )
                  }

                  return response.updatedOrderLine
               })
               .catch(function (error) {
                  return handleOrderLineQuantityUpdateError(error, _this, orderLine.packView)
               })
               .finally(function () {
                  delete _this.updatingOrderLines
               })
         },
         updateItemQuantity: function (packaging, existingQuantity, newQuantity, parentFeaturePath) {
            var featurePath = parentFeaturePath + ':updateItemQuantity'
            if (newQuantity === existingQuantity) {
               return
            }

            var _this = this
            _this.updatingOrderLines = true
            if (newQuantity > existingQuantity) {
               var quantityToAdd = newQuantity - existingQuantity
               return _this.addItem(packaging, quantityToAdd, false, featurePath).finally(function () {
                  delete _this.updatingOrderLines
               })
            } else if (newQuantity < existingQuantity) {
               var quantityToRemove = existingQuantity - newQuantity
               return _this.removeItem(packaging, quantityToRemove, featurePath).finally(function () {
                  delete _this.updatingOrderLines
               })
            }
         },
         incrementOrderLine: function (orderLine, parentFeaturePath) {
            return this.updateOrderLineQuantity(orderLine, orderLine['quantity-ordered'] + 1, parentFeaturePath)
         },
         decrementOrderLine: function (orderLine, parentFeaturePath) {
            return this.updateOrderLineQuantity(orderLine, orderLine['quantity-ordered'] - 1, parentFeaturePath)
         },
         promptIfOutWithSubs: function (packaging, parentFeaturePath) {
            var _this = this
            if (!packaging.isOutOfStockOn(_this.pickDate)) {
               return $q.resolve({
                  addItemToCart: true,
               })
            }
            return packaging.productView.hasActiveSubstitution({inStockOn: _this.pickDate}).then(function (shouldShow) {
               if (!shouldShow) {
                  return {
                     addItemToCart: true,
                  }
               }
               return modalService
                  .productSubstitutions(
                     {
                        productId: packaging.productView.id,
                        code: packaging.code,
                        inStockOnly: true,
                        pickDate: _this.pickDate,
                        addingToCart: true,
                     },
                     parentFeaturePath
                  )
                  .result.then(function (childFeature) {
                     return {
                        addItemToCart: true,
                        childFeature: childFeature,
                     }
                  })
                  .catch(function () {
                     return {
                        addItemToCart: false,
                     }
                  })
            })
         },
         addItem: function (packaging, quantity, forceNewLine, parentFeaturePath) {
            var featurePath = parentFeaturePath + ':addItem'
            var _this = this
            var code = packaging.code

            if (_this.orderPlaceIssuePrompt(1)) {
               return $q.reject()
            }

            if (
               packaging.freightHandlingRequired &&
               !_userState.superUserId &&
               !_this.order.getQuantity(packaging.code)
            ) {
               return modalService.freightHandlingRequired()
            }

            return _this.promptIfOutWithSubs(packaging, featurePath).then(function (promptResult) {
               if (!promptResult.addItemToCart) {
                  return
               }

               packaging.busy = true
               _this.updatingOrderLines = true
               var containsProductCode = _this.order && _this.order.containsProductCode(code)
               var action = addItemToOrder.bind(_this, _this.id, packaging, quantity, forceNewLine)
               return doAction(action)
                  .then(function (result) {
                     maybeShowOrderlineChangesWarning(result)

                     if (result.orderLine.warnings) {
                        if (result.quantityAdded) {
                           alerts.warningMessage(result.orderLine.warnings[0])
                        } else {
                           alerts.errorMessage(result.orderLine.warnings[0])
                        }
                     }
                     if (result.quantityAdded) {
                        maybeShowLowerPricePerUnitGoodNewsAlert(
                           result.oldUnitPrice,
                           result.newUnitPrice,
                           result.orderLine.name
                        )

                        googleTagManagerService.addToCartEvent(
                           packaging.productView,
                           packaging,
                           result.quantityAdded,
                           promptResult.childFeature || featurePath
                        )

                        if (containsProductCode && forceNewLine) {
                           alerts.warning({
                              message: 'Item ' + code + ' is now in your ' + _this.noun + ' more than once.',
                              autoClose: true,
                              duration: 4500,
                           })
                        }
                     }
                     return result
                  })
                  .catch(function (error) {
                     return handleOrderLineQuantityUpdateError(error, _this, packaging)
                  })
                  .finally(function () {
                     delete _this.updatingOrderLines
                     packaging.busy = false
                  })
            })
         },
         addItems: function (items, newLine, parentFeaturePath) {
            var featurePath = parentFeaturePath + ':addItems'
            var _this = this

            if (_this.orderPlaceIssuePrompt(1)) {
               return $q.reject()
            }

            _this.updatingOrderLines = true
            var action = orderService.addToOrder.bind(_this, _this.id, items, newLine)
            return doAction(action)
               .then(function (itemsAddedOrRejected) {
                  // Fire Google Tag Manager add to cart event for successfully added products.
                  itemsAddedOrRejected.forEach(function (item) {
                     if (item.state === 'fulfilled' && item.value.quantityAdded) {
                        googleTagManagerService.addToCartEvent(
                           item.value.packaging.productView,
                           item.value.packaging,
                           item.value.quantityAdded,
                           featurePath
                        )
                     }
                  })
                  return itemsAddedOrRejected
               })
               .finally(function () {
                  delete _this.updatingOrderLines
               })
         },
         removeItem: function (packaging, quantityToRemoveParam, parentFeaturePath) {
            var featurePath = parentFeaturePath + ':removeItem'
            var _this = this
            var quantityToRemove = quantityToRemoveParam || 1

            if (_this.id) {
               packaging.busy = true
               _this.updatingOrderLines = true
               var action = orderService.removeFromOrder.bind(_this, _this.id, packaging.code, quantityToRemove)
               return doAction(action)
                  .then(function (results) {
                     results.forEach(function (result) {
                        if (result && result.warnings) {
                           alerts.warningMessage(result.warnings[0])
                        }
                     })
                     googleTagManagerService.removeFromCartEvent(
                        packaging.productView,
                        packaging,
                        quantityToRemove,
                        featurePath
                     )
                  })
                  .finally(function () {
                     packaging.busy = false
                     delete _this.updatingOrderLines
                  })
            }
         },
         placeOrder: function (rewardsToAllocate, ensureRetailSalesFlyer, parentFeaturePath) {
            var featurePath = parentFeaturePath + ':placeOrder'
            var _this = this
            if (_this.orderPlaceIssuePrompt(5)) {
               return
            }

            var order = _this.order

            function orderHasValidPromoCode() {
               if (order.promoCodeViews) {
                  var hasValidPromoCode = order.promoCodeViews.some(function (entry) {
                     return !entry.invalidReasonType
                  })
                  return hasValidPromoCode
               }
            }

            var placeOrderYesOrNoPromise
            if (_userState.isGuest && (order.isDrop || orderHasValidPromoCode())) {
               placeOrderYesOrNoPromise = modalService.placingOrderAsGuestUpgradePrompt(featurePath).result.then(
                  function () {
                     return true
                  },
                  function () {
                     return false
                  }
               )
            } else {
               placeOrderYesOrNoPromise = $q.resolve(true)
            }

            placeOrderYesOrNoPromise.then(function (placeOrderYesOrNo) {
               if (!placeOrderYesOrNo) {
                  return
               }
               var parcelCarrierShipByDate
               if (order.isParcelCarrier && order.holdShipmentUntilStockAvailable) {
                  parcelCarrierShipByDate = order.latestNextPurchaseArrivalDate
               }
               _this.placing = true
               var action = orderService.placeOrder.bind(
                  _this,
                  _userState.id,
                  order.id,
                  rewardsToAllocate,
                  ensureRetailSalesFlyer,
                  parcelCarrierShipByDate
               )
               return doAction(action)
                  .then(function () {
                     googleTagManagerService.purchaseEvent(order, featurePath)
                  })
                  .finally(function () {
                     delete _this.placing
                  })
            })
         },
         setHoldShipmentUntilStockAvailable: function (holdShipmentUntilStockAvailable) {
            var _this = this
            _this.changingHoldShipment = true
            var action = orderService.updateOrder.bind(_this, _this.id, {
               holdShipmentUntilStockAvailable: holdShipmentUntilStockAvailable,
            })
            return doAction(action).finally(function () {
               delete _this.changingHoldShipment
            })
         },
         updateRewardsAllocation: function (updateTo, showSuccessMessage) {
            var _this = this
            _this.updatingRewardsAllocation = true
            var action = orderService.updateOrder.bind(_this, _this.id, {
               'rewards-allocation': updateTo || 0,
            })
            return doAction(action)
               .then(function () {
                  if (showSuccessMessage) {
                     alerts.successMessage('Updated Azure Cash applied to this order.')
                  }
               })
               .finally(function () {
                  delete _this.updatingRewardsAllocation
               })
         },
         updateEnsureRetailSalesFlyer: function (updateTo) {
            var _this = this
            _this.updatingEnsureRetailSalesFlyer = true
            var action = orderService.updateOrder.bind(_this, _this.id, {
               ensureRetailSalesFlyer: updateTo,
            })
            return doAction(action)
               .then(function () {
                  alerts.successMessage('Updated sales flyer preference for this order.')
               })
               .finally(function () {
                  delete _this.updatingEnsureRetailSalesFlyer
               })
         },
         modalEditOrder: function (clearOrderCache, parentFeaturePath) {
            var featurePath = parentFeaturePath + ':modalEditOrder'
            // Note: we are refreshing the order here to be sure the user sees the current order information
            // This helps in case a change was made to the order outside this instance of the app
            this.refresh(undefined, clearOrderCache)
            $state.go('.', {cart: true, featurePath: featurePath})
         },
         modalAddPromoCode: function () {
            var _this = this
            modalService
               .textInput({
                  heading: 'Enter Promo Code',
                  isRequired: true,
               })
               .result.then(function (promoCodeInput) {
                  return _this.addPromoCode(promoCodeInput)
               }, angular.noop)
         },
         addPromoCode: function (promoCode) {
            var _this = this
            _this.updatingPromoCode = true
            var action = orderService.addPromoCode.bind(_this, _this.id, promoCode)
            return doAction(action).finally(function () {
               delete _this.updatingPromoCode
            })
         },
         removePromoCode: function (orderId, promoCodeId) {
            var _this = this
            _this.updatingPromoCode = true
            var action = orderService.removePromoCode.bind(_this, orderId, promoCodeId)
            return doAction(action).finally(function () {
               delete _this.updatingPromoCode
            })
         },
         removeMultiplePromoCodes: function (orderId, promoCodeViews) {
            var _this = this
            _this.updatingPromoCode = true
            var promoCodeIdsToRemove = promoCodeViews.map(function (entry) {
               return entry.id
            })
            var action = orderService.removeMultiplePromoCodes.bind(_this, orderId, promoCodeIdsToRemove)
            return doAction(action).finally(function () {
               delete _this.updatingPromoCode
            })
         },
      }

      function createOrderState(data) {
         return Object.assign(Object.create(_orderStatePrototype), data)
      }

      //================================================================================
      // Unresolved Order States
      //================================================================================

      var _defaultUnresolvedOrderViewOptions = {
         tripView: {},
         stopView: {},
      }

      var _unresolvedOrderViewOptions = angular.copy(_defaultUnresolvedOrderViewOptions)

      var _unresolvedOrderStatesById = {}

      //================================================================================
      // Active Order State
      //================================================================================

      var _activeOrderId

      var _activeOrderStatePromise = $q.resolve()

      var _defaultActiveOrderViewOptions = {
         dropView: {},
         tripView: {},
         stopView: {},
         lineViews: {
            productViews: {
               checkStockAndSubstitutionsOnPickDate: true,
            },
         },
         promoCodeViews: true,
      }

      var _activeOrderViewOptions = angular.copy(_defaultActiveOrderViewOptions)

      function alertWarningMessage(response) {
         if (response && response.changes) {
            response.changes.forEach(function (change) {
               if (change.type === 'remove-order-line') {
                  var warningMessage = change.reason + '; it has been removed from your order'
                  alerts.warningMessage(warningMessage)
               }
            })
         }
         return response
      }

      function alertErrorMessage(error) {
         var errorMessage
         if (error.data) {
            var codedError = error.data.error
            if (codedError) {
               // Customize error message per error code here
               if (codedError.code === 'E20') {
                  errorMessage = '"' + codedError.context.promoCode + '" is not a valid promo code.'
               } else if (codedError.code === 'E22') {
                  errorMessage =
                     '"' +
                     codedError.context.promoCode +
                     '" cannot be applied to your order. ' +
                     codedError.context.invalidReasonType.description
               } else {
                  errorMessage = codedError.message
               }
            } else {
               errorMessage = error.data.message
            }
         } else {
            errorMessage = error.message
         }
         error.userErrorMessage = errorMessage
         alerts.errorMessage(errorMessage)
      }

      function doAction(action) {
         return action()
            .then(alertWarningMessage)
            .catch(function (error) {
               if (error) {
                  if (!error.doNotShowErrorAlert) {
                     alertErrorMessage(error)
                  }
                  return $q.reject(error)
               }
            })
      }

      function addItemToOrder(orderId, packaging, quantity, forceNewLine) {
         var items = [
            {
               packaging: packaging,
               quantity: quantity || 1,
            },
         ]
         return orderService.addToOrder(orderId, items, forceNewLine).then(function (itemsAddedOrRejected) {
            if (itemsAddedOrRejected[0].state === 'fulfilled') {
               return itemsAddedOrRejected[0].value
            } else {
               return $q.reject(itemsAddedOrRejected[0].reason.error)
            }
         })
      }

      function handleOrderLineQuantityUpdateError(error, orderState, packaging) {
         if (error.requiresPriceIncreaseConfirmation) {
            // The change did not occur because the price-per-unit has increased.
            // In this case, rather than updating the existing orderline's price-per-unit, we prompt to confirm adding the
            // item on a new orderline (which allows users to keep the lower price on the existing orderline).
            return modalService
               .prompt({
                  maybeConfirmBtnText: 'Agree & Add to Order',
                  message:
                     'The price-per-unit on this item has gone up from ' +
                     $filter('dollars')(error.data.oldUnitPrice) +
                     ' to ' +
                     $filter('dollars')(error.data.newUnitPrice) +
                     ' since you placed your order. We want you to keep the lower price on the ' +
                     error.existingOrderLineQuantity +
                     ' ' +
                     util.pluralize('item', error.existingOrderLineQuantity) +
                     " you've already ordered. Therefore, to add " +
                     error.quantityToAdd +
                     '  more to your order, ' +
                     (error.quantityToAdd === 1 ? 'it' : 'they') +
                     ' will be added as a new line item at the current price.',
               })
               .result.then(function () {
                  return orderState.addItem(packaging, error.quantityToAdd, true, 'newOrderLinePrompt')
               })
         }
      }

      function maybeShowOrderlineChangesWarning(response) {
         if (response.changes && response.changes.length) {
            response.changes.forEach(function (change) {
               if (change.type === 'drop-to-home-orderfee-added') {
                  alerts.warningMessage(change.reason)
               }
            })
         }
      }

      function maybeShowLowerPricePerUnitGoodNewsAlert(oldUnitPrice, newUnitPrice, itemName) {
         // If the user is getting a lower price-per-unit, show a non-blocking notice sharing the good news.
         if (oldUnitPrice > newUnitPrice) {
            alerts.success({
               autoClose: false,
               closeBtn: true,
               message:
                  'Good news! You are now paying less per unit for ' +
                  itemName +
                  " (it's been reduced from " +
                  $filter('dollars')(oldUnitPrice) +
                  ' to ' +
                  $filter('dollars')(newUnitPrice) +
                  ')',
            })
         }
      }

      function toOrderState(orderView) {
         return createOrderState({
            id: orderView.id,
            order: orderView,
         })
      }

      // There should always be an active order, so this will always resolve with an order id
      function getActiveOrderIdWithDefault() {
         var activeOrderIdPromise = orderData.getActiveOrderId(_userState.id)
         var unresolvedOrderStatesPromise = viewService
            .getUnresolvedOrderViews(_userState.id, _defaultUnresolvedOrderViewOptions, true)
            .then(function (orderViews) {
               return $q.map(orderViews, function (orderView) {
                  var orderState = _unresolvedOrderStatesById[orderView.id]
                  if (orderState) {
                     return orderState.refresh()
                  }
                  return _appState._addUnresolvedOrderStateById(orderView.id)
               })
            })

         return $q.all([activeOrderIdPromise, unresolvedOrderStatesPromise]).then(function (results) {
            var maybeActiveOrderId = results[0]
            var unresolvedOrderStates = results[1]

            var canShopOrOpenOrderStates = unresolvedOrderStates.filter(function (orderState) {
               return orderState.canShopOrOpen
            })

            if (canShopOrOpenOrderStates.length) {
               var orderState
               // If the last active order id is still open or shoppable, use it
               if (maybeActiveOrderId) {
                  orderState = util.findById(canShopOrOpenOrderStates, maybeActiveOrderId)
               }
               // Otherwise, use the order that was updated last
               if (!orderState) {
                  util.sortByPropertyDesc('lastApiUpdateDate', canShopOrOpenOrderStates)
                  orderState = canShopOrOpenOrderStates[0]
               }
               // If the order is open but not shoppable (past cutoff), bump it to the next trip
               if (!orderState.canShop) {
                  return orderState.changeOpenOrderShipmentToNextTrip().catch(function (error) {
                     if (error.reason === orderService.errors.ORDER_IS_NOT_OPEN) {
                        return getActiveOrderIdWithDefault()
                     } else {
                        return $q.reject(error)
                     }
                  })
               } else {
                  return orderState.id
               }
            } else {
               // No shoppable orders
               // If this is a guest user with an existing order, activate it.
               if (_userState.isGuest && unresolvedOrderStates.length) {
                  return unresolvedOrderStates[0].id
               }
               // Otherwise, create a new order
               return orderService.createOrder(_userState.id)
            }
         })
      }

      function createOrderAndActivate(createOrderData) {
         return orderService.createOrder(_userState.id, createOrderData).then(activateOrderWithDefaults)
      }

      function activateLocalOrder() {
         return orderData
            .getLocalOrderId()
            .then(_appState._addUnresolvedOrderStateById)
            .then(function (orderState) {
               return activateOrder(orderState.id)
            })
      }

      function activateUserOrder() {
         return $q
            .mapSettled(_appState.unresolvedOrderStates, function (orderState) {
               return $q.resolve(orderState.changeShipmentPromise)
            })
            .then(getActiveOrderIdWithDefault)
            .then(function (orderId) {
               if (orderId) {
                  return orderService
                     .maybeSyncLocalOrderLines(orderId)
                     .then(function (results) {
                        if (results) {
                           results.forEach(function (result) {
                              if (result.state === 'rejected') {
                                 alertErrorMessage(result.reason)
                              } else if (result.value['order-line'].warnings) {
                                 alerts.warningMessage(result.value['order-line'].warnings[0])
                              }
                           })
                        }
                        return activateOrderWithDefaults(orderId)
                     })
                     .then(function (orderState) {
                        maybeSyncLocalPromoCodeToOrder(orderState)
                        return orderState
                     })
               } else {
                  return activateOrder()
               }
            })
      }

      function maybeSyncLocalPromoCodeToOrder(orderState) {
         if (!orderState || !orderState.order) {
            return
         }
         orderData.localGetPromoCode().then(function (maybeLocalPromoCodeViewData) {
            var promoCodeToAdd = maybeLocalPromoCodeViewData && maybeLocalPromoCodeViewData.code
            if (promoCodeToAdd) {
               if (!_userState.isGuest) {
                  orderState.addPromoCode(promoCodeToAdd)
               }
               // Whatever happens, remove the local promo code
               orderData.localRemovePromoCode()
            }
         })
      }

      function activateOrderWithDefaults(orderId) {
         // Update order with defaults and then activate it
         return (
            orderService
               .updateOrderWithDefaults(orderId, _userState.id, _userState.superUserId)
               .then(function () {
                  return activateOrder(orderId)
               })
               // updating with defaults should never cause an error, but if it does, swallow it
               .catch(function () {
                  return activateOrder(orderId)
               })
         )
      }

      function activateOrder(orderId) {
         return orderData.setActiveOrderId(_userState.id, orderId).then(function () {
            _activeOrderId = orderId
            orderActivated(orderId)
            return _appState.refreshActiveOrderState()
         })
      }

      //================================================================================
      // User State Prototype
      //================================================================================

      var _defaultPersonViewOptions = {
         orderedPackagedProducts: true,
      }

      var _userStatePrototype = {
         get busy() {
            return this.refreshing || this.upgrading
         },
         get promise() {
            return $q.resolve(this._refreshPromise || this)
         },
         get homePageVersion() {
            return personService.getHomePageVersion(this.user)
         },
         get priceSettings() {
            if (this.user) {
               return {
                  priceLevel: this.user['price-level'],
                  rewardsRate: this.user['rewards-rate'],
               }
            }
            return personData.defaultPriceSettings
         },
         get isWholesale() {
            return this.priceSettings.priceLevel === personData.priceLevels.wholesale
         },
         get isUser() {
            return this.user && !this.user.guest
         },
         get isDropToHomeDeliveryAllowed() {
            return this.user && this.user.isDropToHomeDeliveryAllowed
         },
         get isGuest() {
            return this.user && this.user.guest
         },
         get isVisitor() {
            return !this.user
         },
         get isHomeDeliveryDriver() {
            return this.user && this.user.homeDeliveryDriverDropIds && this.user.homeDeliveryDriverDropIds.length
         },
         get orderedPackagedProductsPromise() {
            return $q.resolve(this.user && this.user.orderedPackagedProductsPromise)
         },
         get orderedPackagedProducts() {
            return this.user && this.user.orderedPackagedProducts
         },
         get freightQuoteNumber() {
            return this.isWholesale
               ? {
                    global: '+1-971-200-8355',
                    local: '971-200-8355',
                 }
               : {
                    global: '+1-971-200-8338',
                    local: '971-200-8338',
                 }
         },
         get supportNumber() {
            return this.isWholesale
               ? {
                    global: '+1-971-200-8355',
                    local: '971-200-8355',
                 }
               : {
                    global: '+1-971-200-8350',
                    local: '971-200-8350',
                 }
         },
         get supportEmail() {
            return this.isWholesale ? 'wholesale@azurestandard.com' : 'info@azurestandard.com'
         },
         init: function (userId, isNewUser) {
            if (!this.initialized) {
               if (!this.superUserId) {
                  changeUser(userId, isNewUser)
               }
               this.initialized = true
            }
         },
         upgradeGuestAccount: function () {
            var _this = this
            if (!_this.isGuest || _this.upgrading) {
               return $q.resolve()
            }
            _this.upgrading = true
            var action = personService.updatePerson.bind(this, _this.id, {guest: false})
            return doAction(action)
               .then(function () {
                  return _this.refresh()
               })
               .finally(function () {
                  delete _this.upgrading
               })
         },
         refresh: function (clearUserCache) {
            var _this = this
            if (_this.id) {
               _this.refreshing = true
               _this._refreshPromise = $q
                  .resolve(_this._refreshPromise)
                  .then(function () {
                     if (clearUserCache) {
                        personData.clearPerson(_this.id)
                     }
                     return viewService.getPersonView(_this.id, _defaultPersonViewOptions).then(function (personView) {
                        if (_this.id === personView.id) {
                           _this.user = personView
                           _this.email = personView.email
                        }
                        return _this
                     })
                  })
                  .finally(function () {
                     delete _this._refreshPromise
                     delete _this.refreshing
                  })
            } else {
               delete _this._refreshPromise
               delete _this.user
               delete _this.email
               delete _this.superUserId
            }
            return _this.promise
         },
         hasOrderedByPackaging: function (packaging) {
            var _this = this
            if (!_this.orderedPackagedProducts || !packaging) {
               return
            }
            return packaging.find(function (pack) {
               return util.findByPropertyValue(_this.orderedPackagedProducts, 'code', pack.code)
            })
         },
         orderedSummaryByPackaging: function (packaging) {
            var _this = this
            var zeroOrderedSummary = {
               lastOrderInvoiceDate: undefined,
               orderCount: 0,
            }
            if (!_this.orderedPackagedProducts || !packaging) {
               return zeroOrderedSummary
            }
            return packaging.reduce(function (summary, pack) {
               var orderedPack = util.findByPropertyValue(_this.orderedPackagedProducts, 'code', pack.code)
               if (orderedPack) {
                  summary.orderCount += orderedPack.orderCount
                  if (
                     !summary.lastOrderInvoiceDate ||
                     orderedPack.lastOrderInvoiceDate > summary.lastOrderInvoiceDate
                  ) {
                     summary.lastOrderInvoiceDate = orderedPack.lastOrderInvoiceDate
                     summary.lastOrderId = orderedPack.lastOrderId
                     summary.code = orderedPack.code
                  }
               }
               return summary
            }, zeroOrderedSummary)
         },
      }

      function createUserState(data) {
         return Object.assign(Object.create(_userStatePrototype), data)
      }

      //================================================================================
      // User State
      //================================================================================

      var _userState = createUserState({})

      //================================================================================
      // App State
      //================================================================================

      var _appState = {
         get activeOrderId() {
            return _activeOrderId
         },
         get activeOrderState() {
            return _unresolvedOrderStatesById[_activeOrderId]
         },
         get activeOrderNoun() {
            return this.activeOrderState ? this.activeOrderState.noun : 'cart'
         },
         get activeOrderStatePromise() {
            return _activeOrderStatePromise
         },
         get anyOpenOrders() {
            return _appState.unresolvedOrderStates.some(function (orderState) {
               return orderState.order.isStatusOpen
            })
         },
         get pickDate() {
            return _appState.activeOrderState && _appState.activeOrderState.pickDate
         },
         get unresolvedOrderStatesById() {
            return _unresolvedOrderStatesById
         },
         get unresolvedOrderStates() {
            if (!_appState._unresolvedOrderStates) {
               _appState._unresolvedOrderStates = Object.values(_unresolvedOrderStatesById)
            }
            return _appState._unresolvedOrderStates
         },
         _removeUnresolvedOrderStateById: function (orderId) {
            delete _unresolvedOrderStatesById[orderId]
            delete _appState._unresolvedOrderStates
         },
         _addUnresolvedOrderStateById: function (orderId) {
            var orderViewOptions = angular.merge({}, _unresolvedOrderViewOptions, {
               priceSettings: _userState.priceSettings,
            })
            return viewService
               .getOrderView(orderId, orderViewOptions)
               .then(toOrderState)
               .then(function (orderState) {
                  if (orderState.order.customer === _userState.id) {
                     _unresolvedOrderStatesById[orderId] = orderState
                     delete _appState._unresolvedOrderStates
                     return orderState
                  } else {
                     return $q.reject()
                  }
               })
         },
         _clearUnresolvedOrderStates: function () {
            delete _appState._unresolvedOrderStates
            _unresolvedOrderStatesById = {}
         },
         activateOrder: function (orderId) {
            var action = activateOrderWithDefaults.bind(_appState, orderId)
            _appState.activatingOrder = true
            _activeOrderStatePromise = doAction(action)
            return _activeOrderStatePromise.finally(function () {
               delete _appState.activatingOrder
            })
         },
         activateDefaultOrder: function () {
            if (!_appState.activatingOrder) {
               if (!_userState.id) {
                  _activeOrderStatePromise = activateLocalOrder()
               } else {
                  _appState.activatingOrder = true
                  var action = activateUserOrder.bind(_appState)
                  _activeOrderStatePromise = doAction(action).finally(function () {
                     delete _appState.activatingOrder
                  })
               }
            }
            return _activeOrderStatePromise
         },
         createOrderAndActivate: function (orderData) {
            _appState.activatingOrder = true
            var action = createOrderAndActivate.bind(_appState, orderData)
            _activeOrderStatePromise = doAction(action)
               .then(function () {
                  alerts.success({
                     message:
                        'Your new order was successfully created. To start adding items, <a ui-sref="shop">visit the shop</a>.',
                     autoClose: false,
                     closeBtn: true,
                  })
               })
               .finally(function () {
                  delete _appState.activatingOrder
               })
            return _activeOrderStatePromise
         },
         refreshActiveOrderState: function (options, clearOrderCache) {
            return $q.resolve(
               _appState.activeOrderState && _appState.activeOrderState.refresh(options, clearOrderCache)
            )
         },
         refreshUnresolvedOrderStates: function (options) {
            return $q
               .map(_appState.unresolvedOrderStates, function (orderState) {
                  return orderState.refresh(options).then(function () {
                     if (!orderState.order || orderState.order.isResolved) {
                        _appState._removeUnresolvedOrderStateById(orderState.id)
                     }
                  })
               })
               .then(function () {
                  return _appState.unresolvedOrderStates
               })
         },
         resetUnresolvedOrderViewOptions: function () {
            _unresolvedOrderViewOptions = undefined
            _activeOrderViewOptions = undefined
            this.unresolvedOrderStates.forEach(function (orderState) {
               orderState.options = undefined
            })
         },
         createOrderState: createOrderState,
         resetActiveOrderViewOptions: function () {
            _activeOrderViewOptions = undefined
         },
         userState: _userState,
         events: _events,
         subscribe: pubSub.subscribe,
      }

      //================================================================================
      // Cache Clearing
      //================================================================================

      function clearCurrentUserCachedData() {
         accountEntryData.clearUserCache(_userState.id)
         rewardData.clearUserCache(_userState.id)
         addressData.clearUserCache(_userState.id)
         dropData.clearUserCache(_userState.id)
         emailData.clearUserCache(_userState.id)
         favoriteData.clearUserCache(_userState.id)
         notificationData.clearUserCache(_userState.id)
         orderData.clearUserCache(_userState.id)
         personData.clear()
         paymentMethodData.clearUserCache(_userState.id)
      }

      function clearAllCachedData() {
         accountEntryData.clear()
         rewardData.clear()
         addressData.clear()
         dropData.clear()
         emailData.clear()
         favoriteData.clear()
         notificationData.clear()
         orderData.clear()
         personData.clear()
         stopData.clear()
         tripData.clear()
         paymentMethodData.clear()
      }

      //================================================================================
      // Subscriptions to updates
      //================================================================================

      //================================================================================
      // Order updates
      //================================================================================

      function orderCreated(event, orderId) {
         _appState._addUnresolvedOrderStateById(orderId)
      }

      function orderChanged(event, orderId) {
         var orderState = _unresolvedOrderStatesById[orderId]
         if (orderState) {
            var wasActiveCanShop = orderState.isActiveCanShop
            orderState.refresh().then(function () {
               if (wasActiveCanShop && !orderState.canShop) {
                  _appState.activateDefaultOrder()
               }
            })
         }
      }

      function orderDeleted(event, orderId) {
         _appState._removeUnresolvedOrderStateById(orderId)
         if (orderId === _activeOrderId) {
            _appState.activateDefaultOrder()
         }
      }

      pubSub.subscribe(orderService.events.orderCreated, orderCreated)
      pubSub.subscribe(
         [
            orderService.events.orderLineChanged,
            orderService.events.orderLineCreated,
            orderService.events.orderLineDeleted,
            orderService.events.orderChanged,
         ],
         orderChanged
      )
      pubSub.subscribe(orderService.events.orderDeleted, orderDeleted)

      //================================================================================
      // User updates
      //================================================================================

      function changeUser(userId, isNewUser) {
         _userState.id = userId
         _userState.newUser = isNewUser
         delete _userState.user
         _appState._clearUnresolvedOrderStates()
         delete _appState.activatingOrder
         _appState.activateDefaultOrder()
         _userState.refresh()
         userChanged(userId)
      }

      function loggedIn(event, args) {
         // Change to the user who just logged in, unless the user logged in just to go into superUser mode
         if (!_userState.superUserFor) {
            changeUser(args.userId, false)
            _userState.promise.then(function () {
               // Fires this GA4 built-in "recommended" event: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#login
               googleTagManagerService.pushEvent({
                  event: 'login',
                  featurePath: args.featurePath,
                  user_id: _userState.id,
                  homePageVersion: _userState.homePageVersion,
                  guest: _userState.isGuest,
                  priceLevel: _userState.priceSettings.priceLevel,
               })
            })
         }
      }

      function registered(event, args) {
         changeUser(args.userId, true)
         _userState.promise.then(function () {
            // Fires this GA4 built-in "recommended" event: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#sign_up
            googleTagManagerService.pushEvent({
               event: 'sign_up',
               featurePath: args.featurePath,
               user_id: args.userId,
               homePageVersion: _userState.homePageVersion,
               guest: _userState.isGuest,
               priceLevel: _userState.priceSettings.priceLevel,
            })
         })
      }

      function loggedOut() {
         clearAllCachedData()
         changeUser()
      }

      function superUserSessionStarted(event, args) {
         _userState.superUserId = args.userId
         _userState.superUserFor = args.superUserFor
         changeUser(args.superUserFor)
      }

      function superUserSessionEnded() {
         clearCurrentUserCachedData()
         var userId = _userState.superUserId
         delete _userState.superUserId
         delete _userState.superUserFor
         changeUser(userId)
      }

      function personChanged(event, personId) {
         if (personId === _userState.id) {
            _userState.refresh()
         }
      }

      pubSub.subscribe(sessionService.events.loggedIn, loggedIn)
      pubSub.subscribe(sessionService.events.registered, registered)
      pubSub.subscribe(sessionService.events.loggedOut, loggedOut)
      pubSub.subscribe(sessionService.events.superUserSessionStarted, superUserSessionStarted)
      pubSub.subscribe(sessionService.events.superUserSessionEnded, superUserSessionEnded)
      pubSub.subscribe(personService.events.personChanged, personChanged)

      //================================================================================
      // Time updates
      //================================================================================

      function perSecond() {
         if (_appState.activatingOrder) {
            return
         }

         var dropOrderStates = _appState.unresolvedOrderStates.filter(function (orderState) {
            return orderState.order.isDrop
         })

         dropOrderStates.forEach(function (orderState) {
            if (
               orderState.isActiveCanShop &&
               orderState.order.isApproachingCutoff &&
               !orderState.userNotifiedOfApproachingCutoff
            ) {
               orderState.userNotifiedOfApproachingCutoff = true
               $templateRequest('partials/alert_approaching_cutoff.htm').then(function (html) {
                  alerts.warning({
                     message: html,
                     condition: function () {
                        return !_appState.activatingOrder && orderState.isActive && orderState.order.isApproachingCutoff
                     },
                     autoClose: true,
                     duration: orderState.order.tripView.cutoffDate - new Date(),
                  })
               })
            }

            // Trigger cutoff actions
            if (orderState.order.isPastCutoff && !orderState.refreshing) {
               if (!orderState.refreshedAfterCutoff) {
                  // Refresh the order and its stop to check if in extended cutoff and ensure order data is fresh
                  stopData.clearStop(orderState.order.drop, orderState.order.trip)
                  orderState.refresh(undefined, true)
               }

               // Notify customer if active order is in extended cutoff
               if (
                  orderState.isActiveCanShop &&
                  !orderState.userNotifiedOfExtendedCutoff &&
                  orderState.order.isExtendedCutoff
               ) {
                  orderState.userNotifiedOfExtendedCutoff = true
                  $templateRequest('partials/alert_extended_cutoff.htm').then(function (html) {
                     alerts.warning({
                        message: html,
                        condition: function () {
                           return !_appState.activatingOrder && orderState.isActive && orderState.order.isExtendedCutoff
                        },
                        autoClose: true,
                        duration: orderState.order.tripView.extendedCutoffDate - new Date(),
                     })
                  })
               }

               if (!orderState.canShop && !_appState.activatingOrder) {
                  if (orderState.order.isStatusOpen) {
                     if (!orderState.changingShipment && !orderState.errorChangingShipmentToNextTrip) {
                        // If the order is open but not shoppable, bump it to the next trip
                        orderState
                           .changeOpenOrderShipmentToNextTrip()
                           .then(function () {
                              if (orderState.isActive) {
                                 $templateRequest('partials/alert_missed_cutoff.htm').then(alerts.warningMessage)
                              }
                           })
                           .catch(function (error) {
                              if (error.reason === orderService.errors.ORDER_IS_NOT_OPEN && orderState.isActive) {
                                 // The active order is not open and no longer shoppable. Activate another order and notify user
                                 _appState.activateDefaultOrder().then(function () {
                                    $templateRequest('partials/alert_order_processing.htm').then(alerts.warningMessage)
                                 })
                              }
                           })
                     }
                  } else if (orderState.isActive) {
                     // The active order is not open and no longer shoppable. Activate another order and notify user
                     _appState.activateDefaultOrder().then(function () {
                        $templateRequest('partials/alert_order_processing.htm').then(alerts.warningMessage)
                     })
                  }
               }
            }
         })
      }

      $interval(perSecond, 1000)

      //================================================================================

      return _appState
   }
})(angular)
