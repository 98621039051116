/* jshint -W106 */
;(function (angular, dataLayer, containerId) {
   'use strict'

   angular.module('app').factory('googleTagManagerService', googleTagManagerService)

   function googleTagManagerService($window, orderService) {
      var warnedAboutUndefined = false

      function pushEvent(data) {
         if ($window.google_tag_manager === undefined) {
            if (warnedAboutUndefined) {
               return
            }
            console.log('$window.google_tag_manager is undefined')
            warnedAboutUndefined = true
            return
         }
         try {
            dataLayer.push({ecommerce: null}) // Clear the previous ecommerce object (recommended in the docs)
            dataLayer.push(data)
         } catch (error) {
            console.error(error)
         }
      }

      // ================================================================================
      // GA4 Utils
      // ================================================================================

      // Useful GA4 links:
      //
      // - Docs: https://developers.google.com/analytics/devguides/collection/ga4
      // - Help center: https://support.google.com/analytics/?hl=en&sjid=16949735370590999001-EU#topic=10737980

      function createProductObject(product, packaging, quantity, list, position) {
         if (!product) {
            return
         }

         var productObj = {
            item_id: product.id,
            item_name: product.name,
         }
         if (product.brand) {
            productObj.item_brand = product.brand.name
         }
         if (packaging) {
            productObj.item_variant = packaging.code
            if (packaging.userPrice) {
               productObj.price = packaging.userPrice.dollarsMedian
            }
         }
         if (quantity) {
            productObj.quantity = quantity
         }
         if (list) {
            productObj.item_list_name = list
         }
         if (position) {
            productObj.index = position
         }
         return productObj
      }

      function createDefaultEcommerceObject(product, packaging, quantity) {
         var ecommerceObject = {
            currency: 'USD',
            items: [createProductObject(product, packaging, quantity)],
         }
         if (packaging && packaging.userPrice) {
            ecommerceObject.value = packaging.userPrice.dollarsMedian
         }
         return ecommerceObject
      }

      function getInStockOrderLines(order) {
         return order.lineViews.filter(function (orderLine) {
            return !orderLine.productView || !orderLine.packView.isOutOfStockOn(order.pickDate)
         })
      }

      function getInStockOrderLineTotals(order) {
         return orderService.calculateTotals(order, getInStockOrderLines(order))
      }

      function createCheckoutSpecificEcommerceObject(order) {
         var inStockOrderLineTotals = getInStockOrderLineTotals(order)
         var inStockProductObjs = getInStockOrderLines(order).map(function (orderLine) {
            return createProductObject(orderLine.productView, orderLine.packView, orderLine['quantity-ordered'])
         })
         var ecommerceObjectForCheckout = {
            transaction_id: order.id,
            currency: 'USD',
            value: inStockOrderLineTotals.totalPrice,
            shipping: inStockOrderLineTotals.totalShipping,
            items: inStockProductObjs,
         }
         if (order.promoCodeViews) {
            // Note that the `coupon` property seems to only support string values (so here's we're passing a comma-separated list instead of an array)
            ecommerceObjectForCheckout.coupon = order.codesFromPromoCodeViews.join(',')
         }
         return ecommerceObjectForCheckout
      }

      // ================================================================================
      // GA4 Events - Built-in ("recommended")
      //
      // GA4 recommends that these be used over custom events in order to get the most
      // out of its built-in reporting.
      //
      // Docs: https://support.google.com/analytics/answer/9267735?hl=en&ref_topic=13367566&sjid=16949735370590999001-EU
      // ================================================================================

      function addToCartEvent(product, packaging, quantity, featurePath) {
         // Fires this GA4 built-in "recommended" event: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#add_to_cart
         pushEvent({
            event: 'add_to_cart',
            featurePath: featurePath,
            ecommerce: createDefaultEcommerceObject(product, packaging, quantity),
         })
      }

      function removeFromCartEvent(product, packaging, quantity, featurePath) {
         // Fires this GA4 built-in "recommended" event: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#remove_from_cart
         pushEvent({
            event: 'remove_from_cart',
            featurePath: featurePath,
            ecommerce: createDefaultEcommerceObject(product, packaging, quantity),
         })
      }

      function productImpressionEvent(impressionFieldObjects, featurePath) {
         // Fires this GA4 built-in "recommended" event: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#view_item_list
         pushEvent({
            event: 'view_item_list',
            featurePath: featurePath,
            ecommerce: {
               items: impressionFieldObjects,
            },
         })
      }

      function productDetailPageviewEvent(product, packaging) {
         // Fires this GA4 built-in "recommended" event: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#view_item
         pushEvent({
            event: 'view_item',
            ecommerce: createDefaultEcommerceObject(product, packaging),
         })
      }

      function productClickEvent(product, productList, position, featurePath) {
         // Fires this GA4 built-in "recommended" event: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#select_item
         var productObj = createProductObject(product)
         if (position) {
            productObj.position = position
         }
         pushEvent({
            event: 'select_item',
            featurePath: featurePath,
            ecommerce: {
               item_list_name: productList,
               items: [productObj],
            },
         })
      }

      function beginCheckoutEvent(order) {
         // Fires this GA4 built-in "recommended" event: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#begin_checkout
         pushEvent({
            event: 'begin_checkout',
            ecommerce: createCheckoutSpecificEcommerceObject(order),
         })
      }

      function purchaseEvent(order, featurePath) {
         // Fires this GA4 built-in "recommended" event: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#purchase
         pushEvent({
            event: 'purchase',
            featurePath: featurePath,
            ecommerce: createCheckoutSpecificEcommerceObject(order),
            placedOrderId: order.id,
            placedOrderValue: getInStockOrderLineTotals(order).totalPrice,
         })
      }

      // ================================================================================
      // GA4 Events - Custom
      // Docs: https://support.google.com/analytics/answer/12229021?hl=en&ref_topic=13367566&sjid=16949735370590999001-EU
      // ================================================================================

      function checkoutStepPageviewEvent(orderLines, step, shippingOption) {
         if (!orderLines) {
            return
         }
         pushEvent({
            event: 'checkoutStepPageview',
            checkoutStep: step,
            checkoutShippingOption: shippingOption,
            ecommerce: {
               items: orderLines.map(function (orderLine) {
                  if (orderLine.productView) {
                     return createProductObject(
                        orderLine.productView,
                        orderLine.packView,
                        orderLine['quantity-ordered']
                     )
                  }
               }),
            },
         })
      }

      //================================================================================

      return {
         // Utils
         createProductObject: createProductObject,
         pushEvent: pushEvent,
         // Recommended GA4 events
         addToCartEvent: addToCartEvent,
         removeFromCartEvent: removeFromCartEvent,
         productImpressionEvent: productImpressionEvent,
         productDetailPageviewEvent: productDetailPageviewEvent,
         productClickEvent: productClickEvent,
         beginCheckoutEvent: beginCheckoutEvent,
         purchaseEvent: purchaseEvent,
         // Custom GA4 events
         checkoutStepPageviewEvent: checkoutStepPageviewEvent,
      }
   }
})(angular, window.dataLayer, window.gtmContainerId)
