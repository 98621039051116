;(function (angular) {
   'use strict'

   angular.module('app').directive('asRegister', register)

   function register() {
      return {
         controller: registerController,
         scope: {
            parentFeaturePath: '@',
            signIn: '=',
         },
         templateUrl: 'partials/register.htm',
      }
   }

   function registerController($scope, $state, alerts, sessionService, orderData) {
      $scope.featurePath = $scope.parentFeaturePath + ':register'
      if ($scope.parentFeaturePath === 'signUp') {
         $scope.context = 'registration'
      } else if ($scope.parentFeaturePath === 'checkout') {
         $scope.context = 'checkout'
      }

      orderData.localGetPromoCode().then(function (localPromoCode) {
         $scope.localPromoCode = localPromoCode && localPromoCode.code
      })

      var _baseUrl =
         $state.href(
            'signIn',
            {},
            {
               absolute: true,
            }
         ) + ($scope.parentFeaturePath === 'checkout' ? '?next=checkout&key=' : '?key=')

      $scope.credentials = {}
      $scope.newUser = {
         fullName: '',
         email: '',
      }

      $scope.setSignIn = function (signIn) {
         $scope.signIn = signIn
      }

      $scope.setGuest = function (guest) {
         $scope.guest = guest
      }

      $scope.resetEmail = function () {
         $scope.newUser.email = ''
         $scope.signIn = false
         $scope.accountExists = false
      }

      $scope.submitJoinForm = function (valid) {
         if (!valid || $scope.isProcessing) {
            return
         }

         $scope.isProcessing = true

         var data = {
            'authentication-base-url': _baseUrl,
            'disable-active-user-email': true,
            context: $scope.context,
            email: $scope.newUser.email,
            person: {
               name: $scope.newUser.fullName,
               password: $scope.newUser.password,
               guest: $scope.guest,
            },
         }

         sessionService
            .register(data, {
               featurePath: $scope.featurePath,
            })
            .then(function (session) {
               if (!session.person) {
                  // If the response to register does not contain a person, the user attempted to register a duplicate email
                  // In this case a new user is not created. Instead we send an email to the user with a sign-in link and
                  // tell the user to check their email and follow the link to continue checkout.
                  $scope.signIn = true
                  $scope.accountExists = true
                  $scope.credentials.username = $scope.newUser.email
               }
               // Otherwise a new user was created and the user will be signed in
            })
            .finally(function () {
               $scope.isProcessing = false
            })
            .catch(alerts.error)
      }
   }
})(angular)
