;(function (angular) {
   'use strict'

   angular.module('app').directive('asHeaderMobile', headerMobile)

   function headerMobile() {
      return {
         templateUrl: 'partials/header_mobile.htm',
         controller: headerMobileController,
      }
   }

   function headerMobileController($scope) {
      $scope.featurePath = 'headerMobile'
   }
})(angular)
