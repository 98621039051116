;(function (angular) {
   'use strict'

   angular.module('app').factory('yamzService', yamzService)

   function yamzService($q, $ocLazyLoad, $rootScope) {
      var _loaded = false

      function load() {
         if (_loaded) {
            return $q.resolve()
         }

         return $ocLazyLoad
            .load([
               'https://cdn.jsdelivr.net/npm/yet-another-medium-zoom@0.4.2/dist/yet-another-medium-zoom.umd.min.js',
            ])
            .then(function () {
               _loaded = true
            })
      }

      function handleInit() {
         window.yamz.setOptions({
            scrollAllowance: 0,
         })

         var images = Array.from(document.querySelectorAll('[data-yamz]'))

         if (images.length) {
            images.forEach(function (image) {
               var opts = {}
               if (image.dataset.caption) {
                  var caption = document.createElement('div')
                  caption.innerHTML = image.dataset.caption
                  caption.style.cursor = 'default'
                  caption.addEventListener('click', function (e) {
                     // Prevent the lightbox from closing when clicking inside of a caption
                     // (i.e. allow clicking links and selecting text and such)
                     e.stopPropagation()
                  })
                  opts.caption = caption
               }
               delete image.dataset.caption
               window.yamz.bind(image, opts)
            })
         }
      }

      function init() {
         return load().then(function () {
            window.setTimeout(function () {
               return handleInit()
            })
         })
      }

      // ====================================================================

      return {
         init: init,
      }
   }
})(angular)
