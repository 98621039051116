;(function (angular) {
   'use strict'

   angular.module('app').directive('asOrderSort', orderSort)

   function orderSort() {
      return {
         controller: orderSortController,
      }
   }

   function orderSortController($scope, $state, $transitions, productService) {
      var _options = [
         {
            sortBy: 'id',
            name: 'Sort: Order added',
            reverseOrderDefault: true,
         },
         {
            sortBy: 'productView.brand.name',
            name: 'Sort: Brand',
            reverseOrderDefault: false,
         },
         {
            sortBy: 'productView.temperatureType',
            name: 'Sort: Temperature type',
            reverseOrderDefault: false,
            comparer: function (a, b) {
               return productService.temperatureTypeComparer(a.value, b.value)
            },
         },
         {
            sortBy: 'productView.selectedPackaging.code',
            name: 'Sort: Product code',
            reverseOrderDefault: false,
         },
      ]

      function getSelectSortOption(sortParam) {
         return (
            sortParam &&
            _options.find(function (item) {
               return item.sortBy === $state.params.sort
            })
         )
      }

      //================================================================================
      // Initialization
      //================================================================================

      function init(params) {
         var selectedSortValue = getSelectSortOption(params.sort) || _options[0]

         if (params.hasOwnProperty('reverse')) {
            selectedSortValue.reverseOrderDefault = params.reverse
         }

         $scope.orderSortState = {
            options: _options,
            selectedSortValue: selectedSortValue,
         }
      }

      init($state.params)

      //================================================================================
      // Update on transition success
      //
      // This is needed for the back button to work properly.
      //================================================================================

      $scope.$on(
         '$destroy',
         $transitions.onSuccess({}, function (transition) {
            init(transition.params())
         })
      )
   }
})(angular)
