;(function (angular) {
   'use strict'

   angular.module('app').directive('asReplaceElementWithTrustedHtml', replaceElementWithTrustedHtml)

   function replaceElementWithTrustedHtml() {
      return {
         link: replaceElementWithTrustedHtmlLink,
      }
   }

   function replaceElementWithTrustedHtmlLink(scope, element, attrs) {
      element.replaceWith(attrs.ngDataHtml)
   }
})(angular)
