;(function (angular, undefined) {
   'use strict'

   angular.module('app').factory('dropService', dropService)

   function dropService($q, dropData, tripData, pubSub) {
      //================================================================================
      // Events
      //================================================================================

      var _events = Object.freeze({
         dropChanged: 'drop:changed',
      })

      function dropChanged(dropId) {
         return pubSub.publish(_events.dropChanged, dropId)
      }

      //================================================================================
      // Helper Methods
      //================================================================================

      function findEarliestTrip(trips) {
         var earliestTrip, earliestTripCutoffDate
         trips.forEach(function (trip) {
            var tripCutoffDate = new Date(trip.cutoff)
            if (!earliestTrip || tripCutoffDate < earliestTripCutoffDate) {
               earliestTrip = trip
               earliestTripCutoffDate = new Date(earliestTrip.cutoff)
            }
         })
         return earliestTrip
      }

      function filterTripsBeforeCutoff(trips) {
         return trips.filter(function (trip) {
            return new Date() < new Date(trip.cutoff)
         })
      }

      function findEarliestTripBeforeCutoff(trips) {
         var tripsBeforeCutoff = filterTripsBeforeCutoff(trips)
         return findEarliestTrip(tripsBeforeCutoff)
      }

      function getEarliestUnconfirmedTripByDropId(dropId, beforeCutoffOnly) {
         if (!dropId) {
            return $q.resolve()
         }
         var findEarliest = beforeCutoffOnly ? findEarliestTripBeforeCutoff : findEarliestTrip
         return tripData.getUnconfirmedTripsByDropId(dropId).then(findEarliest)
      }

      function sortDropIdTripsByCutoff(dropIdTrips) {
         dropIdTrips.sort(function (a, b) {
            var aDate = new Date(a.trip.cutoff)
            var bDate = new Date(b.trip.cutoff)

            if (aDate > bDate) {
               return 1
            }
            if (aDate < bDate) {
               return -1
            }
            return 0
         })
         return dropIdTrips
      }

      function getUnconfirmedDropIdTripsByDropId(dropId) {
         return tripData.getUnconfirmedTripsByDropId(dropId).then(function (trips) {
            return trips.map(function (trip) {
               return {
                  dropId: dropId,
                  trip: trip,
               }
            })
         })
      }

      function getUnconfirmedDropIdTripsByDropIdSorted(dropId) {
         return getUnconfirmedDropIdTripsByDropId(dropId).then(sortDropIdTripsByCutoff)
      }

      //================================================================================
      // Data Update Methods
      //================================================================================

      function addDropMembership(userId, dropId, pending) {
         return dropData.addDropMembership(userId, dropId, pending)
         // TODO: publish a dropMembershipAdded event
      }

      function updateDrop(dropId, updateData) {
         return dropData.updateDrop(dropId, updateData).then(dropChanged)
      }

      function updateDropMembership(dropMembershipId, updateData) {
         return dropData.updateDropMembership(dropMembershipId, updateData)
         // TODO: publish a dropMembershipChanged event
         // Not necessary until something needs to subscribe to this event
         //.then(dropMembershipChanged)
      }

      function removeDropMembership(dropMembershipId) {
         return dropData.updateDropMembership(dropMembershipId, {
            active: false,
         })
         // TODO: publish a dropMembershipRemoved event
      }

      function updateDropMembershipNotification(dropMembershipId, type, channels) {
         var notificationsData = {}
         notificationsData[type] = channels
         return dropData.updateDropMembershipNotifications(dropMembershipId, notificationsData)
         //.then(dropMembershipChanged)
      }

      function removeTextNotifications(userId) {
         return dropData.getActiveDropMemberships(userId).then(function (dropMemberships) {
            return $q.map(dropMemberships, function (dropMembership) {
               var notificationsData = angular.copy(dropMembership.notifications)
               for (var type in notificationsData) {
                  var index = notificationsData[type].indexOf('sms')
                  if (index > -1) {
                     notificationsData[type].splice(index, 1)
                  }
               }
               return dropData.updateDropMembershipNotifications(dropMembership.id, notificationsData)
               //.then(dropMembershipChanged)
            })
         })
      }

      //================================================================================

      return {
         // Helper Methods
         getEarliestUnconfirmedTripByDropId: getEarliestUnconfirmedTripByDropId,
         getUnconfirmedDropIdTripsByDropIdSorted: getUnconfirmedDropIdTripsByDropIdSorted,

         // Data Update Methods
         addDropMembership: addDropMembership,
         removeDropMembership: removeDropMembership,
         updateDrop: updateDrop,
         updateDropMembership: updateDropMembership,
         updateDropMembershipNotification: updateDropMembershipNotification,
         removeTextNotifications: removeTextNotifications,

         // Events
         events: _events,
         subscribe: pubSub.subscribe,
      }
   }
})(angular)
