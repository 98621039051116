/* jshint -W106 */

;(function (angular) {
   'use strict'

   angular.module('app').directive('asBlockImageSlider', blockImageSlider)

   function blockImageSlider() {
      return {
         templateUrl: 'partials/block_image_slider.htm',
         controller: blockImageSliderController,
         restrict: 'A',
         scope: {
            block: '=',
            imageMap: '=',
            columnWidth: '=',
            uniqueSliderId: '@',
            containerMaxWidth: '=?',
            isRounded: '=?',
         },
      }
   }

   function blockImageSliderController($scope, $rootScope, util) {
      $scope.containerMaxWidth = $scope.containerMaxWidth || $rootScope.constrainedMaxContainerWidth

      $scope.imageMaxWidthPixelsString =
         $scope.maxWidth ||
         $scope.block.content.max_width ||
         ($scope.row && $scope.containerMaxWidth / $scope.row.columns.length)

      // Here we're overridding `$scope.block.content.max_visible_slides` at smaller viewports to prevent
      // effectively-broken layouts. If there are cases where we should not do this then we should migrate
      // this handling to a new variable in Kirby (i.e. `max_visible_slides_sm` or `min_visible_slides`).
      if ($scope.block.content.max_visible_slides) {
         if ($rootScope.viewportXs) {
            $scope.maxVisible = Math.min($scope.block.content.max_visible_slides, 2)
         } else if ($rootScope.viewportSm) {
            $scope.maxVisible = Math.min($scope.block.content.max_visible_slides, 3)
         } else {
            $scope.maxVisible = $scope.block.content.max_visible_slides
         }
      } else {
         $scope.maxVisible = 1
      }

      $scope.slideWidthFull = !$scope.maxVisible || $scope.maxVisible === 1

      $scope.ratio = $scope.block.content.slides[0].image_aspect_ratio

      $scope.ratioAsPercentOrFixed = $scope.block.content.fixed_height
         ? $scope.block.content.fixed_height + 'px'
         : $rootScope.ratioPadding($scope.ratio)

      $scope.widthAsPercentIfNotFull = (1 / $scope.maxVisible) * 100 + '%'

      $scope.maxSlideWidth = $scope.block.content.max_slide_width + 'px' || $scope.widthAsPercentIfNotFull

      $scope.maxWidthAsPixelsIfNotFull = $scope.maxVisible
         ? $scope.containerMaxWidth / $scope.maxVisible
         : $scope.containerMaxWidth

      if ($rootScope.viewportXs) {
         $scope.absoluteMaxSlideWidth = Math.min(
            $scope.block.content.max_slide_width,
            $rootScope.contentMaxWidthAtBreakpointMaxXs
         )
      } else {
         $scope.absoluteMaxSlideWidth =
            $scope.block.content.max_slide_width ||
            ($scope.maxVisible === 1 && $scope.block.content.max_container_width) ||
            $scope.maxWidthAsPixelsIfNotFull
      }

      // =====================================================================================================
      // INIT
      // =====================================================================================================

      function initFlickityInstance() {
         var flickityInstance = new window.Flickity(document.getElementById($scope.uniqueSliderId), {
            // docs: https://flickity.metafizzy.co/options.htm
            imagesLoaded: true,
            wrapAround: true,
            cellAlign: 'left',
            pageDots: util.isStringTrue($scope.block.content.nav_dots),
            autoPlay: util.isStringTrue($scope.block.content.auto_slide) ? 7000 : false,
            lazyLoad: $scope.maxVisible > 1 ? $scope.maxVisible - 1 : true,
            accessibility: false,
            cellSelector: '.js-flickityItem',
            setGallerySize: false,
         })

         setTimeout(function () {
            flickityInstance.resize()
         })

         $scope.$on('$destroy', function () {
            if (flickityInstance) {
               flickityInstance.destroy()
            }
         })
      }

      setTimeout(function () {
         initFlickityInstance()
      })
   }
})(angular, window)
