;(function (angular) {
   'use strict'

   angular.module('app').directive('asSignIn', signIn)

   function signIn() {
      return {
         controller: signInController,
      }
   }

   function signInController($scope, $q, sessionService, alerts, modalService) {
      if (!$scope.credentials) {
         $scope.credentials = {}
      }

      $scope.signIn = function (valid, featurePath) {
         if (!valid || $scope.isProcessing) {
            return
         }

         $scope.isProcessing = true

         // Strip 'C' or 'c' if followed by all digits
         $scope.credentials.username = $scope.credentials.username.replace(/^C(\d+)$/i, '$1')

         // Clear previous error messages when attempting to sign in again
         alerts.clear()

         return sessionService
            .login($scope.credentials, {featurePath: featurePath})
            .then(function () {
               alerts.success({
                  message: "You're signed in. Welcome!",
                  queue: true,
               })
            })
            .catch(function (error) {
               var errorMsg =
                  error.data.message === 'this account is inactive'
                     ? 'This account is inactive. Please <a ui-sref="cmsTopLevelPage({slug: \'support\'})">contact us</a> for assistance.'
                     : 'Login incorrect. Please try again.'
               alerts.errorMessage(errorMsg)
               return $q.reject(error)
            })
            .finally(function () {
               $scope.isProcessing = false
            })
      }

      $scope.resetPassword = function () {
         modalService.resetPassword($scope.credentials.username).result.catch(angular.noop)
      }
   }
})(angular)
