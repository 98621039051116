;(function (angular) {
   'use strict'

   angular.module('app').directive('asDateRangePicker', dateRangePicker)

   function dateRangePicker($q, $ocLazyLoad, $window, util) {
      return {
         restrict: 'A',
         link: dateRangePickerLink,
         templateUrl: 'partials/date_range_picker.htm',
         scope: {
            presetOptions: '=',
            initAtRange: '=',
            initAtPresetSlug: '=',
            onRangeSelected: '&',
            onPresetClicked: '&',
            sharedPickerState: '=',
         },
      }

      function dateRangePickerLink(scope, element) {
         var _loaded = false
         var _picker
         var _localPickerState = {
            activePresetSlug: scope.initAtPresetSlug,
            activeRange: scope.initAtRange,
         }
         scope.localPickerState = _localPickerState

         // This is needed to make sure the picker's state gets updated when changes happen at the parent level.
         // Currently the only time this happens is via the back-button (e.g. where the picker's state can change due
         // to handling in `uiOnParamsChanged`).
         scope.$watch('sharedPickerState', function (newValue, oldValue) {
            if (newValue.range && !newValue.slug) {
               sharedPickerSelectHandling(newValue.range)
               if (_picker) {
                  _picker.setStartDate(newValue.range[0])
                  _picker.setEndDate(newValue.range[1])
               }
            } else if (newValue.slug) {
               sharedPresetClickHandling(newValue.slug)
            }
         })

         //================================================================================
         // Internal utils
         //================================================================================

         function closeUi() {
            // Note that this is all that's needed to close the UI because we're using
            // the directive `as-dropdown` to show/hide the UI.
            document.body.click()
         }

         // ====================================================================
         // One-off handling for events
         // ====================================================================

         function sharedPresetClickHandling(slug) {
            if (_picker) {
               _picker.clear()
            }

            _localPickerState.activePresetSlug = slug
            _localPickerState.activeRange = undefined
         }

         scope.handlePresetClick = function (option) {
            sharedPresetClickHandling(option.slug)

            scope.onPresetClicked({
               presetOptionSlug: option.slug,
            })

            closeUi()
         }

         function sharedPickerSelectHandling(range) {
            _localPickerState.activePresetSlug = undefined
            _localPickerState.activeRange = range
         }

         function onPickerSelect(event) {
            var start = event.detail.start.toLocaleDateString('sv')
            var end = event.detail.end.toLocaleDateString('sv')

            sharedPickerSelectHandling([start, end])

            scope.onRangeSelected({
               dataFromDirective: {
                  start: start,
                  end: end,
               },
            })

            closeUi()
         }

         // ====================================================================
         // Init handling
         // ====================================================================

         function load() {
            if (_loaded) {
               return $q.resolve()
            }

            return (
               $ocLazyLoad
                  // Note that the bundle version is smaller than the total of loading each needed script separately
                  // (even though we're not using all of the bundled plugins).
                  .load(['https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.umd.min.js'])
                  .then(function () {
                     _loaded = true
                  })
            )
         }

         function handlePickerInit() {
            if (_picker) {
               return
            }

            _picker = new $window.easepick.create({
               element: document.getElementById('js-dateRangeFilterUi'),
               css: [
                  'https://cdn.jsdelivr.net/combine/npm/@easepick/core@1.2.1/dist/index.css,npm/@easepick/range-plugin@1.2.1/dist/index.css,npm/@easepick/amp-plugin@1.2.1/dist/index.css,npm/@easepick/lock-plugin@1.2.1/dist/index.css',
                  // This is our "style override" file. The only other workable approach to customizing the styles seems to
                  // be https://github.com/easepick/easepick/discussions/95#discussioncomment-4169941, but there's a bug with
                  // that approach that results in a bad FLUC.
                  '/styles/calendarRangePicker.css',
               ],
               autoApply: true,
               inline: true, // possible bug with this approach. see https://github.com/easepick/easepick/issues/188
               zIndex: 200,
               documentClick: true,
               plugins: ['RangePlugin', 'AmpPlugin', 'LockPlugin'],
               RangePlugin: {
                  tooltip: false,
                  startDate: scope.initAtRange && scope.initAtRange[0],
                  endDate: scope.initAtRange && scope.initAtRange[1],
               },
               AmpPlugin: {
                  dropdown: {
                     months: true,
                     years: true,
                     minYear: 2010,
                  },
                  darkMode: false,
               },
               LockPlugin: {
                  maxDate: new Date(),
               },
            })

            _picker.on('select', onPickerSelect)

            // This is needed to ensure the calendar state starts at the beginning of the existing selection.
            // See https://github.com/easepick/easepick/issues/188#issuecomment-1814259940
            _picker.trigger('show')
         }

         function init() {
            return load().then(function () {
               return handlePickerInit()
            })
         }

         init()
      }
   }
})(angular)
