;(function (angular) {
   'use strict'

   angular.module('app').directive('asSuperuserMenu', superuserMenu)

   function superuserMenu() {
      return {
         templateUrl: 'partials/superuser_menu.htm',
         controller: superuserMenuController,
      }
   }

   function superuserMenuController($scope, $window, $location) {
      $scope.openInNewTab = function () {
         $window.open($location.absUrl(), '_blank')
      }
   }
})(angular)
