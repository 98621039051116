;(function (angular) {
   'use strict'

   angular.module('app').factory('tripService', tripService)

   function tripService() {
      //================================================================================
      // Helper Methods
      //================================================================================

      function getExtendedCutoffDate(cutoffDate) {
         // extended cutoff is 2 hours after cutoff
         return new Date(cutoffDate.getTime() + 2 * 60 * 60 * 1000)
      }

      //================================================================================

      return {
         // Helper Methods
         getExtendedCutoffDate: getExtendedCutoffDate,
      }
   }
})(angular)
