;(function (angular) {
   'use strict'

   angular.module('app').directive('asAddresses', addresses)

   function addresses() {
      return {
         controller: addressesController,
         restrict: 'A',
      }
   }

   function addressesController(
      $q,
      $scope,
      $attrs,
      $parse,
      alerts,
      personService,
      dropService,
      addressService,
      appState,
      viewService,
      modalService
   ) {
      var _userState = appState.userState

      $scope.editAddressModal = function (address, isPrimary) {
         return modalService.addressForm(address, isPrimary).result.then(function (updatedAddress) {
            $scope.addressesLoading = true
            var textFromPrimaryRemoved = $scope.addresses[0].id === updatedAddress.id && !updatedAddress.text
            return addressService
               .updateAddress(_userState.id, updatedAddress)
               .then(function () {
                  initAddresses()
                  alerts.successMessage('Your address was successfully updated.')
                  if (textFromPrimaryRemoved) {
                     removeTextNotifications()
                  }
                  return updatedAddress
               })
               .catch(function (error) {
                  $scope.addressesLoading = false
                  alerts.error(error)
                  return $q.reject(error)
               })
         })
      }

      $scope.addAddressModal = function () {
         return modalService.addressForm().result.then($scope.addAddress)
      }

      $scope.addAddress = function (address) {
         // If there are no existing addresses, set this one as primary
         address.preference = $scope.addresses && $scope.addresses.length ? 0 : 1
         $scope.addressesLoading = true
         return addressService
            .addAddress(_userState.id, address)
            .then(function (newAddress) {
               initAddresses()
               alerts.successMessage('New address added.')
               return newAddress
            })
            .catch(function (error) {
               $scope.addressesLoading = false
               alerts.error(error)
            })
      }

      $scope.deleteAddress = function (address) {
         modalService.confirmDelete('address').result.then(function () {
            $scope.addressesLoading = true
            return addressService
               .deleteAddress(_userState.id, address.id)
               .then(function () {
                  initAddresses()
                  alerts.successMessage('Address deleted.')
                  return address
               })
               .catch(function (error) {
                  $scope.addressesLoading = false
                  alerts.error(error)
               })
         }, angular.noop)
      }

      $scope.setPrimary = function (address) {
         var addressIndex = $scope.addresses.indexOf(address)
         if (addressIndex === 0) {
            // this address is already the primary
            return
         }
         // loading index for only showing loading indicator on loading address
         $scope.loadingIndex = addressIndex

         var currentPrimaryHasText = $scope.addresses[0].text
         if (currentPrimaryHasText && !address.text) {
            var promptModalOptions = {
               heading: 'Heads up!',
               message:
                  'Setting an address to primary that does not have a text number will deactivate any active text notifications.',
            }
            return modalService.prompt(promptModalOptions).result.then(function () {
               handleSetPrimary(address)
            }, angular.noop)
         } else {
            return handleSetPrimary(address)
         }
      }

      $scope.addPrimarySmsModal = function () {
         return modalService.addSmsUi($scope.addresses[0]).result.then(function () {
            initAddresses()
            alerts.success({
               message:
                  '<div class="leading-normal">Text number successfully added to your primary address.<br>Text notifications are now enabled.</div>',
               autoClose: false,
               closeBtn: true,
            })
         })
      }

      function handleSetPrimary(address) {
         $scope.primaryLoading = true
         return addressService
            .makeAddressPrimary(_userState.id, address.id)
            .then(function () {
               initAddresses()
               alerts.successMessage('Primary address updated.')
               if (!address.text) {
                  removeTextNotifications()
               }
            })
            .catch(alerts.error)
            .finally(function () {
               $scope.primaryLoading = false
            })
      }

      function removeTextNotifications() {
         personService.removeTextNotifications(_userState.id)
         dropService.removeTextNotifications(_userState.id)
      }

      function initAddresses(isCalledFromInit) {
         $scope.addressesLoading = true
         $scope.addressesPromise = viewService
            .getUserAddressViews(_userState.id)
            .then(function (addressViews) {
               $scope.addresses = addressViews

               if (isCalledFromInit) {
                  var maybeInitiallyLoadedCallback = $parse($attrs.asAddressesInitiallyLoadedThen)
                  if (maybeInitiallyLoadedCallback) {
                     maybeInitiallyLoadedCallback($scope)
                  }
               }
            })
            .finally(function () {
               $scope.addressesLoading = false
            })
      }

      //================================================================================
      // Initialization
      //================================================================================

      function init() {
         initAddresses(true)
      }

      init()
   }
})(angular)
