;(function (angular, undefined) {
   'use strict'

   angular.module('app').factory('orderService', orderService)

   function orderService(
      $q,
      orderData,
      dropService,
      dropData,
      tripData,
      stopData,
      pubSub,
      tripService,
      personService,
      rewardData,
      paymentMethodData,
      util
   ) {
      //================================================================================
      // Events
      //================================================================================

      var _events = Object.freeze({
         orderCreated: 'order:created',
         orderChanged: 'order:changed',
         orderDeleted: 'order:deleted',
         orderLineChanged: 'orderLine:changed',
         orderLineCreated: 'orderLine:created',
         orderLineDeleted: 'orderLine:deleted',
      })

      function orderCreated(orderId) {
         return pubSub.publish(_events.orderCreated, orderId)
      }

      function orderChanged(orderId) {
         return pubSub.publish(_events.orderChanged, orderId)
      }

      function orderDeleted(orderId) {
         return pubSub.publish(_events.orderDeleted, orderId)
      }

      function orderLineChanged(orderId) {
         return pubSub.publish(_events.orderLineChanged, orderId)
      }

      function orderLineCreated(orderId) {
         return pubSub.publish(_events.orderLineCreated, orderId)
      }

      function orderLineDeleted(orderId) {
         return pubSub.publish(_events.orderLineDeleted, orderId)
      }

      //================================================================================
      // Errors
      //================================================================================

      var _errors = Object.freeze({
         CHANGE_SHIPMENT_ITEM_REMOVAL: 1,
         ORDER_IS_NOT_OPEN: 2,
         CHANGE_SHIPMENT_ITEM_PRICE_CHANGES: 3,
      })

      //================================================================================
      // Helper Methods
      //================================================================================

      function calculateTotals(order, orderLines) {
         if (!orderLines) {
            return {}
         }
         var linePrice = 0
         var rewards = 0
         var totalPrice
         var totalShipping
         var weight = 0
         var volume = 0
         var countOrdered = 0
         var countShipped = 0
         orderLines.forEach(function (line) {
            linePrice += line.price
            rewards += line.rewards || 0
            weight += line.weight || 0
            volume += line.volume || 0
            countOrdered += line['quantity-ordered'] || 0
            countShipped += line['quantity-shipped'] || 0
         })
         if (order.fees) {
            totalPrice = linePrice
            totalShipping = 0
            order.fees.forEach(function (fee) {
               totalPrice += fee.amount
               if (fee.type === 'parcel-carrier' || fee.type === 'shipping') {
                  totalShipping += fee.amount
               }
            })
         }
         return {
            linePrice: util.pennyRound(linePrice),
            totalPrice: util.pennyRound(totalPrice),
            totalShipping: util.pennyRound(totalShipping),
            rewards: util.pennyRound(rewards),
            weight: weight,
            volume: volume,
            countOrdered: countOrdered,
            countShipped: countShipped,
         }
      }

      function calculateTotalQuantityOrderedOfPackages(orderLines, packaging) {
         if (!orderLines) {
            return
         }
         // sum the quantity-ordered on orderLines of a package of this product
         return orderLines.reduce(function (total, orderLine) {
            if (util.findByPropertyValue(packaging, 'code', orderLine['packaged-product'])) {
               total += orderLine['quantity-ordered']
            }
            return total
         }, 0)
      }

      function willProductExpire(productMaxStorageDays, deliveryDate, tripPickDate) {
         if (productMaxStorageDays && deliveryDate && tripPickDate) {
            // determine if the product will expire during the trip
            var deliveryDurationDays = Math.round((deliveryDate - tripPickDate) / (24 * 60 * 60 * 1000))
            return deliveryDurationDays > productMaxStorageDays
         }
         return false
      }

      function canShop(tripCutoffDate, isTripConfirmed, isStopShort, isSuperUser) {
         if (!tripCutoffDate) {
            return true
         }

         var now = new Date()
         var pastCutoff = tripCutoffDate <= now

         // can shop if the trip is not past cutoff
         if (!pastCutoff) {
            return true
         }

         // if the trip is past cutoff
         if (isSuperUser && !isTripConfirmed) {
            // can shop if in superUser mode and the trip is not confirmed
            return true
         } else if (isStopShort) {
            // can shop if the stop is shorted and within the extended cutoff time
            var extendedCutoffDate = tripService.getExtendedCutoffDate(tripCutoffDate)
            return now < extendedCutoffDate
         }
         return false
      }

      function canShopByDropIdTripId(dropId, tripId, isSuperUser) {
         if (!tripId) {
            return $q.resolve(true)
         }
         return $q.all([tripData.getTrip(tripId), dropId && stopData.getStop(dropId, tripId)]).then(function (results) {
            var trip = results[0]
            var stop = results[1]
            var tripCutoffDate = new Date(trip.cutoff)
            var isStopShort = stop && stop['short-drop']
            return canShop(tripCutoffDate, trip.confirmed, isStopShort, isSuperUser)
         })
      }

      function hasDestination(order) {
         if (order) {
            return (order.drop && order.trip) || order.parcelCarrierDeliveryAddress || order.dropToHomeDeliveryAddress
         }
      }

      function findFirstShoppableDropIdTrip(dropIdTrips, isSuperUser) {
         if (dropIdTrips.length === 0) {
            return $q.resolve()
         }
         return canShopByDropIdTripId(dropIdTrips[0].dropId, dropIdTrips[0].trip.id, isSuperUser).then(function (
            isShoppable
         ) {
            if (isShoppable) {
               return dropIdTrips[0]
            }
            dropIdTrips.shift()
            return findFirstShoppableDropIdTrip(dropIdTrips, isSuperUser)
         })
      }

      function getEarliestShoppableDropIdTrip(dropId, isSuperUser) {
         return dropService.getUnconfirmedDropIdTripsByDropIdSorted(dropId).then(function (dropIdTrips) {
            return findFirstShoppableDropIdTrip(dropIdTrips, isSuperUser)
         })
      }

      function normalizeDestination(destination) {
         if (destination) {
            if (destination.parcelCarrierDeliveryAddress) {
               destination.drop = undefined
               destination.trip = undefined
               destination.dropToHomeDeliveryAddress = undefined
               // Remove the deprecated `address` prop
               destination.address = undefined
            } else if (destination.drop && destination.trip) {
               destination.address = undefined
               destination.parcelCarrierDeliveryAddress = undefined
            }
         }
         return destination
      }

      function getDefaultDestination(userId, isSuperUser) {
         return orderData.getLocalLastDestination(userId).then(function (lastDestination) {
            if (lastDestination && lastDestination.parcelCarrierDeliveryAddress) {
               return lastDestination
            }

            return dropData.getUserActiveDropIds(userId).then(function (dropIds) {
               var defaultDropId
               if (dropIds.length === 1) {
                  defaultDropId = dropIds[0]
               } else if (lastDestination && lastDestination.drop) {
                  defaultDropId = dropIds.find(function (dropId) {
                     return dropId === lastDestination.drop
                  })
               }

               if (defaultDropId) {
                  return getEarliestShoppableDropIdTrip(defaultDropId, isSuperUser).then(function (dropIdTrip) {
                     if (dropIdTrip) {
                        return {
                           drop: dropIdTrip.dropId,
                           trip: dropIdTrip.trip.id,
                        }
                     }
                  })
               }
            })
         })
      }

      function getDefaultDestinationIfOrderUnchanged(orderId, userId, isSuperUser) {
         return getDefaultDestination(userId, isSuperUser).then(function (maybeDefaultDestination) {
            if (!maybeDefaultDestination) {
               return
            }
            maybeDefaultDestination = normalizeDestination(maybeDefaultDestination)
            // check to see if adding this default destination to the order will change it
            return orderData.tryUpdateOrder(orderId, maybeDefaultDestination).then(function (response) {
               // if there are no changes to the order then return the default destination
               if (!response.changes) {
                  return maybeDefaultDestination
               }
            })
         })
      }

      function paymentMethodIdToCheckoutPayment(paymentMethodId) {
         return {
            'checkout-payment': {
               'payment-method': paymentMethodId,
            },
         }
      }

      function noCheckoutPayment() {
         return paymentMethodIdToCheckoutPayment()
      }

      function getDefaultPaymentMethod(userId) {
         return orderData.getLocalLastPaymentMethodId(userId).then(function (defaultPaymentMethodId) {
            if (defaultPaymentMethodId) {
               return paymentMethodData.getPaymentMethod(userId, defaultPaymentMethodId)
            }
         })
      }

      function findMostRecentOrderLineByProductCode(orderLines, productCode) {
         if (orderLines) {
            var allOrderLinesWithProductCode = getAllOrderLinesWithProductCode(orderLines, productCode)
            if (allOrderLinesWithProductCode && allOrderLinesWithProductCode.length) {
               return allOrderLinesWithProductCode[0]
            }
         }
      }

      function getAllOrderLinesWithProductCode(orderLines, productCode) {
         // Note that this sorts by newest orderlines first (since IDs are sequential).
         // We do this to ensure that the most recent orderline is preferred when adding and removing quantities (which
         // is important when there are multiple orderlines of the same package that have different per-unit prices).
         if (orderLines) {
            return util.sortByIdDesc(
               orderLines.filter(function (orderLine) {
                  return orderLine['packaged-product'] === productCode
               })
            )
         }
      }

      function isLargeShippingService(service) {
         return service && service.includes('Freight')
      }

      //================================================================================
      // Data Update Methods
      //================================================================================

      function updateOrder(orderId, orderUpdateData, rejectInvalidPayment) {
         return orderData
            .updateOrder(orderId, orderUpdateData)
            .then(function (response) {
               orderChanged(orderId)
               return response
            })
            .catch(function (error) {
               if (!error.invalidPaymentMethod || rejectInvalidPayment) {
                  return $q.reject(error)
               }
               // If the payment method is invalid and rejectInvalidPayment is not set,
               // remove the payment method and retry the update
               var orderUpdateDataWithPaymentRemoved = angular.extend({}, orderUpdateData, noCheckoutPayment())
               return updateOrder(orderId, orderUpdateDataWithPaymentRemoved, true)
            })
      }

      function changeShipmentError(error) {
         if (!error.message) {
            error.message =
               'Cannot change the shipment for this order. Please <a ui-sref="cmsTopLevelPage({slug: \'support\'})">contact us</a> for assistance.'
         }
         return $q.reject(error)
      }

      function changeShipment(orderId, destination, trialRun) {
         destination = normalizeDestination(destination)
         if (!hasDestination(destination)) {
            return $q.reject({
               message: 'A valid destination is required to change the order shipment.',
            })
         }

         // changing the destination always removes parcel-carrier-services
         destination['parcel-carrier-services'] = undefined

         if (trialRun) {
            return orderData
               .tryUpdateOrder(orderId, destination)
               .then(function (response) {
                  // we only want the items that will be removed due to 'cannot be shipped'
                  // because it's possible that other items will be removed for other reasons (and we're not yet
                  // going to convey such item-removal to the user)
                  var someItemCannotBeShipped =
                     response.changes &&
                     response.changes.some(function (change) {
                        return change.reason.includes('cannot be shipped')
                     })
                  if (someItemCannotBeShipped) {
                     return $q.reject({
                        reason: _errors.CHANGE_SHIPMENT_ITEM_REMOVAL,
                        data: response,
                     })
                  }

                  var changesEntryForPriceChanges =
                     response.changes &&
                     response.changes.find(function (change) {
                        return change.type === 'price-changes'
                     })
                  if (changesEntryForPriceChanges) {
                     return $q.reject({
                        reason: _errors.CHANGE_SHIPMENT_ITEM_PRICE_CHANGES,
                        data: changesEntryForPriceChanges,
                     })
                  }
               })
               .then(function () {
                  return updateOrder(orderId, destination)
               })
               .catch(changeShipmentError)
         }

         return updateOrder(orderId, destination).catch(changeShipmentError)
      }

      function changeOpenOrderShipmentToNextTrip(orderId, dropId) {
         // Get the order directly from the API to be 100% sure that it is still open before bumping the trip.
         return orderData.getOrder(orderId, true).then(function (order) {
            if (order.status === orderData.statuses.open) {
               return dropService.getEarliestUnconfirmedTripByDropId(dropId, true).then(function (trip) {
                  if (trip) {
                     return updateOrder(orderId, {trip: trip.id})
                  } else {
                     // Drop doesn't have a next trip; remove order from drop
                     return updateOrder(orderId, {drop: undefined, trip: undefined})
                  }
               })
            } else {
               return $q.reject({
                  reason: _errors.ORDER_IS_NOT_OPEN,
               })
            }
         })
      }

      function updateParcelCarrierServices(orderId, parcelCarrierServices, perishableShippingWarningAccepted) {
         var validParcelCarrierServices =
            parcelCarrierServices.all || (parcelCarrierServices.roomTemp && parcelCarrierServices.chilled)
         if (!validParcelCarrierServices) {
            return $q.reject({
               message: 'Please select your parcel carrier options to continue',
            })
         }
         return updateOrder(orderId, {
            'parcel-carrier-services': parcelCarrierServices,
            'perishable-shipping-warning-accepted': perishableShippingWarningAccepted,
         })
      }

      function changePaymentMethod(orderId, paymentMethodId) {
         return updateOrder(orderId, paymentMethodIdToCheckoutPayment(paymentMethodId), true)
      }

      function removePaymentMethod(orderId) {
         return changePaymentMethod(orderId)
      }

      function createOrder(userId, createOrderData) {
         return orderData.createOrder(userId, createOrderData).then(orderCreated)
      }

      function placeOrder(userId, orderId, rewardsToAllocate, ensureRetailSalesFlyer, parcelCarrierShipByDate) {
         return updateOrder(
            orderId,
            {
               status: orderData.statuses.placed,
               'rewards-allocation': rewardsToAllocate || 0,
               ensureRetailSalesFlyer: ensureRetailSalesFlyer,
               parcelCarrierShipByDate: parcelCarrierShipByDate,
            },
            true
         ).then(function (response) {
            // Placing an order changes the `last-order-placed` and  maybe the
            // `first-order-placed` properties on the person object
            personService.personChangedExternal(userId)
            return response
         })
      }

      function deleteOrder(orderId) {
         return orderData.deleteOrder(orderId).then(orderDeleted)
      }

      function updateOrderWithDefaults(orderId, userId, isSuperUser) {
         return orderData.getOrder(orderId).then(function (order) {
            var maybeDefaultDestinationPromise
            var maybeDefaultPaymentMethodPromise
            if (!hasDestination(order)) {
               maybeDefaultDestinationPromise = getDefaultDestinationIfOrderUnchanged(orderId, userId, isSuperUser)
            }

            if (!order['checkout-payment']) {
               maybeDefaultPaymentMethodPromise = getDefaultPaymentMethod(userId)
            }

            return $q
               .allSettled([$q.resolve(maybeDefaultDestinationPromise), $q.resolve(maybeDefaultPaymentMethodPromise)])
               .then(function (results) {
                  var maybeDefaultDestination = results[0].value
                  var maybeDefaultPaymentMethod = results[1].value
                  var maybeCheckoutPayment
                  if (results[0].reason && results[0].reason.invalidPaymentMethod) {
                     // If the attempt to get the default destination failed because the
                     // existing payment method is now invalid, remove the payment method
                     maybeCheckoutPayment = noCheckoutPayment()
                  } else if (maybeDefaultPaymentMethod && maybeDefaultPaymentMethod.active) {
                     maybeCheckoutPayment = paymentMethodIdToCheckoutPayment(maybeDefaultPaymentMethod.id)
                  }

                  if (maybeDefaultDestination || maybeCheckoutPayment) {
                     var orderUpdateData = angular.extend({}, maybeDefaultDestination, maybeCheckoutPayment)
                     return updateOrder(orderId, orderUpdateData)
                  }
               })
         })
      }

      function updateOrderLineQuantity(orderId, orderLineId, quantity) {
         return orderData
            .updateOrderLine(orderLineId, {
               order: orderId,
               'quantity-ordered': quantity,
            })
            .then(function (response) {
               orderLineChanged(orderId)
               return {
                  updatedOrderLine: response['order-line'],
                  newUnitPrice: response.newUnitPrice,
                  oldUnitPrice: response.oldUnitPrice,
                  changes: response.changes,
               }
            })
      }

      function createOrderLine(orderId, productCode, quantity) {
         if (quantity === undefined) {
            quantity = 1
         }
         return orderData.createOrderLine(orderId, productCode, quantity).then(function (response) {
            var orderLine = response['order-line']
            orderLineCreated(orderId)
            return {
               orderLine: orderLine,
               changes: response.changes,
            }
         })
      }

      function deleteOrderLine(orderId, orderLineId) {
         return orderData.deleteOrderLine(orderId, orderLineId).then(function () {
            orderLineDeleted(orderId)
         })
      }

      function addToOrder(orderId, itemsToAdd, forceNewLine) {
         return orderData.getOrderLines(orderId).then(function (orderLines) {
            var addPromises = itemsToAdd.map(function (item) {
               var addOrUpdatePromise
               var orderLine = findMostRecentOrderLineByProductCode(orderLines, item.packaging.code)
               if (forceNewLine || !orderLine) {
                  addOrUpdatePromise = createOrderLine(orderId, item.packaging.code, item.quantity).then(function (
                     response
                  ) {
                     return {
                        orderLine: response.orderLine,
                        quantityAdded: response.orderLine['quantity-ordered'],
                        quantity: item.quantity,
                        packaging: item.packaging,
                        changes: response.changes,
                     }
                  })
               } else {
                  addOrUpdatePromise = updateOrderLineQuantity(
                     orderId,
                     orderLine.id,
                     orderLine['quantity-ordered'] + item.quantity
                  ).then(function (response) {
                     return {
                        orderLine: response.updatedOrderLine,
                        oldUnitPrice: response.oldUnitPrice,
                        newUnitPrice: response.newUnitPrice,
                        quantityAdded: response.updatedOrderLine['quantity-ordered'] - orderLine['quantity-ordered'],
                        quantity: item.quantity,
                        packaging: item.packaging,
                        changes: response.changes,
                     }
                  })
               }
               return addOrUpdatePromise.catch(function (error) {
                  return $q.reject({
                     error: error,
                     quantity: item.quantity,
                     packaging: item.packaging,
                  })
               })
            })
            return $q.allSettled(addPromises)
         })
      }

      function removeFromOrder(orderId, productCode, totalQuantityToRemove) {
         return orderData.getOrderLines(orderId).then(function (orderLines) {
            var oneOrMoreOrderLines = getAllOrderLinesWithProductCode(orderLines, productCode)

            // If no orderLine is found, exit;
            if (oneOrMoreOrderLines.length === 0) {
               return
            }

            var remainingQuantityToRemove = totalQuantityToRemove
            var promises = []

            for (var i = 0; i < oneOrMoreOrderLines.length; i++) {
               var orderLine = oneOrMoreOrderLines[i]
               var removedThisIteration
               // exit
               if (remainingQuantityToRemove === 0) {
                  break
               }
               // delete
               if (orderLine['quantity-ordered'] <= 1 || remainingQuantityToRemove >= orderLine['quantity-ordered']) {
                  promises.push(deleteOrderLine(orderId, orderLine.id))
                  removedThisIteration = orderLine['quantity-ordered']
                  // decrement
               } else if (remainingQuantityToRemove < orderLine['quantity-ordered']) {
                  var remainingQuantity = orderLine['quantity-ordered'] - remainingQuantityToRemove
                  promises.push(updateOrderLineQuantity(orderId, orderLine.id, remainingQuantity))
                  removedThisIteration = remainingQuantityToRemove
               }
               remainingQuantityToRemove -= removedThisIteration
            }
            return $q.all(promises)
         })
      }

      function syncLocalOrderLinesToOrderId(orderId) {
         return orderData.syncLocalOrderLinesToOrderId(orderId).then(function (results) {
            // If any number of orderlines are created,
            // publish a single orderLineCreated event
            var anyOrderLinesAdded = results.some(function (result) {
               return result.state === 'fulfilled'
            })
            if (anyOrderLinesAdded) {
               orderLineCreated(orderId)
            }
            return results
         })
      }

      function maybeSyncLocalOrderLines(orderId) {
         return orderData.getLocalOrderLines().then(function (orderLines) {
            if (orderId && orderLines.length) {
               return syncLocalOrderLinesToOrderId(orderId)
            }
         })
      }

      function updatePackingRequest(orderId, notes) {
         return updateOrder(orderId, {
            notes: notes,
         })
      }

      function updateHomeDeliveryInstructions(orderId, homeDeliveryInstructions) {
         return updateOrder(orderId, {
            homeDeliveryInstructions: homeDeliveryInstructions,
         })
      }

      function updateCustomerPo(orderId, customerPo) {
         return updateOrder(orderId, {
            customerPo: customerPo,
         })
      }

      function addPromoCode(orderId, promoCode) {
         return orderData.addPromoCodeToOrder(orderId, promoCode).then(function () {
            return orderChanged(orderId)
         })
      }

      function removePromoCode(orderId, promoCodeId) {
         return orderData.removePromoCode(orderId, promoCodeId).then(function () {
            return orderChanged(orderId)
         })
      }

      function removeMultiplePromoCodes(orderId, promoCodeIds) {
         return $q
            .all(
               promoCodeIds.map(function (codeId) {
                  return orderData.removePromoCode(orderId, codeId)
               })
            )
            .then(function () {
               return orderChanged(orderId)
            })
      }

      //================================================================================

      return {
         // Helper Methods
         calculateTotals: calculateTotals,
         calculateTotalQuantityOrderedOfPackages: calculateTotalQuantityOrderedOfPackages,
         willProductExpire: willProductExpire,
         canShop: canShop,
         hasDestination: hasDestination,
         isLargeShippingService: isLargeShippingService,

         // Data Update Methods
         placeOrder: placeOrder,
         updateOrder: updateOrder,
         createOrder: createOrder,
         deleteOrder: deleteOrder,
         deleteOrderLine: deleteOrderLine,
         changeShipment: changeShipment,
         changeOpenOrderShipmentToNextTrip: changeOpenOrderShipmentToNextTrip,
         updateParcelCarrierServices: updateParcelCarrierServices,
         addToOrder: addToOrder,
         changePaymentMethod: changePaymentMethod,
         removePaymentMethod: removePaymentMethod,
         updateOrderLineQuantity: updateOrderLineQuantity,
         updatePackingRequest: updatePackingRequest,
         updateHomeDeliveryInstructions: updateHomeDeliveryInstructions,
         updateCustomerPo: updateCustomerPo,
         maybeSyncLocalOrderLines: maybeSyncLocalOrderLines,
         updateOrderWithDefaults: updateOrderWithDefaults,
         removeFromOrder: removeFromOrder,
         addPromoCode: addPromoCode,
         removePromoCode: removePromoCode,
         removeMultiplePromoCodes: removeMultiplePromoCodes,

         // Events
         events: _events,
         subscribe: pubSub.subscribe,

         // Errors
         errors: _errors,
      }
   }
})(angular)
