;(function (angular) {
   'use strict'

   /**
    * @ngdoc directive
    * @name app.directive:asProductFavorite
    * @description
    * # asProductFavorite
    * Handle functionality related to user favoriting a product
    * Requires a product view on current scope's $scope.product
    */

   angular.module('app').directive('asProductFavorite', productFavorite)

   function productFavorite() {
      return {
         controller: productFavoriteController,
      }
   }

   function productFavoriteController($scope, $q, favoriteService, appState, alerts) {
      var product = $scope.product
      if (!product || !appState.userState.isUser) {
         return
      }

      function toggleFavoritePackages(packs) {
         if (product.favoriteProcessing) {
            return
         }

         product.favoriteProcessing = true

         var hadFavoritedPackage = isFavorited()

         // optimistic update
         packs.forEach(function (pack) {
            pack.isFavorited = !pack.isFavorited
            if (pack.favorites !== undefined) {
               pack.favorites += pack.isFavorited ? 1 : -1
            }
         })
         var originalProductFavorites = product.favorites
         if (product.favorites !== undefined) {
            if (isFavorited() && !hadFavoritedPackage) {
               product.favorites++
            } else if (!isFavorited() && hadFavoritedPackage) {
               product.favorites--
            }
         }

         return $q
            .mapSettled(packs, function (pack) {
               return favoriteService.toggleFavorite(appState.userState.id, pack.code)
            })
            .then(function (results) {
               var anyFailures = false
               packs.forEach(function (pack, i) {
                  if (results[i].state === 'rejected') {
                     // roll back failures
                     anyFailures = true
                     pack.isFavorited = !pack.isFavorited
                     if (pack.favorites !== undefined) {
                        pack.favorites += pack.isFavorited ? 1 : -1
                     }
                  }
               })

               if (anyFailures) {
                  alerts.error()
                  if (product.favorites !== undefined) {
                     product.favorites = originalProductFavorites
                  }
               }
               product.favoriteProcessing = false
            })
      }

      $scope.toggleFavoriteProduct = function () {
         var packages = product.packaging.filter(function (pack) {
            return pack.isFavorited
         })
         if (!packages.length) {
            packages = [product.selectedPackagingOrDefault]
         }
         return toggleFavoritePackages(packages)
      }

      $scope.toggleFavoritePackage = function (pack) {
         return toggleFavoritePackages([pack])
      }

      function initFavorites() {
         $q.map(product.packaging, function (pack) {
            return favoriteService.isFavorited(appState.userState.id, pack.code).then(function (isFavorited) {
               pack.isFavorited = isFavorited
            })
         })
      }

      function isFavorited() {
         return product.packaging.some(function (pack) {
            return pack.isFavorited
         })
      }

      $scope.isFavorited = isFavorited

      //================================================================================
      // On Directive Init
      //================================================================================

      initFavorites()
   }
})(angular)
