;(function (angular) {
   'use strict'

   /**
    * @ngdoc directive
    * @name app.directive:asProductGridItem
    * @description
    * # asProductGridItem
    * Display a single item in list of products as a grid item.
    */

   angular.module('app').directive('asProductGridItem', productGridItem)

   // Set up image lazy loading observer
   var _productImageLazyLoadObserver
   var _scopeKey
   if (window.IntersectionObserver) {
      _scopeKey = 'productGridItemScope'
      _productImageLazyLoadObserver = new window.IntersectionObserver(intersectionObserverCallback, {
         rootMargin: '125px',
      })
   }

   function productGridItem() {
      return {
         controller: productGridItemController,
         scope: {
            product: '=',
            productList: '@?',
            filterDescription: '@?',
            position: '=',
            flagText: '@?',
            inFlickity: '=?',
            parentFeaturePath: '@',
            showAllSizes: '=',
         },
         replace: true,
         templateUrl: 'inlineTemplates/productGridItem.htm',
      }
   }

   function productGridItemController($scope, $element, appState, orderService, packagedProductTagService, util) {
      $scope.featurePath = $scope.parentFeaturePath + ':productGridItem'
      $scope.packaging = $scope.showAllSizes ? $scope.product.packaging : $scope.product.filteredPackaging
      $scope.hasActivePackaging = $scope.showAllSizes
         ? $scope.product.hasActivePackaging
         : $scope.product.hasActiveFilteredPackaging

      function updateTotalPackagesInCart() {
         if (appState.activeOrderState) {
            $scope.product.totalPackagesInCart = orderService.calculateTotalQuantityOrderedOfPackages(
               appState.activeOrderState.order.lineViews,
               $scope.packaging
            )
         }
      }

      function attachLazyLoadObserver() {
         if (_productImageLazyLoadObserver) {
            var target = $element[0]
            $element.data(_scopeKey, $scope)
            _productImageLazyLoadObserver.observe(target)
            $scope.$on('$destroy', function () {
               _productImageLazyLoadObserver.unobserve(target)
            })
         } else {
            $scope.loadImage = true
         }
      }

      if ($scope.inFlickity) {
         $scope.$on('flickityLoaded', function () {
            attachLazyLoadObserver()
         })
      } else {
         attachLazyLoadObserver()
      }

      //================================================================================
      // On Directive Init
      //================================================================================

      if ($scope.packaging.length > 1) {
         var prices = util.compact(
            $scope.packaging.map(function (pack) {
               return pack.userPrice && pack.userPrice.dollarsMedian
            })
         )
         $scope.minPrice = Math.min.apply(Math, prices)
         $scope.maxPrice = Math.max.apply(Math, prices)

         var combined = packagedProductTagService.getCombinedLabelsAndDiscount(
            $scope.packaging,
            appState.userState.priceSettings.priceLevel
         )

         $scope.discountPercent = combined.discountPercent
         $scope.labels = combined.labels

         updateTotalPackagesInCart()

         appState.subscribe(appState.events.orderRefreshed, updateTotalPackagesInCart, $scope)
      } else if ($scope.packaging[0]) {
         if ($scope.packaging[0].userPrice) {
            $scope.discountPercent = $scope.packaging[0].userPrice.discountPercent
         }
         $scope.labels = $scope.packaging[0].labels
      }
   }

   function intersectionObserverCallback(entries) {
      var entriesInView = entries.filter(function (entry) {
         return entry.intersectionRatio
      })

      if (!entriesInView.length) {
         return
      }

      entriesInView.forEach(function (change) {
         var $element = angular.element(change.target)
         var scope = $element.data(_scopeKey)
         if (!scope) {
            return
         }
         scope.$evalAsync(function (scope) {
            scope.loadImage = true
         })
         _productImageLazyLoadObserver.unobserve(change.target)
      })
   }
})(angular)
