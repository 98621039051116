;(function (angular) {
   'use strict'

   angular.module('app').directive('asCartSlideout', cartSlideout)

   function cartSlideout() {
      return {
         templateUrl: 'inlineTemplates/cartSlideout.htm',
         controller: function ($scope) {
            $scope.featurePath = 'cartSlideout'
         },
      }
   }
})(angular)
