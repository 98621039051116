;(function (angular) {
   'use strict'

   /**
    * @ngdoc directive
    * @name app.directive:asQuickAdd
    * @description
    * # asQuickAdd
    * Add a new orderLine to cart using the packaged-product code.
    */

   angular.module('app').directive('asQuickAdd', quickAdd)

   function quickAdd() {
      return {
         controller: quickAddController,
      }
   }

   function quickAddController($rootScope, $timeout, $scope, $element, viewService, alerts, appState) {
      var _featurePath = $scope.featurePath + ':quickAdd'

      $scope.productQty = null
      $scope.productCode = null

      //================================================================================
      // Helpers
      //================================================================================

      function maybeSetDefaultQty() {
         $scope.productQty = $scope.productQty === null ? 1 : $scope.productQty
      }

      //================================================================================
      // Scope functions
      //================================================================================

      $scope.showQuickAdd = function () {
         $rootScope.quickAddOpen = true
         $timeout(function () {
            $element.find('#js-quickCode').focus()
         })
      }

      $scope.handleCodeChange = maybeSetDefaultQty

      $scope.handleQtyFocus = function ($event) {
         maybeSetDefaultQty()
         $timeout(function () {
            $event.target.select()
         })
      }

      $scope.addOrderLine = function () {
         if (!$scope.productCode) {
            return
         }

         var productCode = $scope.productCode.toUpperCase()
         viewService
            .getProductViewByCode(productCode, {
               priceSettings: appState.userState.priceSettings,
            })
            .then(function (product) {
               if (product && product.selectedPackaging) {
                  if (appState.activeOrderState.order && appState.activeOrderState.order.cannotAddProduct(product)) {
                     var productWarning = appState.activeOrderState.order.getProductWarning(product, 'long')
                     alerts.errorMessage(productWarning)
                  } else {
                     var quantity = $scope.productQty || 1
                     appState.activeOrderState
                        .addItem(product.selectedPackaging, quantity, true, _featurePath)
                        .then(function () {
                           $scope.productCode = null
                           $scope.productQty = null
                           $element.find('#js-quickCode').focus()
                        })
                  }
               } else {
                  alerts.errorMessage('Product with code ' + productCode + ' not found.')
               }
            })
      }
   }
})(angular)
