/* jshint -W106 */

;(function (angular) {
   'use strict'

   angular.module('app').directive('asBlockFormCatalogRequest', blockFormCatalogRequest)

   function blockFormCatalogRequest() {
      return {
         templateUrl: 'partials/block_form_catalog_request.htm',
         restrict: 'A',
         controller: blockFormCatalogRequestController,
         scope: {
            block: '=',
         },
      }
   }

   function blockFormCatalogRequestController(
      $scope,
      $filter,
      $q,
      $http,
      config,
      appState,
      AzureAlgolia,
      util,
      regionData,
      addressService,
      alerts
   ) {
      var _localState = {
         initialLoadComplete: false,
         busy: false,
         allowErrorMessageOnCatalogCheckboxGroup: true,
         submissionSuccess: false,
         submissionFailure: false,
      }

      $scope.localState = _localState

      var _formInputs = {
         catalogsToSend: [],
      }

      $scope.formInputs = _formInputs

      $scope.catalogOptionsCheckedStates = {}

      //================================================================================
      // Submit handler
      //================================================================================

      $scope.submitHandler = function (isInvalid) {
         _localState.allowErrorMessageOnCatalogCheckboxGroup = true

         $scope.selectedCatalogCodes = $scope.catalogOptions.reduce(function (acc, item) {
            if ($scope.catalogOptionsCheckedStates[item.code]) {
               acc.push(item.code)
            }
            return acc
         }, [])

         if (isInvalid || !$scope.selectedCatalogCodes.length) {
            alerts.error({
               message: 'Please check errors and try again',
               autoClose: true,
            })
            return $q.reject()
         }

         // Prevent duplicate submissions
         if (_localState.busy) {
            return
         }

         _localState.busy = true

         return util
            .asyncRetryOnce($http, {
               method: 'POST',
               url: config.beehiveApiV2 + '/catalog_requests',
               data: {
                  catalogCodes: $scope.selectedCatalogCodes,
                  email: _formInputs.email,
                  firstName: _formInputs.firstName,
                  lastName: _formInputs.lastName,
                  address1: _formInputs.address1,
                  address2: _formInputs.address2,
                  city: _formInputs.city,
                  stateAbbrev: _formInputs.region.abbreviation,
                  zip: _formInputs.zip,
               },
            })
            .then(function () {
               _localState.submissionSuccess = true
            })
            .catch(function () {
               _localState.submissionFailure = true
            })
            .finally(function () {
               _localState.busy = false
            })
      }

      //================================================================================
      // Init
      //================================================================================

      function initRegions() {
         // Note: This form only supports addresses in the USA.
         return regionData.getRegionsByCountryIso3('USA').then(function (regions) {
            $scope.regions = regions
         })
      }

      function maybeAutoFillAddress() {
         if (!appState.userState.id) {
            return $q.resolve()
         }

         return addressService.getUserPrimaryShippingAddress(appState.userState.id).then(function (address) {
            // If the country is set to anything other than the US do not prefill it.
            if (address.country && !['UNITED STATES', 'US', 'USA'].includes(address.country)) {
               return $q.resolve()
            }

            _formInputs.email = appState.userState.email
            _formInputs.firstName = $filter('firstName')(address.name)
            _formInputs.lastName = $filter('lastName')(address.name)
            _formInputs.zip = address['postal-code']
            _formInputs.city = address.locality
            _formInputs.address1 = address['street-address']
            _formInputs.address2 = address['extended-address']
            _formInputs.region = $filter('filter')($scope.regions, {
               name: address.region,
            })[0]
         })
      }

      function initCatalogOptions() {
         return (
            AzureAlgolia.createIndexProxy(config.algoliaIndexNames.products, {
               cacheObjects: false,
            })
               // Note: `34206` is the category ID for the "Azure Catalogs & Sale Flyers" category
               .search('', {facetFilters: ['category-ids:34206'], attributesToRetrieve: 'name,packaging.code'})
               .then(function (algoliaResponse) {
                  $scope.catalogOptions = algoliaResponse.hits.map(function (hit) {
                     return {
                        label: hit.name,
                        code: hit.packaging[0].code,
                     }
                  })
               })
         )
      }

      function init() {
         return $q
            .all([initCatalogOptions(), initRegions()])
            .then(maybeAutoFillAddress)
            .catch(alerts.error)
            .finally(function () {
               _localState.initialLoadComplete = true
            })
      }
      init()
   }
})(angular)
