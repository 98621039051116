;(function (angular) {
   'use strict'

   angular.module('app').factory('productService', productService)

   function productService() {
      //================================================================================
      // Temperature Types
      //================================================================================

      var _temperatureTypes = Object.freeze({
         frozen: {
            key: 'frozen',
            name: 'Frozen',
            bgClass: 'bg-tempFrozen',
            bgClassWithOpacity: 'bg-tempFrozen/[0.035]',
            beforeBgClass: 'before:bg-tempFrozen',
         },
         chilled: {
            key: 'chilled',
            name: 'Chilled',
            bgClass: 'bg-tempChilled',
            bgClassWithOpacity: 'bg-tempChilled/[0.025]',
            beforeBgClass: 'before:bg-tempChilled',
         },
         roomTemp: {
            key: 'roomTemp',
            name: 'Room Temp',
            bgClass: 'bg-tempRoom',
            bgClassWithOpacity: 'bg-tempRoom/[0.025]',
            beforeBgClass: 'before:bg-tempRoom',
         },
         greenhouse: {
            key: 'greenhouse',
            name: 'Greenhouse',
            bgClass: 'bg-tempGreenhouse',
            bgClassWithOpacity: 'bg-tempGreenhouse/[0.025]',
            beforeBgClass: 'before:bg-tempGreenhouse',
         },
      })

      function getTemperatureType(storageClimate) {
         if (storageClimate === 'frozen') {
            return _temperatureTypes.frozen
         } else if (storageClimate === 'chilled') {
            return _temperatureTypes.chilled
         } else if (storageClimate === 'greenhouse') {
            return _temperatureTypes.greenhouse
         }
         return _temperatureTypes.roomTemp
      }

      function temperatureTypeComparer(temperatureTypeA, temperatureTypeB) {
         var temperatureTypeValues = Object.values(_temperatureTypes)
         return temperatureTypeValues.indexOf(temperatureTypeA) - temperatureTypeValues.indexOf(temperatureTypeB)
      }

      //================================================================================

      return {
         getTemperatureType: getTemperatureType,
         temperatureTypes: _temperatureTypes,
         temperatureTypeComparer: temperatureTypeComparer,
      }
   }
})(angular)
