;(function (angular) {
   'use strict'

   angular.module('app').factory('searchService', searchService)

   function searchService($rootScope, $state, appState, productData, searchQueryData) {
      function goToShopSearch(query) {
         return $state.go('shop.search', {
            query: query,
            tag: '',
            clearance: false,
            sort: '',
            brand: '',
            category: '',
            cart: false,
         })
      }

      function shopSearch(query) {
         $rootScope.appLoading = true
         return productData
            .getProductByQuery(query)
            .then(function (product) {
               if (product) {
                  var code
                  // product.codes are the product codes that exactly match the query
                  if (product.codes && product.codes.length) {
                     // If there are any exact matches, select the one most recently ordered by the user (if any);
                     // otherwise, the first one
                     var packaging = product.packaging.filter(function (pack) {
                        return product.codes.includes(pack.code)
                     })
                     var orderedSummary = appState.userState.orderedSummaryByPackaging(packaging)
                     code = orderedSummary.code || product.codes[0]
                  }

                  return $state.go('shop.product', {
                     id: product.id,
                     path: product.slug,
                     package: code,
                     cart: false,
                  })
               } else {
                  return goToShopSearch(query)
               }
            })
            .catch(function () {
               return goToShopSearch(query)
            })
            .finally(function () {
               $rootScope.appLoading = false
            })
      }

      function saveOrUpdateSearchQuery(userId, query) {
         searchQueryData.saveOrUpdateSearchQuery(userId, query)
      }

      function removeSearchQuery(userId, query) {
         searchQueryData.removeSearchQuery(userId, query)
      }

      function findSearchQueries(userId, queryPrefix) {
         var queries = searchQueryData.getSearchQueries(userId)
         return Object.keys(queries).reduce(function (matches, query) {
            if (query.startsWith(queryPrefix)) {
               matches[query] = queries[query]
            }
            return matches
         }, {})
      }

      //================================================================================

      return {
         shopSearch: shopSearch,
         saveOrUpdateSearchQuery: saveOrUpdateSearchQuery,
         removeSearchQuery: removeSearchQuery,
         findSearchQueries: findSearchQueries,
      }
   }
})(angular)
