;(function (angular) {
   'use strict'

   angular.module('app').factory('cookieService', cookieService)

   function cookieService($cookies, config) {
      var longExpires = new Date(2030, 12, 31)

      function set(key, value) {
         return $cookies.put(key, value, {
            domain: config.cookieDomain,
            expires: longExpires,
            secure: location.protocol === 'https',
         })
      }

      function get(key) {
         return $cookies.get(key)
      }

      // ====================================================================

      return {
         set: set,
         get: get,
      }
   }
})(angular)
