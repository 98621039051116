;(function (angular) {
   'use strict'

   angular.module('app').directive('asPaymentMethodSelect', paymentMethodSelect)

   function paymentMethodSelect() {
      return {
         templateUrl: 'partials/payment_method_select.htm',
         controller: paymentMethodSelectController,
         scope: {
            allowedPaymentMethodTypes: '=',
            context: '@',
            selectedPaymentMethod: '=',
            selectPaymentMethod: '&',
         },
      }
   }

   function paymentMethodSelectController(
      $scope,
      $q,
      modalService,
      viewService,
      appState,
      paymentMethodService,
      alerts
   ) {
      $scope.addPaymentMethodModal = function (paymentMethodType) {
         modalService.addPaymentMethod(paymentMethodType).result.then($scope.selectNewPaymentMethod, angular.noop)
      }

      $scope.selectNewPaymentMethod = function (paymentMethod) {
         $scope.selectPaymentMethod({
            paymentMethod: paymentMethod,
            newCard: true,
         })
      }

      function maybeAddSelectedPaymentMethod() {
         if ($scope.selectedPaymentMethod && $scope.paymentMethods) {
            var selectedPaymentMethodFound = $scope.paymentMethods.some(function (paymentMethod) {
               return paymentMethod.id === $scope.selectedPaymentMethod.id
            })
            if (!selectedPaymentMethodFound) {
               $scope.paymentMethods.unshift($scope.selectedPaymentMethod)
            }
         }
      }

      function maybeFilterPaymentMethods(paymentMethods) {
         if ($scope.allowedPaymentMethodTypes) {
            return paymentMethods.filter(function (paymentMethod) {
               return $scope.allowedPaymentMethodTypes.includes(paymentMethod.type)
            })
         }
         return paymentMethods
      }

      $scope.$watch('selectedPaymentMethod', maybeAddSelectedPaymentMethod)

      function initPaymentMethods() {
         $scope.loading = true

         var maybeAddPaymentMethodPromise
         var tokenId = paymentMethodService.getTokenId(true)
         if (tokenId) {
            maybeAddPaymentMethodPromise = paymentMethodService
               .completeVaultStepThree(tokenId)
               .then(function (paymentMethod) {
                  alerts.successMessage('Securely saved payment method!')
                  $scope.selectNewPaymentMethod(paymentMethod)
               })
               .catch(alerts.error)
         }

         return $q.resolve(maybeAddPaymentMethodPromise).finally(function () {
            return viewService
               .getActivePaymentMethodViews(appState.userState.id)
               .then(maybeFilterPaymentMethods)
               .then(function (paymentMethodViews) {
                  $scope.paymentMethods = paymentMethodViews
                  maybeAddSelectedPaymentMethod()
               })
               .finally(function () {
                  $scope.loading = false
               })
         })
      }

      //================================================================================
      // Initialization
      //================================================================================

      function init() {
         initPaymentMethods()
      }

      init()
   }
})(angular)
