;(function (angular) {
   'use strict'

   angular.module('app').directive('asHint', asHint)

   function asHint() {
      var _hintStates = Object.freeze({
         open: 1,
         closed: 2,
         dismissed: 3,
      })

      return {
         templateUrl: 'partials/hint.htm',
         transclude: true,
         controller: hintController,
         scope: {
            hintName: '@',
            hintLine: '@',
            hintPopoverPlacement: '@',
         },
      }

      function hintController($scope, $transclude, $templateCache, appState, personData) {
         var hintPreference = 'hint-' + $scope.hintName
         personData.getPreferenceWithDefault(appState.userState.id, hintPreference, true).then(function (hintState) {
            var hintVisible = hintState !== _hintStates.dismissed
            $scope.hintVisible = hintVisible
            if (!hintVisible) {
               return
            }

            $scope.popoverIsOpen = hintState === _hintStates.open

            var hintContent = angular
               .element('<div class="w-[237px] px-1 py-2.5"></div>')
               .append($transclude())
               .append(
                  '<button class="bg-blue-600 text-blue-50 hocus:bg-blue-600/90 no-underline hocus:no-underline text-sm tracking-[0.9px] px-2 py-1 rounded-lg" ng-click="close()">Ok, got it</button>'
               )

            var html = hintContent[0].outerHTML
            var template = $scope.hintName + '-hintPopoverTemplate.htm'
            $templateCache.put(template, html)
            $scope.template = template

            $scope.hintClicked = function () {
               $scope.popoverIsOpen = !$scope.popoverIsOpen
               var hintState = $scope.popoverIsOpen ? _hintStates.open : _hintStates.closed
               personData.updatePreference(appState.userState.id, hintPreference, hintState)
            }

            $scope.close = function () {
               $scope.popoverIsOpen = false
               $scope.hintVisible = false
               personData.updatePreference(appState.userState.id, hintPreference, _hintStates.dismissed)
            }
         })
      }
   }
})(angular)
