;(function (angular, undefined) {
   'use strict'

   angular.module('app').factory('favoriteService', favoriteService)

   function favoriteService(favoriteData) {
      //================================================================================
      // Helper Methods
      //================================================================================

      function isFavorited(userId, code) {
         return favoriteData.getFavoriteByCode(userId, code).then(function (favorite) {
            return favorite !== undefined
         })
      }

      //================================================================================
      // Data Update Methods
      //================================================================================

      function deleteFavorite(userId, favoriteId) {
         return favoriteData.deleteFavorite(userId, favoriteId)
      }

      // Returns a promise that resolves with the favorite if it was added, undefined if was deleted
      function toggleFavorite(userId, code) {
         return favoriteData.getFavoriteByCode(userId, code).then(function (favorite) {
            if (favorite) {
               return favoriteData.deleteFavorite(userId, favorite.id)
            } else {
               return favoriteData.addFavorite(userId, code)
            }
         })
      }

      function updateFavoriteQuantity(userId, favoriteId, quantity) {
         return favoriteData.updateFavorite(userId, favoriteId, {
            quantity: quantity,
         })
      }

      //================================================================================

      return {
         isFavorited: isFavorited,

         // Data Update Methods
         deleteFavorite: deleteFavorite,
         toggleFavorite: toggleFavorite,
         updateFavoriteQuantity: updateFavoriteQuantity,
      }
   }
})(angular)
