;(function (angular) {
   'use strict'

   angular.module('app').directive('asResponsiveBgImageStyleAttrVal', responsiveBgImageStyleAttrVal)

   function responsiveBgImageStyleAttrVal($templateRequest, $interpolate) {
      return {
         scope: {
            imageSrc: '=',
            imageW: '=',
            imageH: '=',
         },
         link: function (scope, element) {
            if (!scope.imageSrc) {
               return
            }
            $templateRequest('partials/responsive_background_image_style_attr_val.htm').then(function (html) {
               var template = $interpolate(html)(scope)
               element.attr('style', template)
            })
         },
      }
   }
})(angular)
