;(function (angular) {
   'use strict'

   angular.module('app').factory('accountEntryService', accountEntryService)

   function accountEntryService($q, accountEntryData, personService) {
      function getAccountBalance(userId, bypassCache) {
         return accountEntryData.getLastOne(userId, bypassCache).then(function (lastAccountEntry) {
            return lastAccountEntry ? lastAccountEntry.balance : 0
         })
      }

      function getAccountBalanceWithPending(userId, bypassCache) {
         return $q
            .all([
               getAccountBalance(userId, bypassCache),
               accountEntryData.getPendingPaymentsState(userId, bypassCache),
            ])
            .then(function (responses) {
               var settledBalance = responses[0]
               var pendingPaymentsState = responses[1]
               var hasPending = pendingPaymentsState && pendingPaymentsState.count > 0

               return {
                  settledBalance: settledBalance,
                  hasPending: hasPending,
                  pendingPaymentsCount: pendingPaymentsState && pendingPaymentsState.count,
                  pendingSum: pendingPaymentsState && pendingPaymentsState.sum,
                  pendingBalance: hasPending ? settledBalance + pendingPaymentsState.sum : settledBalance,
               }
            })
      }

      function makePayment(userId, paymentMethodId, amount) {
         return accountEntryData
            .makePayment(userId, {
               'payment-method': paymentMethodId,
               amount: amount,
            })
            .then(function () {
               // Making a payment might change the `order-place-issue` property on the
               // person object
               personService.personChangedExternal(userId)
            })
      }

      function downloadAccountEntries(userId, apiFilterParams) {
         return accountEntryData.downloadAccountEntries(userId, apiFilterParams).then(function (response) {
            var blob = new Blob([response.data], {type: 'text/csv'})
            var downloadUrl = URL.createObjectURL(blob)
            var a = document.createElement('a')
            a.href = downloadUrl

            // Create the filename
            var filename = 'azure-standard-billing-history'
            if (apiFilterParams.historyStart && apiFilterParams.historyEnd) {
               filename = filename + '-from-' + apiFilterParams.historyStart + '-to-' + apiFilterParams.historyEnd
            }
            a.download = filename + '.csv'

            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
         })
      }

      //================================================================================

      return {
         getAccountBalance: getAccountBalance,
         getAccountBalanceWithPending: getAccountBalanceWithPending,
         makePayment: makePayment,
         downloadAccountEntries: downloadAccountEntries,
      }
   }
})(angular)
