;(function (angular) {
   'use strict'

   angular.module('app').factory('mapboxService', mapboxService)

   function mapboxService($q, $ocLazyLoad) {
      var _loaded = false

      function load() {
         if (_loaded) {
            return $q.resolve()
         }

         return $ocLazyLoad
            .load([
               '/lazy-dependencies/mapbox-gl-supported.1.5.0.js',
               'https://cdnjs.cloudflare.com/ajax/libs/mapbox-gl/1.13.3/mapbox-gl.min.js',
               'https://cdnjs.cloudflare.com/ajax/libs/mapbox-gl/1.13.3/mapbox-gl.min.css',
            ])
            .then(function () {
               _loaded = true
            })
      }

      // ====================================================================

      return {
         load: load,
      }
   }
})(angular)
