;(function (angular) {
   'use strict'

   angular.module('app').directive('asNavSlideout', navSlideout)

   function navSlideout() {
      return {
         templateUrl: 'inlineTemplates/navSlideout.htm',
         controller: navSlideoutController,
      }
   }

   function navSlideoutController(
      $scope,
      $rootScope,
      $transitions,
      $q,
      util,
      categoryData,
      viewService,
      appState,
      dropData
   ) {
      $scope.featurePath = 'navSlideout'

      //================================================================================
      // Menus (top-level)
      //================================================================================

      var _menu = [
         {
            id: 'main',
            heading: 'Main Menu',
            level: 0,
         },
         {
            id: 'shop-root',
            heading: 'Shop Menu',
            parentId: 'main',
            level: 1,
         },
         {
            id: 'shop-child',
            heading: 'Shop Menu',
            parentId: 'shop-root',
            hasSubheading: true,
            level: 2,
         },
         {
            id: 'account-root',
            heading: 'My Account',
            parentId: 'main',
            level: 1,
         },
         {
            id: 'azure-life',
            heading: 'Azure Life',
            parentId: 'main',
            level: 1,
         },
         {
            id: 'about',
            heading: 'About',
            parentId: 'main',
            level: 1,
         },
         {
            id: 'dc',
            heading: 'Drop Coordinator',
            parentId: 'main',
            level: 1,
         },
         {
            id: 'dd',
            heading: 'Drop Driver',
            parentId: 'main',
            level: 1,
         },
      ]

      //================================================================================
      // Initialize scope vars
      //================================================================================

      $scope.activeMenu = _menu[0]

      //================================================================================
      // Scope Functions
      //================================================================================

      $scope.goToMenu = function (menuItemId) {
         $scope.activeMenu = util.findById(_menu, menuItemId)
      }

      //================================================================================
      // Section Specific: Shop
      //================================================================================

      $scope.toggleShopCat = function (parent) {
         $scope.currentCatId = parent.id
         categoryData
            .getCategoriesByParent(parent.id)
            .then(viewService.toCategoryViews)
            .then(function (categoryViews) {
               $scope.childCategories = categoryViews
            })
      }

      //================================================================================
      // Section Specific: DC
      //================================================================================

      $scope.loadDcDropMemberships = function () {
         // Note that there's a good amount of duplicated code here from dc.controller.js

         // Note that the `dcDrops` are filtered (only drops the user is a DC of) whereas
         // `dcDropMemberships` are all of the user's drops. This is why we're mapping here
         // against `dcDrops` rather than `dcDropMemberships`.
         $q.all([
            viewService.getCoordinatorDropViews(appState.userState.id),
            dropData.getActiveDropMemberships(appState.userState.id),
         ]).then(function (results) {
            var dcDrops = results[0]
            var dcDropMemberships = results[1]

            $scope.dropMemberships = dcDrops.map(function (drop) {
               var dropMembership = dcDropMemberships.find(function (dropMembership) {
                  return dropMembership.drop === drop.id
               })
               return angular.extend({}, dropMembership, {dropView: drop})
            })
         })
      }

      //================================================================================
      // Section Specific: DD
      //================================================================================

      $scope.loadDdDropMemberships = function () {
         $q.all([
            dropData.getUserActiveDrops(appState.userState.id).then(function (allUserDrops) {
               return allUserDrops.filter(function (drop) {
                  return appState.userState.user.homeDeliveryDriverDropIds.includes(drop.id)
               })
            }),
            dropData.getActiveDropMemberships(appState.userState.id),
         ]).then(function (results) {
            var ddDrops = results[0]
            var ddDropMemberships = results[1]

            $scope.ddDropMemberships = ddDrops.map(function (drop) {
               var dropMembership = ddDropMemberships.find(function (dropMembership) {
                  return dropMembership.drop === drop.id
               })
               return angular.extend({}, dropMembership, {dropView: drop})
            })
         })
      }

      //================================================================================
      // Reset
      //================================================================================

      function resetMenu() {
         $scope.activeMenu = _menu[0]
      }

      $scope.$on('$locationChangeSuccess', resetMenu)

      $scope.$on(
         '$destroy',
         $transitions.onStart({}, function () {
            $rootScope.showNavSlideout = false
         })
      )
   }
})(angular)
