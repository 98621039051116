;(function (angular) {
   'use strict'

   angular.module('app').directive('asDomListFilterSort', domListFilterSort)

   function domListFilterSort() {
      return {
         controller: domListFilterSortController,
      }
   }

   function domListFilterSortController($scope, $attrs) {
      var _localState = {
         itemHtmlCollection: document.getElementsByClassName('js-filterSearchListItem'),
         searchValue: undefined,
         selectedSortOptionKey: $attrs.defaultSortOptionKey,
         reverseSortOrder: false,
      }
      $scope.localState = _localState
      _localState.visibleItemCount = _localState.itemHtmlCollection.length

      $scope.filterOnSearch = function () {
         _localState.visibleItemCount = _localState.itemHtmlCollection.length
         Object.values(_localState.itemHtmlCollection).forEach(function (item) {
            if (item.dataset.filterAttributes.indexOf(_localState.searchValue.toLowerCase()) > -1) {
               item.classList.add($attrs.activeClassName)
               item.classList.remove('hidden')
            } else {
               item.classList.remove($attrs.activeClassName)
               item.classList.add('hidden')
               _localState.visibleItemCount--
            }
         })
      }

      $scope.clearSearchFilter = function () {
         _localState.searchValue = ''
         $scope.filterOnSearch()
      }

      $scope.sortList = function () {
         var items = Array.prototype.slice.call(_localState.itemHtmlCollection)

         // Sort (note: this does not update the items in the DOM)
         items.sort(function (a, b) {
            return JSON.parse(a.dataset.sortAttributes)[_localState.selectedSortOptionKey] >
               JSON.parse(b.dataset.sortAttributes)[_localState.selectedSortOptionKey]
               ? 1
               : -1
         })

         // Re-attach the sorted list (to update the DOM)
         // src: https://jsfiddle.net/jmarikle/vxju87nv/
         for (var i = 0, length = items.length; i < length; i++) {
            // Store the parent node so we can reatach the item
            var parent = items[i].parentNode
            // Detach it from wherever it is in the DOM
            var detatchedItem = parent.removeChild(items[i])
            // Reatach it. This works because we are itterating
            // over the items in the same order as they were re-
            // turned from being sorted.
            parent.appendChild(detatchedItem)
         }
      }

      $scope.reverseList = function () {
         // https://gomakethings.com/how-to-reverse-a-linked-list-with-vanilla-js/
         Object.values(_localState.itemHtmlCollection).forEach(function (item) {
            item.parentNode.insertBefore(item, item.parentNode.firstChild)
         })
      }
   }
})(angular)
