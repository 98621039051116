;(function (angular) {
   'use strict'

   angular.module('app').directive('asClickToCopy', clickToCopy)

   function clickToCopy() {
      return {
         controller: clickToCopyController,
         restrict: 'A',
         scope: {
            copyTargetId: '@?',
            copyText: '@?',
            nounForSuccessMessage: '@?',
         },
      }
   }

   function clickToCopyController($window, $rootScope, $scope, $q, alerts, $element) {
      $element.on('click', function () {
         $rootScope.blur()

         if (!$scope.copyTargetId && !$scope.copyText) {
            return
         }

         var textToCopy = $scope.copyText ? $scope.copyText : document.getElementById($scope.copyTargetId).textContent

         $q.resolve($window.clipboard.writeText(textToCopy))
            .then(function () {
               alerts.success({
                  message: $scope.nounForSuccessMessage ? 'Copied ' + $scope.nounForSuccessMessage + '!' : 'Copied!',
                  fullscreenOverlay: true,
                  duration: $scope.nounForSuccessMessage ? 1400 : 900,
               })
            })
            .catch(function () {
               alerts.errorMessage('Unable to copy.')
            })
      })
   }
})(angular)
