;(function (angular) {
   'use strict'

   angular.module('app').directive('asKeyValueBoxEditUiHandler', keyValueBoxEditUiHandler)

   function keyValueBoxEditUiHandler() {
      return {
         controller: controller,
      }
   }

   function controller($scope, $rootScope, $timeout, util, alerts) {
      //================================================================================
      // Internal functions
      //================================================================================

      function showEditUi(idToFocus, maybeParentShowHandler) {
         $timeout(function () {
            var toFocus = document.getElementById(idToFocus)
            if (toFocus) {
               toFocus.focus()
            }
         })
         if (maybeParentShowHandler) {
            maybeParentShowHandler()
         }
         $scope.isEditUiOpen = true
         $scope.editingId = idToFocus
      }

      function closeEditUi(bypassLocalModelsUpdate) {
         $scope.isEditUiOpen = false
         $rootScope.removeUnderlayClass()
         // Note: this is defined on the parent scope
         if (!bypassLocalModelsUpdate) {
            $scope.createOrUpdateLocalModels()
         }
      }

      //================================================================================
      // Scope functions
      //================================================================================

      $scope.showEditUi = showEditUi
      $scope.closeEditUi = closeEditUi

      $scope.getDisplayText = function (items, value) {
         var lookupIndex = util.findIndexByPropertyValue(items, 'value', value)
         if (lookupIndex > -1) {
            return items[lookupIndex].label
         } else {
            return 'Not set'
         }
      }

      $scope.checkboxToggleHandler = function (value, modelProp) {
         // Handle the case of `modelProp` being `undefined`,
         // since "not set" properties are not returned by the backend.
         $scope.modelAsWrite[modelProp] = $scope.modelAsWrite[modelProp] || []

         var model = $scope.modelAsWrite[modelProp]
         var index = model.indexOf(value)

         // Is already selected, remove
         if (model.includes(value)) {
            model.splice(index, 1)
         }
         // Is not already selected, add
         else {
            model.push(value)
         }
      }

      $scope.handleToggleChange = function defaultToggleChangeHandler(editId, modelProp) {
         // `editId` here is assigned in order to show the loading overlay
         $scope.editingId = editId
         $scope.saveField(true, modelProp, 'toggle')
      }

      $scope.saveField = function (valid, modelProp, inputType) {
         console.log(arguments)
         closeEditUi(true)

         if (!valid || !modelProp) {
            return
         }

         $scope.modelAsWrite.busy = true
         // Note: Props array defined here and used elsewhere in this function
         // to support single-nested property updating.
         var propsArray = modelProp.split('.')
         // Required scope variables defined by the parent controller
         var modelAsRead = $scope.modelAsRead
         var modelAsWrite = $scope.modelAsWrite
         var parentSaver = $scope.parentSaver

         // If no change...
         if (inputType !== 'toggle' && angular.equals(modelAsRead[propsArray[0]], modelAsWrite[propsArray[0]])) {
            closeEditUi()
            return
         }

         var updateData = {}
         updateData[propsArray[0]] = modelAsWrite[propsArray[0]]

         // Special handling for password form:
         if ($scope.updateDataCustomHandler) {
            updateData = $scope.updateDataCustomHandler(modelProp, updateData)
         }

         // Handle save
         parentSaver
            .handler(parentSaver.resourceId, updateData)
            .then(function (response) {
               if (parentSaver.customSuccessHandler) {
                  parentSaver.customSuccessHandler(response, modelProp)
               } else {
                  alerts.successMessage('Update successful')
               }
            })
            .catch(function (response) {
               if (parentSaver.customErrorHandler) {
                  parentSaver.customErrorHandler(response, modelProp)
               } else {
                  alerts.errorMessage('There was a problem, please try again.')
               }
            })
            .finally(function () {
               $scope.modelAsWrite.busy = false
            })
      }
   }
})(angular)
