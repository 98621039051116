;(function (angular, Sentry, Azure) {
   'use strict'
   /**
    * @ngdoc overview
    * @name app
    * @description
    * # app
    *
    * Main module of the application.
    */

   var modules = [
      'app.routes',
      'ngCookies',
      'ngTouch',
      'ngSanitize',
      'ui.router',
      'ui.bootstrap',
      'ui.mask',
      'azureProviders',
      'azure.data',
      'angular-cache',
      'ngPromiseExtras',
      'oc.lazyLoad',
      'angular-page-visibility',
      'azure.util',
      'azure.pubSub',
      'azure.config',
      'azure.barcodeScanner',
      'azure.alerts',
      'azure.filters',
      'azure.exceptionHandler',
      'azure.validation',
   ]

   angular.module('app', modules).config(appConfig).run(appRun)

   function appConfig(
      $compileProvider,
      $locationProvider,
      $logProvider,
      AzureAPIProvider,
      AzureAlgoliaProvider,
      configProvider,
      CacheFactoryProvider
   ) {
      $compileProvider.commentDirectivesEnabled(false)
      $compileProvider.cssClassDirectivesEnabled(false)

      $compileProvider.debugInfoEnabled(false)
      $logProvider.debugEnabled(false)

      $locationProvider.html5Mode(true)

      angular.extend(CacheFactoryProvider.defaults, {
         maxAge: 4 * 60 * 60 * 1000, // 4 hours
         // https://github.com/jmdobry/angular-cache#storeonresolve
         storeOnResolve: true,
         // https://github.com/jmdobry/angular-cache#deleteonexpire
         deleteOnExpire: 'passive',
         storagePrefix: 'ac.',
      })

      AzureAlgoliaProvider.setIdAndKey('J8N8I8KE2Y', '2982ea7ae8fdc7ed1772c496260d35f5')
      AzureAPIProvider.setUrl(configProvider.$get().api)
   }

   // Note: Services injected in the .run block are instantiated as early as possible, before the app is run.
   // For this reason, it is sometimes useful to inject a service in .run for the sole purpose of eager instantiation
   // (i.e. although it may not be used in the .run block, removing the injection would be detrimental).
   function appRun(
      $rootScope,
      $location,
      $anchorScroll,
      $timeout,
      $state,
      $transitions,
      $window,
      $urlService,
      $templateCache,
      $pageVisibility,
      $uibModalStack,
      sessionService,
      alerts,
      appState,
      cookieService,
      scrollPos,
      googleTagManagerService,
      categoryData,
      viewService,
      personData,
      barcodeScanner,
      searchService,
      modalService,
      orderData,
      productService,
      util,
      notificationData,
      paymentMethodData,
      config
   ) {
      // Snapshot the url search parameters when the app loads
      // The router removes any parameters that are not explicitly specified
      // This saves parameters that may have been added for later access (such as the AdWords gclid parameter)
      $window.searchOnLoad = $urlService.search()

      //================================================================================
      // Promo Code via query param
      //================================================================================
      var maybePromoCode = $window.searchOnLoad.promoCode
      if (maybePromoCode) {
         // Note: We're adding the promo code here to local storage for it to later be
         // added to the order via `maybeSyncLocalPromoCodeToOrder`.
         orderData.localAddPromoCode(maybePromoCode)
      }

      //================================================================================
      // Load root categories
      //================================================================================

      categoryData
         .getRootCategories()
         .then(viewService.toCategoryViews)
         .then(function (rootCategoryViews) {
            $rootScope.rootCategories = rootCategoryViews
         })

      //================================================================================
      // Initialize rootScope
      //================================================================================

      $rootScope.head = {}
      $rootScope.$state = $state
      $rootScope.appState = appState
      $rootScope.userState = appState.userState
      $rootScope.temperatureTypes = productService.temperatureTypes
      $rootScope.apiUrl = config.api
      $rootScope.util = util

      // For programatically removing an actively focused element
      //
      // Note: Prefer calling this directly in template markup rather than in JS functions (in order to
      // reduce non data-logic concerns in the functions).
      //
      $rootScope.blur = function () {
         if (document.activeElement && document.activeElement.blur) {
            document.activeElement.blur()
         }
      }

      $rootScope.noop = angular.noop

      // Type to search from anywhere
      $rootScope.onBodyKeydown = function ($event) {
         if (
            !$rootScope.viewportSm &&
            !$event.metaKey &&
            !$event.ctrlKey &&
            $event.target.nodeName !== 'INPUT' &&
            $event.target.nodeName !== 'SELECT' &&
            $event.target.nodeName !== 'TEXTAREA' &&
            $event.target.nodeName !== 'HYVOR-TALK-COMMENTS' &&
            !$event.target.isContentEditable &&
            /^[a-z0-9]$/i.test($event.key) // is alphanumeric
         ) {
            var topModal = $uibModalStack.getTop()
            var searchInput
            // If no modal is open, focus the primary search input
            if (!topModal) {
               searchInput = document.getElementById('js-siteSearchInput')
            }
            // If the cart modal is open, focus its search input
            else if (
               topModal.value &&
               topModal.value.windowTopClass &&
               topModal.value.windowTopClass.includes('js-modalCart')
            ) {
               searchInput = document.getElementById('js-modalCartSearchInput')
            }

            if (searchInput) {
               searchInput.value = ''
               searchInput.focus()
            }
         }
      }

      $rootScope.launchRewardsBreakdownModal = function (orderState) {
         modalService.rewardsBreakdown(orderState).result.catch(angular.noop)
      }

      $rootScope.launchRewardsExplainerModal = function () {
         modalService.rewardsExplainer().result.catch(angular.noop)
      }

      $rootScope.launchSignInModal = function (parentFeaturePath) {
         modalService.signIn(parentFeaturePath).result.catch(angular.noop)
      }

      $rootScope.endSuperUser = function () {
         $state.go(
            'home',
            {
               'superuser-for': undefined,
            },
            {
               reload: true,
            }
         )
      }

      $rootScope.toggleCart = function (parentFeaturePath) {
         if ($rootScope.viewportMd) {
            appState.activeOrderState.modalEditOrder(true, parentFeaturePath)
         } else {
            $rootScope.showSlideoutCart = !$rootScope.showSlideoutCart
         }
      }

      $rootScope.getCurrentPageUrlWithMaybeAffiliateId = function () {
         var toStateName = $state.current.data.shareable ? '.' : 'home'
         var toUrl = util.absoluteUrl($state.href(toStateName))

         if (appState.userState.user && appState.userState.user['is-allowed-to-be-affiliate']) {
            return appState.userState.user.addAffiliateIdToUrl(toUrl)
         } else {
            return toUrl
         }
      }

      $rootScope.launchDropExplainerVideo = function () {
         modalService.video('https://www.youtube.com/embed/_Xne1dDr8Fg').result.catch(angular.noop)
      }

      $rootScope.toggleNavSlideout = function () {
         $rootScope.showNavSlideout = !$rootScope.showNavSlideout
      }

      $rootScope.removeUnderlayClass = function () {
         $rootScope.underlayForElementClass = null
      }

      $rootScope.createImageSrc = function (path, params) {
         if (!path) {
            return
         }

         // Default params, included in every image transformation request.
         var queryString = '?auto=format,compress'

         // Default crop parameters
         if (!params.includes('crop=')) {
            queryString += '&crop=faces,edges'
         }

         if (params) {
            queryString += '&' + params
         }

         return 'https://azurestandard.imgix.net/cms/' + path + queryString
      }

      $rootScope.createImageSrcset = function (path, params) {
         var srcset = [
            $rootScope.createImageSrc(path, params + '&dpr=2') + ' 2x',
            $rootScope.createImageSrc(path, params + '&dpr=3') + ' 3x',
         ]
         return srcset.join()
      }

      $rootScope.maybeSetRatioForImgix = function (maybeRatio) {
         if (maybeRatio) {
            return maybeRatio.replace('/', ':') + '&fit=crop'
         }
      }

      $rootScope.contentMaxWidthAtBreakpointMaxXs = 457
      $rootScope.lightboxImageMaxWidth = 4200
      $rootScope.cmsContentPageContentMaxWidth = 796

      $rootScope.calculateImageMaxPixelWidthFromImageMaxPercentWidth = function (maxPixelWidthAsPercent) {
         // Possible values for `maxPixelWidthAsPercent` are `undefined` or an integer between `1` and `100`

         if (!maxPixelWidthAsPercent) {
            return $rootScope.cmsContentPageContentMaxWidth
         }

         return $rootScope.cmsContentPageContentMaxWidth * (maxPixelWidthAsPercent / 100)
      }

      $rootScope.Math = $window.Math

      $rootScope.ratioPadding = function (ratio) {
         // src: https://github.com/getkirby/kirby/blob/6112f1cb8ebf9c36e8a9f97ae08acc910ddbc792/panel/src/helpers/ratio.js
         var parts = String(ratio).split('/')

         if (parts.length !== 2) {
            return '100%'
         }

         var a = Number(parts[0])
         var b = Number(parts[1])
         var padding = 100

         if (a !== 0 && b !== 0) {
            padding = (100 / a) * b
         }

         return padding + '%'
      }

      $rootScope.openSocialSharingWindow = function (url) {
         var w = 800
         var h = 600
         var x = window.top.outerWidth / 2 + window.top.screenX - w / 2
         var y = window.top.outerHeight / 2 + window.top.screenY - h / 2
         return window.open(
            url,
            '_blank',
            'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
               w +
               ', height=' +
               h +
               ', top=' +
               y +
               ', left=' +
               x
         )
      }

      // TODO: This seems like it belongs as a property on each object returned by the CMS... maybe set this at the Kirby level or the fetch/Algolia level?
      $rootScope.getCmsEntryType = function (slug, parentId) {
         if (!parentId) {
            if (slug === 'core-values') {
               return 'coreValuesIndex'
            } else if (slug === 'careers') {
               return 'careersIndex'
            }
         } else {
            if (parentId === 'blog') {
               return 'blogEntry'
            } else if (parentId === 'recipes') {
               return 'recipeEntry'
            } else if (parentId === 'top-level-pages') {
               return 'topLevelPageEntry'
            } else if (parentId === 'azure-life') {
               return 'azureLifeEntry'
            } else if (parentId === 'categories') {
               return 'categoryPage'
            } else if (parentId.startsWith('categories/')) {
               return 'categoryPage'
            } else if (parentId === 'core-values') {
               return 'cmsCoreValuesEntry'
            } else if (parentId === 'product-insight') {
               return 'productInsight'
            } else if (parentId === 'videos') {
               return 'videoEntry'
            }
         }
      }
      $rootScope.cmsEntryTypeTagMap = {
         blogEntry: {
            label: 'Blog',
            classes: 'bg-blue-600 text-blue-50',
         },
         recipeEntry: {
            label: 'Recipe',
            // classes: 'bg-brandGreen50 text-white',
            classes: 'bg-lime-600 text-lime-50',
         },
         topLevelPageEntry: {
            label: 'Other',
            classes: 'bg-gray-600 text-gray-50',
         },
         categoryPage: {
            label: 'Category',
            classes: 'bg-gray-600 text-gray-50',
         },
         coreValuesEntry: {
            label: 'Core Value',
            classes: 'bg-gray-600 text-gray-50',
         },
         coreValuesIndex: {
            label: 'Core Values',
            classes: 'bg-gray-600 text-gray-50',
         },
         careersIndex: {
            label: 'Careers',
            classes: 'bg-gray-600 text-gray-50',
         },
         videoEntry: {
            label: 'Video',
            classes: 'bg-red-600 text-red-50',
         },
      }

      $rootScope.createCmsEntryHref = function (parentId, stateParams) {
         var targetStateName

         if (!parentId) {
            if (stateParams.slug === 'core-values') {
               targetStateName = 'cmsCoreValuesIndex'
            } else if (stateParams.slug === 'careers') {
               targetStateName = 'cmsCareersIndex'
            }
         } else {
            if (parentId === 'blog') {
               targetStateName = 'cmsBlogEntry'
            } else if (parentId === 'recipes') {
               targetStateName = 'cmsRecipesEntry'
            } else if (parentId === 'top-level-pages') {
               targetStateName = 'cmsTopLevelPage'
            } else if (parentId === 'azure-life') {
               targetStateName = 'cmsAzureLifeEntry'
            } else if (parentId === 'categories') {
               targetStateName = 'cmsCategoriesEntry'
            } else if (parentId.startsWith('categories/')) {
               targetStateName = 'cmsSubcategoriesEntry'
            } else if (parentId === 'core-values') {
               targetStateName = 'cmsCoreValuesEntry'
            } else if (parentId === 'product-insight') {
               targetStateName = 'cmsProductInsight'
            } else if (parentId === 'authors') {
               targetStateName = 'cmsAuthorsEntry'
            } else if (parentId === 'careers') {
               targetStateName = 'cmsCareersEntry'
            } else if (parentId === 'videos') {
               targetStateName = 'cmsVideosEntry'
            }
         }

         if (targetStateName) {
            return $state.href(targetStateName, stateParams)
         }
      }

      $rootScope.onCmsPageInit = function (pageData) {
         //
         // Set/Update CMS preview state
         //

         $rootScope.internalCmsState = {
            currentPagePanelPath: pageData.panelPagePath,
            isServingPreviewData: pageData._isInternalPreviewDraftData,
         }

         // Previews for top-level pages are stored in their own `*-index/` directory due to a limitation of KQL.
         // However, we still want to link to the non preview entry in the CMS.
         if ($rootScope.internalCmsState.currentPagePanelPath === 'pages/home-index') {
            $rootScope.internalCmsState.currentPagePanelPath = 'pages/home'
         }

         //
         // Handle page metadata
         //

         var metadata = {
            title: pageData.metaTitle || pageData.title,
            meta: {
               description: pageData.metaDescription,
            },
         }

         if (pageData.primaryImage) {
            var imageExtension = pageData.primaryImage.filename.substring(
               pageData.primaryImage.filename.lastIndexOf('.') + 1
            )
            metadata.primaryImageType = 'image/' + imageExtension
            metadata.primaryImageAlt = pageData.primaryImage.alt
            metadata.primaryImageSrc = $rootScope.createImageSrc(pageData.primaryImage.filename, 'w=1200&h=630')
         }

         $rootScope.pugPageMetadata = metadata
      }

      $rootScope.constrainedMaxContainerWidth = 1192

      $rootScope.cmsLayoutPageCreateColumnStyles = function (column, containerMaxWidth) {
         containerMaxWidth = containerMaxWidth || $rootScope.constrainedMaxContainerWidth

         if (column.width === '1/1') {
            return {flex: '100%'}
         } else if (column.width === '1/4') {
            return {flex: '20%', 'min-width': '225px'}
         } else if (column.width === '3/4') {
            return {flex: '1 1 ' + parseInt(containerMaxWidth * 0.75 - 100) + 'px'}
         } else if (column.width === '1/2') {
            return {flex: '40%', 'min-width': '300px'}
         } else if (column.width === '1/3') {
            return {flex: '30% ', 'min-width': '300px'}
         }
      }

      $rootScope.cmsColumnCalculateMaxWidth = function (containerMaxWidth, columnWidth, blockMaxWidthPercent) {
         blockMaxWidthPercent = blockMaxWidthPercent || 100

         if ($rootScope.viewportXs) {
            return $rootScope.contentMaxWidthAtBreakpointMaxXs
         } else {
            return Math.ceil(
               containerMaxWidth * util.columnWidthStringRatioToFloat(columnWidth) * (blockMaxWidthPercent / 100)
            )
         }
      }

      $rootScope.convertContainerColumnPercentMaxWidthToPixel = function (columnWidth, containerColumnPercentMaxWidth) {
         // Note: This currently only supports the `constrainedMaxContainerWidth`.

         if (!columnWidth) {
            return
         }

         // Consideration: Move this function to the utils file if it's ever needed elsewhere.
         function columnWidthStringRatioToFloat(columnWidth) {
            var parts = columnWidth.split('/')
            return parseInt(parts[0]) / parseInt(parts[1])
         }

         var columnWidthAsFloat = columnWidthStringRatioToFloat(columnWidth)

         if (containerColumnPercentMaxWidth) {
            return $rootScope.constrainedMaxContainerWidth * columnWidthAsFloat * (containerColumnPercentMaxWidth / 100)
         } else {
            return $rootScope.constrainedMaxContainerWidth * columnWidthAsFloat
         }
      }

      $rootScope.getIdFromYoutubeVideoUrl = function (url) {
         // adapted from: https://stackoverflow.com/a/71010058/1971662
         var regex = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm
         var parts = regex.exec(url)
         if (parts) {
            return parts[3]
         }
      }

      $rootScope.paymentMethodTypes = paymentMethodData.types

      //================================================================================
      // Camera scanner
      //================================================================================

      // Check for browser/device camera support
      if (navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function') {
         $rootScope.cameraSupported = true
      }

      $rootScope.launchCameraScanner = function () {
         modalService.cameraScanner().result.catch(angular.noop)
      }

      //================================================================================
      // Set mobile based on screen size
      //================================================================================

      $rootScope.viewportXs = Azure.viewportXsMediaQuery.matches
      $rootScope.viewportSm = Azure.viewportSmMediaQuery.matches
      $rootScope.viewportMd = Azure.viewportMdMediaQuery.matches

      //================================================================================
      // Cache header template
      //================================================================================

      util.resolveXhr(Azure.headerRequest).then(function (responseText) {
         $templateCache.put(Azure.headerPath, responseText)
      })

      //================================================================================
      // Global transition hooks
      //================================================================================

      // Uncomment to debug transitions (remember to inject `$trace` above)
      //$trace.enable('TRANSITION');

      function stateTargetWithoutParam(state, params, withoutParam, options) {
         var withoutParams = {}
         withoutParams[withoutParam] = undefined
         return $state.target(state, angular.extend({}, params, withoutParams), options)
      }

      $transitions.onBefore(
         {},
         function () {
            $rootScope.appLoading = true
         },
         {
            priority: 1,
         }
      )

      $transitions.onStart(
         {},
         function (transition) {
            var userState = appState.userState
            var toState = transition.to()
            var toParams = transition.params()
            var options = transition.options()

            if (toParams.promoCode) {
               return stateTargetWithoutParam(toState, toParams, 'promoCode', options)
            }

            if (toState.name === 'signOut') {
               // Clear all alerts (even persistent ones) when a user signs out
               alerts.clear(true)
               return sessionService.logout({featurePath: toParams.featurePath})
            }

            var oldSuperUserFor = userState.superUserFor

            // Check for valid superuser-for parameter
            var newSuperUserFor = Number(toParams['superuser-for'])
            if (!newSuperUserFor) {
               // superuser-for parameter is not present or invalid

               // if currently in superuser, end superuser
               if (oldSuperUserFor) {
                  sessionService.endSuperUser()
               }

               // If superuser-for parameter is present, remove it because it is invalid
               if (toParams['superuser-for'] !== undefined) {
                  return stateTargetWithoutParam(toState, toParams, 'superuser-for', options)
               }
            }

            return sessionService.getSession().then(function (session) {
               var userId = session.person
               return personData.getPersonIsGuest(userId).then(function (isGuest) {
                  // organicOnly query parameter turns on the preference but does
                  // not remain in the URL

                  var isNewUser = false
                  if ($window.searchOnLoad['token-id'] && $window.searchOnLoad['is-new-user']) {
                     isNewUser = true
                  }

                  if (toParams.organicOnly === 'true') {
                     personData.updatePreference(userId, personData.preferences.organicOnly, true)
                     return stateTargetWithoutParam(toState, toParams, 'organicOnly', options)
                  }

                  if (toState.authenticate || newSuperUserFor) {
                     // Authentication is required
                     if (!userId || (isGuest && !toState.name.startsWith('checkout'))) {
                        // User is not authenticated; redirect to sign in page
                        $rootScope.returnToState = {
                           state: toState,
                           params: toParams,
                        }
                        return stateTargetWithoutParam('signIn', toParams, 'superuser-for', options)
                     }

                     // User is authenticated
                     // If the superuser-for parameter is new, start the new SuperUser session.
                     if (newSuperUserFor && oldSuperUserFor !== newSuperUserFor) {
                        return sessionService
                           .startSuperUserFor(userId, newSuperUserFor)
                           .catch(function (error) {
                              alerts.error({
                                 message: error.message,
                                 queue: true,
                              })
                              return stateTargetWithoutParam(toState, toParams, 'superuser-for', options)
                           })
                           .finally(function () {
                              userState.init(userId, isNewUser)
                           })
                     }
                  }

                  userState.init(userId, isNewUser)
               })
            })
         },
         {
            priority: 1,
         }
      )

      $transitions.onStart({}, function (transition) {
         var fromState = transition.from()
         var fromParams = transition.params('from')
         var toParams = transition.params()

         // Clear any template metadata set from the previous route.
         $rootScope.pugPageMetadata = undefined

         // Clear the internal CMS state from the previous route.
         $rootScope.internalCmsState = {}

         // Clear alerts and add queued alerts
         alerts.clear()
         alerts.addQueued()

         // The cart modal must be closed rather than dismissed because
         // dismissing it invokes the browser history's back function (see
         // modalService.cart).
         modalService.closeCart()

         // Dismiss all modals when a transition starts. We don't have any cases
         // where a state transition can occur while a modal remains open.
         $uibModalStack.dismissAll()

         // Open the cart modal if the cart query parameter is set
         if (toParams.cart) {
            var featurePath = fromState.abstract ? 'appLoad' : toParams.featurePath
            modalService.cart(featurePath)
         }

         var href = $state.href(fromState, fromParams)
         if (href) {
            // Save the scroll position for the state we transitioned from
            scrollPos.saveScrollPos($state.href(fromState, fromParams))
         }

         if (!transition.to().data.shopListing && $rootScope.showSlideoutCart) {
            $rootScope.showSlideoutCart = false
         }
         var locationSearch = $location.search()
         /* jshint -W106 */
         var affiliateId = locationSearch.a_aid
         if (affiliateId) {
            cookieService.set('PAPAffiliateId', affiliateId)
            var bannerId = locationSearch.a_bid
            if (bannerId) {
               cookieService.set('PAPBannerId', bannerId)
            }
         }
         /* jshint +W106 */
      })

      $transitions.onError({}, function (transition) {
         var error = transition.error()

         // If the error status is 403 or 404, send to the 404 page.
         // When requesting a resource from the API results in a 403 response, it means the user is forbidden
         // access to that resource. In such cases it is better for the user to see the Not Found page than a
         // generic error page.
         var errorStatus = error.detail && error.detail.status
         if (errorStatus === 403 || errorStatus === 404) {
            $state.go('404', {}, {location: false})
         } else if (error.type === 6) {
            // If the error type is fatal in nature ("errored"), go to the generic error page.
            // Note:
            // Checking the transition.error().type for `6` (message: The transition errored) seems to be
            // the cleanest way to check if a transition error was fatal, though there may be a better approach.
            $state.go('error', {status: errorStatus || 500}, {location: false})
         }

         $rootScope.appLoading = false
      })

      $transitions.onFinish({}, function () {
         $rootScope.appLoading = false
      })

      $transitions.onSuccess({}, function (transition) {
         var fromState = transition.from()
         var fromParams = transition.params('from')
         var toState = transition.to()
         var toParams = transition.params()
         var toStateName = toState.name

         var hashParam = toParams['#']

         // Blur any actively focused element
         $rootScope.blur()

         // Set page name
         var page = toStateName.replace(/\./g, '-')
         $rootScope.pageName = 'page-' + page

         function maybeScrollToTop() {
            function scrollToTop() {
               $window.scrollTo(0, 0)
            }
            // Don't scroll to top when only opening or closing the cart
            if (fromState.name !== toStateName || fromParams.cart === toParams.cart) {
               // Maybe don't scroll to top on specific state changes.
               if (
                  toStateName.startsWith('dc.') ||
                  toStateName === 'account.orders' ||
                  toStateName === 'account.order'
               ) {
                  // Scroll to top if the state change is something other than a query param change
                  // or if toggling between different drops (in the case of a DC managing multiple drops)
                  if (
                     fromState.name !== toStateName ||
                     fromParams.dropId !== toParams.dropId ||
                     fromParams.id !== toParams.id
                  ) {
                     scrollToTop()
                  }
               }
               // Do not scroll to top on in-page navigation events (i.e. billing history)
               else if (
                  (fromState.name === 'account.billing' && toStateName === 'account.billing') ||
                  (fromState.name === 'account.azureCash' && toStateName === 'account.azureCash')
               ) {
                  return
               } else {
                  scrollToTop()
               }
            }
         }

         maybeScrollToTop()

         // After a successful state transition, if we're not on 404
         // - Redirect if necessary
         // - Set the canonical url
         if (toStateName !== '404') {
            // The default canonical url is the current href without query string parameters or hash
            var path = $state.href(toState, toParams).split(/[?#]/)[0]
            var canonicalUrl = util.absoluteUrl(path)

            if (toState.data.prerenderRedirectToCanonical || (hashParam && hashParam.startsWith('!'))) {
               toState.data.head.meta['prerender-status-code'] = '301'
               toState.data.head.meta['prerender-header'] = 'Location: ' + canonicalUrl
            }

            $rootScope.canonicalUrl = canonicalUrl
         }

         // This timeout is needed in order for the browser's location to be updated before updating the title
         // and firing the Google Analytics pageView. This ensures the browser's history entries have the proper
         // title and the 'Page URL' GTM variable is properly set when the pageView event is fired.
         $timeout(function () {
            // Handle setting the page metadata
            //
            // This prefers any page metadata passed from the Pug template with a fallback to `toState.data.head`.
            // `toState.data.head` is inherited from ancestor states (with the root state defining the default head).
            //
            // Note: We use a variable here (`metadataForRootScope`) instead of directly assigning to `toState.data.head`
            // in order to prevent caching bugs.

            var metadataForRootScope
            if ($rootScope.pugPageMetadata) {
               metadataForRootScope = angular.merge({}, toState.data.head, $rootScope.pugPageMetadata)
            } else {
               metadataForRootScope = toState.data.head
            }

            // Copy the metadata to `$rootScope.head` for use in the templates.
            angular.copy(metadataForRootScope, $rootScope.head)

            document.title = metadataForRootScope.title

            // Google Analytics pageView
            if (toStateName === 'shop.product') {
               var product = transition.injector().get('product')
               googleTagManagerService.productDetailPageviewEvent(product, product.selectedPackaging)
            } else if (toState.data.checkoutStep && appState.activeOrderState) {
               var option
               if (toStateName === 'checkout.shipping' || toStateName === 'checkout.review') {
                  option = appState.activeOrderState.order.shippingTypeString
               }
               googleTagManagerService.checkoutStepPageviewEvent(
                  appState.activeOrderState.order.lineViews,
                  toState.data.checkoutStep,
                  option
               )
            }

            if (hashParam) {
               // Note: This is to account for the site's sticky header.
               $anchorScroll.yOffset = 64

               $anchorScroll()
            }
         })
      })

      //================================================================================
      // Window/document event handling
      //================================================================================

      // The use case of resizing from mobile to non-mobile or vice versa is assumed to be rare
      // If any of the viewport{size} states change we simply reload the current state
      // This avoids the need to keep watchers on the viewport{size} in the views.
      Azure.viewportSmMediaQuery.addListener(function () {
         $rootScope.viewportSm = Azure.viewportSmMediaQuery.matches
         $state.reload()
      })
      Azure.viewportMdMediaQuery.addListener(function () {
         $rootScope.viewportMd = Azure.viewportMdMediaQuery.matches
         $state.reload()
      })

      // Restore scroll position when browser history is used for navigation
      $window.history.scrollRestoration = 'manual'
      $window.addEventListener('popstate', function () {
         if ($state.transition) {
            $state.transition.onSuccess({}, function (transition) {
               scrollPos.restoreScrollPos($state.href(transition.to(), transition.params()))
            })
         } else if (!$state.params.cart) {
            scrollPos.restoreScrollPos($state.href($state.current, $state.params))
         }
      })

      $pageVisibility.$on('pageFocused', function () {
         var userId = appState.userState.superUserId || appState.userState.id
         sessionService.autoLoginOrLogout({featurePath: 'pageFocused'}).then(function (session) {
            if (session && session.person === userId) {
               // If the same person is still logged in, refresh their active order,
               // ensuring that it is shoppable
               appState.activateDefaultOrder()
            } else {
               $state.reload()
            }
         })
         modalService.checkForNewVersion().catch(angular.noop)
      })

      $pageVisibility.$on('pageBlurred', function () {
         // By clearing cached order data when the page is blurred, we free up memory
         // and ensure that fresh order data is fetched when the page is focused again.
         orderData.clear()
      })

      //================================================================================
      // Subscriptions to updates
      //================================================================================

      function loggedInOrRegistered(event, args) {
         // Prevent reloading under these conditions
         if (args.featurePath === 'add') {
            return
         }

         if ($rootScope.returnToState) {
            if ($rootScope.returnToState.params && $rootScope.returnToState.params['superuser-for']) {
               $window.history.replaceState(
                  {},
                  '',
                  $state.href($rootScope.returnToState.state, $rootScope.returnToState.params)
               )
            } else {
               $state.go($rootScope.returnToState.state, $rootScope.returnToState.params, {
                  location: 'replace',
                  reload: true,
               })
            }
            $rootScope.returnToState = null
         } else {
            $state.reload()
         }
      }

      function barcodeScanned(event, args) {
         searchService.shopSearch(args.scannedCode)
      }

      function setSentryUser() {
         if (Sentry) {
            Sentry.configureScope(function (scope) {
               scope.setUser(appState.userState)
            })
         }
      }

      function userChanged() {
         setSentryUser()
         appState.userState.promise.then(function () {
            setSentryUser()
         })

         // Get user notifications
         if (appState.userState.id) {
            notificationData.getNotifications(appState.userState.id).then(function (notifications) {
               $rootScope.notifications = notifications
            })
         } else {
            $rootScope.notifications = []
         }
      }

      sessionService.subscribe([sessionService.events.loggedIn, sessionService.events.registered], loggedInOrRegistered)
      barcodeScanner.subscribe(barcodeScanner.events.barcodeScanned, barcodeScanned)
      appState.subscribe(appState.events.userChanged, userChanged)
   }
})(angular, window.Sentry, window.Azure)
