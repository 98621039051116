;(function (angular) {
   'use strict'

   angular.module('app').directive('asNotifications', notifications)

   function notifications() {
      return {
         templateUrl: 'partials/notifications.htm',
         controller: notificationsController,
      }
   }

   function notificationsController($scope, appState, notificationData) {
      $scope.dismissNotification = function (notificationId) {
         notificationData.dismiss(appState.userState.id, notificationId)
      }
   }
})(angular)
