/* jshint -W106 */

;(function (angular) {
   'use strict'

   angular.module('app').directive('asBlockImage', blockImage)

   function blockImage() {
      return {
         templateUrl: 'partials/block_image.htm',
         restrict: 'A',
         controller: blockImageController,
         scope: {
            block: '=',
            columnWidth: '=',
            contentOrSlide: '=',
            imageMap: '=',
            maxWidth: '=',
            isRounded: '=?',
            isInSlider: '=?',
            isHeightFull: '=?',
            shouldLazyload: '=?',
            forceCover: '=?',
         },
      }
   }

   function blockImageController($scope, $rootScope, util) {
      $scope.containerMaxWidth = $scope.containerMaxWidth || $rootScope.constrainedMaxContainerWidth

      $scope.imageUuid = $scope.isInSlider
         ? $scope.contentOrSlide.image_cms[0].replace('images/', '')
         : $scope.block.content.image_cms[0].replace('images/', '')

      if ($scope.maxWidth) {
         $scope.imageWidthString = $scope.maxWidth
      } else {
         $scope.imageWidthString = $rootScope.cmsColumnCalculateMaxWidth(
            $scope.containerMaxWidth,
            $scope.columnWidth,
            $scope.block.content.max_width
         )
      }

      $scope.textShadow = function () {
         if (!util.isStringTrue($scope.contentOrSlide.overlay_text_shadow_toggle)) {
            return
         }

         if ($scope.contentOrSlide.overlay_text_shadow === 'white') {
            return 'rgba(255, 255, 255, 0.75) 0px 0px 13.5px'
         }

         if ($scope.contentOrSlide.overlay_text_shadow === 'black') {
            return 'rgba(0, 0, 0, 0.75) 0px 0px 13.5px'
         }
      }
   }
})(angular)
