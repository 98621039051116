;(function (angular) {
   'use strict'

   angular.module('app').directive('paymentMethodInfo', paymentMethodInfo)

   function paymentMethodInfo() {
      return {
         templateUrl: 'partials/payment_method_info.htm',
         scope: {
            pm: '=',
         },
      }
   }
})(angular)
