;(function (angular) {
   'use strict'

   /**
    * @ngdoc directive
    * @name app.directive:asProductImpression
    * @description
    * # asProductImpression
    * Measures a product impression.
    */

   angular.module('app').directive('asProductImpression', productImpression)

   // Create an IntersectionObserver to observe elements that enter the viewport 95%
   // This will be used to log product impressions
   var _productImpressionThreshold = 0.95
   var _productImpressionObserver
   var _scopeKey
   if (window.IntersectionObserver) {
      _scopeKey = 'productImpressionScope'
      _productImpressionObserver = new window.IntersectionObserver(intersectionObserverCallback, {
         threshold: _productImpressionThreshold,
      })
   }

   function productImpression() {
      var obj = {}
      if (_productImpressionObserver) {
         obj.controller = productImpressionController
      }
      return obj
   }

   /* @ngInject */
   function productImpressionController($scope, $q, $attrs, $element, viewService, googleTagManagerService, appState) {
      $scope.userId = appState.userState.id
      $scope.homePageVersion = appState.userState.homePageVersion
      $scope.guest = appState.userState.isGuest
      $scope.priceLevel = appState.userState.priceSettings.priceLevel

      var _productClickEventFired = false

      // If the current scope doesn't have a product, try getting a product by productId
      // The productId can either be in the as-product-impression attribute or at $scope.productId
      if (!$scope.product) {
         var productId = Number($attrs.asProductImpression || $scope.productId)
         if (!productId) {
            return
         }
         $scope.maybeProductPromise = viewService.getProductView(productId).then(function (productView) {
            $scope.product = productView
         })
      }

      // If the current scope doesn't have a productList, try getting it from the product-list attribute.
      if (!$scope.productList) {
         $scope.productList = $attrs.productList
      }

      // If the current scope doesn't have a position, try getting it from the position attribute.
      if (!$scope.position) {
         $scope.position = Number($attrs.position)
      }

      $scope.productClick = function () {
         if ($scope.product && !_productClickEventFired) {
            _productClickEventFired = true
            googleTagManagerService.productClickEvent(
               $scope.product,
               $scope.productList,
               $scope.position,
               $scope.featurePath
            )
         }
      }

      function attachImpressionObserver() {
         $q.resolve($scope.product || $scope.maybeProductPromise).then(function () {
            if (!$scope.product) {
               return
            }

            var target = $element[0]
            $element.data(_scopeKey, $scope)
            _productImpressionObserver.observe(target)
            $scope.$on('$destroy', function () {
               _productImpressionObserver.unobserve(target)
            })
         })
      }

      if ($scope.inFlickity) {
         $scope.$on('flickityLoaded', function () {
            attachImpressionObserver()
         })
      } else {
         attachImpressionObserver()
      }
   }

   function intersectionObserverCallback(entries) {
      var googleTagManagerService
      var featurePath

      var entriesInFullView = entries.filter(function (entry) {
         return entry.intersectionRatio > _productImpressionThreshold
      })

      if (!entriesInFullView.length) {
         return
      }

      var impressionFieldObjects = entriesInFullView.map(function (change) {
         var $element = angular.element(change.target)
         var scope = $element.data(_scopeKey)
         if (!scope) {
            return
         }

         // Unobserve this element to ensure the product impression is only logged once
         _productImpressionObserver.unobserve(change.target)
         if (!googleTagManagerService) {
            googleTagManagerService = $element.injector().get('googleTagManagerService')
         }
         return googleTagManagerService.createProductObject(
            scope.product,
            undefined,
            undefined,
            scope.productList,
            Number(scope.position)
         )
      })

      if (googleTagManagerService) {
         googleTagManagerService.productImpressionEvent(impressionFieldObjects, featurePath)
      }
   }
})(angular)
