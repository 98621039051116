;(function (angular) {
   'use strict'

   angular.module('app').directive('asHyvorTalkView', hyvorTalkView)

   function hyvorTalkView($rootScope, $state, $http, sessionService) {
      return {
         restrict: 'A',
         link: hyvorTalkViewLink,
      }

      function hyvorTalkViewLink(scope, element) {
         sessionService.getSession().then(function (session) {
            //================================================================================
            // Initialization
            //================================================================================

            element.append('<script async type="module" src="https://talk.hyvor.com/embed/embed.js"></script>')

            var commentsElement = document.createElement('hyvor-talk-comments')

            commentsElement.setAttribute('website-id', 7892)
            commentsElement.setAttribute('page-id', scope.pageData.uuid)

            var base64EncodedEmptyObject = 'e30='
            if (session.hyvorSso.userData !== base64EncodedEmptyObject) {
               commentsElement.setAttribute('sso-user', session.hyvorSso.userData)
               commentsElement.setAttribute('sso-hash', session.hyvorSso.hash)
            }

            element.append(commentsElement)

            //================================================================================
            // Events (docs https://talk.hyvor.com/docs/events)
            //================================================================================

            var _existingRatingsCount
            var _existingRatingsAverage

            commentsElement.addEventListener('loaded', function () {
               var ratingsData = commentsElement.api.page().ratings
               _existingRatingsCount = ratingsData.count
               _existingRatingsAverage = ratingsData.average
            })

            commentsElement.addEventListener('auth:login:clicked', $rootScope.launchSignInModal)

            commentsElement.addEventListener('rating', function (event) {
               // Exit if there's no rating change or the current page is not whitelisted
               if (
                  (event.detail.count === _existingRatingsCount && event.detail.average === _existingRatingsAverage) ||
                  !['cmsRecipesEntry', 'cmsBlogEntry'].includes($state.current.name)
               ) {
                  return
               }

               _existingRatingsCount = event.detail.count
               _existingRatingsAverage = event.detail.average

               return $http({
                  method: 'POST',
                  url: window.Azure.serverlessEndpointsApiUrl + '/update-comment-and-rating-algolia-data',
                  headers: {
                     'Content-Type': 'text/plain',
                  },
                  data: JSON.stringify({
                     objectID: scope.pageData.uuid,
                  }),
               })
            })
         })
      }
   }
})(angular)
