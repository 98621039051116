;(function (angular, Azure, undefined) {
   'use strict'

   angular.module('app').config(uibModalProviderConfig).factory('modalService', modalService)

   function uibModalProviderConfig($uibModalProvider) {
      // UIB Modal defaults (can be overridden on a per-instance basis)
      $uibModalProvider.options = {
         windowClass: 'Modal-overlay',
         size: 'lg',
         backdrop: 'static', // do not allow closing by clicking off the modal
         keyboard: false, // do not allow closing via the escape key
         animation: false,
      }
   }

   function modalService($state, $uibModal, $uibModalStack, $http, $ocLazyLoad, addressService) {
      // Note: The default size is "large" so do not specify "large" or "lg"

      var _cartModalInstance

      var resolveFiles = function (files) {
         // `files` can be either a string or an array of strings
         return function () {
            return $ocLazyLoad.load(files)
         }
      }

      //================================================================================
      // Modals - Generic
      //================================================================================

      function prompt(params) {
         return $uibModal.open({
            size: 'sm',
            templateUrl: 'partials/modal.prompt.htm',
            controller: function ($scope) {
               $scope.heading = params.heading
               $scope.message = params.message
               $scope.maybeCancelBtnText = params.maybeCancelBtnText
               $scope.maybeConfirmBtnText = params.maybeConfirmBtnText
            },
            keyboard: params.hasOwnProperty('allowEscapeKeyClose') ? params.allowEscapeKeyClose : true,
         })
      }

      function alert(params) {
         return $uibModal.open({
            size: 'sm',
            templateUrl: 'partials/modal.alert.htm',
            controller: function ($scope) {
               $scope.heading = params.heading
               $scope.message = params.message
               $scope.maybeCloseBtnText = params.maybeCancelBtnText
            },
            keyboard: params.hasOwnProperty('allowEscapeKeyClose') ? params.allowEscapeKeyClose : true,
         })
      }

      function textarea(params) {
         return $uibModal.open({
            /* @ngInject */
            controller: function ($scope) {
               $scope.heading = params.heading
               $scope.placeholder = params.placeholder
               $scope.maybeText = params.maybeText
               $scope.maxLength = params.maxLength
            },
            size: 'md',
            templateUrl: 'partials/modal.textarea.htm',
            windowClass: 'Modal-overlay Modal-overlay--allowSibling', // allow sibling
         })
      }

      /**
       * Modal: Text Input
       * @param {Object} params Parameters for the the `textInput` modal.
       * @param {string} params.heading The modal's heading.
       * @param {string} [params.initialValue] The initial value for the single input.
       * @param {boolean} [params.isRequired] Prevent submitting the modal with an empty input? Defaults to `false`.
       */
      function textInput(params) {
         return $uibModal.open({
            /* @ngInject */
            controller: function ($scope) {
               $scope.heading = params.heading
               $scope.textInput = params.initialValue
               $scope.isRequired = params.isRequired // Note that this effectively defaults to `false`
            },
            size: 'sm',
            templateUrl: 'partials/modal.text_input.htm',
         })
      }

      function video(videoUrl) {
         return $uibModal.open({
            templateUrl: 'partials/modal.video.htm',
            windowTopClass: 'Modal--cursorZoomOut',
            backdrop: true, // allow off-click close
            keyboard: true, // allow escape-key close
            /* @ngInject */
            controller: function ($sce, $scope) {
               $scope.videoUrl = $sce.trustAsResourceUrl(videoUrl)
            },
         })
      }

      //================================================================================
      // Modals - Specific
      //================================================================================

      function addSmsUi(primaryAddress) {
         return $uibModal.open({
            size: 'sm',
            keyboard: true, // allow escape-key close
            templateUrl: 'partials/modal.add_sms.htm',
            controller: 'AddSmsUiController',
            resolve: {
               ctrl: resolveFiles('app.common/add_sms_ui.controller.js'),
               primaryAddress: function () {
                  return primaryAddress
               },
            },
         })
      }

      function addPaymentMethod(paymentMethodType) {
         return $uibModal.open({
            size: 'sm',
            templateUrl: 'partials/modal.payment_method_form.htm',
            /* @ngInject */
            controller: function ($scope, paymentMethodData) {
               $scope.isMethodTypeCard = paymentMethodType === paymentMethodData.types.creditCard
               $scope.isMethodTypeAch = paymentMethodType === paymentMethodData.types.ach
            },
         })
      }

      function addressBook(existingSelectedAddress) {
         return $uibModal.open({
            controller: function ($scope) {
               $scope.isAddressSelected = function (address) {
                  return addressService.isSameAddress(address, existingSelectedAddress)
               }
            },
            size: 'lg',
            keyboard: true, // allow escape-key close
            templateUrl: 'partials/modal.address_select.htm',
         })
      }

      function addressForm(address, isPrimary, noPhone) {
         return $uibModal.open({
            controller: function ($scope) {
               $scope.address = angular.copy(address || {})
               $scope.isPrimary = isPrimary
               $scope.includePhone = !noPhone
            },
            size: '700px',
            templateUrl: 'partials/modal.address_form.htm',
         })
      }

      function cameraScanner() {
         return $uibModal.open({
            windowTopClass: 'js-modalCameraScanner',
            templateUrl: 'partials/modal.camera_scanner.htm',
            controller: 'CameraScannerController',
            resolve: {
               ctrl: resolveFiles(['app.common/camera_scanner.controller.js', '/lazy-dependencies/zxing.0.21.1.js']),
            },
         })
      }

      function dropToHomeCompleteDelivery(orderId) {
         return $uibModal.open({
            templateUrl: 'app.dd/modal.dd_complete_delivery.htm',
            controller: 'DdCompleteDeliveryController',
            resolve: {
               ctrl: resolveFiles('app.dd/modal.dd_complete_delivery.controller.js'),
               orderId: function () {
                  return orderId
               },
            },
         })
      }

      function cart(parentFeaturePath) {
         closeCart()

         _cartModalInstance = $uibModal.open({
            windowClass: 'Modal-overlay Modal-overlay--allowSibling',
            size: 'cart',
            templateUrl: 'partials/modal.cart.htm',
            // Note: openedClass was being used here but was causing a bug that on close of
            // any modals called from within this modal all modals would close. Using
            // windowTopClass is the approach to use for adding modifier classes to the modal.
            windowTopClass: 'Modal--cursorZoomOut js-modalCart',
            backdrop: true, // allow off-click close
            keyboard: true, // allow escape-key close
            controller: function ($scope) {
               $scope.featurePath = parentFeaturePath + ':cartModal'
            },
         })

         _cartModalInstance.result.catch(function () {
            _cartModalInstance = undefined
            if (parentFeaturePath === 'appLoad') {
               $state.go('.', {cart: false})
            } else {
               history.back()
            }
         })

         return _cartModalInstance
      }

      function closeCart() {
         $uibModalStack.close(_cartModalInstance)
         _cartModalInstance = undefined
      }

      function checkForNewVersion(reloadHref) {
         return $http.get('v.txt').then(function (response) {
            if (response.data !== Azure.version) {
               return $uibModal.open({
                  controller: function ($scope, $window) {
                     $scope.refresh = function () {
                        if (reloadHref) {
                           $window.location.href = reloadHref
                        } else {
                           $window.location.reload(true)
                        }
                     }
                  },
                  size: 'sm',
                  templateUrl: 'inlineTemplates/newVersionModal.htm',
               }).result
            }
         })
      }

      function confirmDelete(typeToDelete) {
         return $uibModal.open({
            controller: function ($scope) {
               $scope.type = typeToDelete
            },
            size: 'md',
            keyboard: true, // allow escape-key close
            templateUrl: 'partials/modal.confirm_delete.htm',
         })
      }

      function confirmJoiningClosedDrop() {
         return prompt({
            heading: 'Please confirm',
            message: 'This is a closed (private) drop. Are you sure you still want to join it?',
            maybeConfirmBtnText: 'Yes, join',
         }).result
      }

      function dcGuardEmailListCopy(emailList) {
         return $uibModal.open({
            size: 'sm',
            templateUrl: 'app.dc/modal.dc_guard_email_list_copy.htm',
            controller: function ($scope) {
               $scope.emailList = emailList
            },
         })
      }

      function insufficientStockUi() {
         return $uibModal.open({
            keyboard: true, // allow escape-key close
            templateUrl: 'partials/modal.insufficientStockUi.htm',
            controller: 'InsufficientStockUiCtrl',
            resolve: {
               ctrl: resolveFiles('app.common/insufficient_stock_ui.controller.js'),
            },
         })
      }

      function itemPriceChangesPrompt(orderLines) {
         return $uibModal.open({
            keyboard: true, // allow escape-key close
            size: 'md',
            templateUrl: 'partials/modal.item_price_changes_prompt.htm',
            controller: function ($scope, appState, orderService) {
               $scope.orderLineChanges = orderLines.map(function (orderLineChange) {
                  return angular.extend({}, orderLineChange, {
                     currentOrderLine: appState.activeOrderState.order.lineViews.find(function (orderline) {
                        return orderline.id === orderLineChange.id
                     }),
                  })
               })

               var updatedOrderlines = angular
                  .copy(appState.activeOrderState.order.lineViews)
                  .map(function (existingOrderLineView) {
                     var maybeOrderLineChange = orderLines.find(function (changedOrderLine) {
                        return changedOrderLine.id === existingOrderLineView.id
                     })
                     if (maybeOrderLineChange) {
                        existingOrderLineView.price = maybeOrderLineChange.price
                     }
                     return existingOrderLineView
                  })
               $scope.updatedTotals = orderService.calculateTotals(appState.activeOrderState.order, updatedOrderlines)
            },
         })
      }

      function makePayment(balance) {
         return $uibModal.open({
            controller: 'MakePaymentCtrl',
            resolve: {
               ctrl: resolveFiles('app.common/make_payment.controller.js'),
               balance: function () {
                  return balance
               },
            },
            size: 'md',
            templateUrl: 'app.account/modal.make_payment.htm',
         })
      }

      function mobileProductZoom(product, packageImageIndex) {
         return $uibModal.open({
            controller: 'MobileProductZoomCtrl',
            windowTopClass: 'js-mobileProductZoom',
            resolve: {
               ctrl: resolveFiles([
                  'app.shop/mobile_product_zoom.controller.js',
                  'https://cdnjs.cloudflare.com/ajax/libs/jquery.panzoom/4.0.0/panzoom.min.js',
               ]),
               product: function () {
                  return product
               },
               packageImageIndex: function () {
                  return parseInt(packageImageIndex)
               },
            },
            templateUrl: 'app.shop/modal.mobile_product_zoom.htm',
         })
      }

      function orderStatusChangePrompt() {
         return $uibModal.open({
            keyboard: true, // allow escape-key close
            size: 'md',
            templateUrl: 'partials/modal.order_status_change_prompt.htm',
         })
      }

      function guardDropRemoval(args) {
         return $uibModal.open({
            keyboard: true,
            size: 'md',
            templateUrl: 'partials/modal.guard_drop_removal.htm',
            controller: function ($scope) {
               $scope.ordersPlaced = args.ordersPlaced
               $scope.ordersProcessing = args.ordersProcessing
            },
         })
      }

      function packagingOptions(product, filterDescription, parentFeaturePath) {
         return $uibModal.open({
            size: 'md',
            templateUrl: 'partials/modal.options_select.htm',
            windowTopClass: 'Modal--cursorZoomOut',
            backdrop: true, // allow off-click close
            keyboard: true, // allow escape-key close
            controller: function ($scope, appState) {
               $scope.featurePath = parentFeaturePath + ':packagingOptions'
               $scope.product = product
               $scope.filterDescription = filterDescription
               $scope.toggleOutline = function () {
                  $scope.outline = !$scope.outline
               }
               if (product.selectedPackagingOrDefault.isOutOfStockOn(appState.pickDate)) {
                  product
                     .hasActiveSubstitution({
                        inStockOn: appState.pickDate,
                     })
                     .then(function (hasSubs) {
                        $scope.outAndHasSubs = hasSubs
                     })
               }
            },
         })
      }

      function placingOrderAsGuestUpgradePrompt(parentFeaturePath) {
         return $uibModal.open({
            size: 'md',
            keyboard: true, // allow escape-key close
            templateUrl: 'partials/modal.placing_order_as_guest_upgrade_prompt.htm',
            controller: function ($scope, appState) {
               $scope.featurePath = parentFeaturePath + ':placingOrderAsGuestUpgradePrompt'
               $scope.promoCodes = appState.activeOrderState.order.codesFromPromoCodeViews
            },
         })
      }

      function productSubstitutions(options, parentFeaturePath) {
         var modalInstance = $uibModal.open({
            windowClass: 'Modal-overlay Modal-overlay--allowSibling',
            size: 'lg',
            controller: 'ProductSubstitutionsCtrl',
            windowTopClass: 'Modal--cursorZoomOut',
            backdrop: true, // allow off-click close
            keyboard: true, // allow escape-key close
            resolve: {
               ctrl: resolveFiles('app.common/product_substitutions.controller.js'),
               options: function () {
                  return options
               },
               featurePath: function () {
                  return parentFeaturePath + ':productSubstitutionsModal'
               },
            },
            templateUrl: 'partials/modal.product-substitutions.htm',
         })
         return modalInstance
      }

      function promptUserPassword() {
         return $uibModal.open({
            size: 'xs',
            templateUrl: 'partials/modal.password_prompt.htm',
         })
      }

      function resetPassword(username) {
         return $uibModal.open({
            size: 'md',
            keyboard: true, // allow escape-key close
            templateUrl: 'partials/modal.reset_password.htm',
            controller: 'ResetPasswordCtrl',
            resolve: {
               ctrl: resolveFiles('app.common/reset_password.controller.js'),
               username: function () {
                  return username
               },
            },
         })
      }

      function rewardsBreakdown(orderState) {
         return $uibModal.open({
            size: '770px', // custom max-width (requires one-off class – `.modal-{size}`)
            templateUrl: 'partials/modal.rewards-breakdown.htm',
            windowTopClass: 'Modal--cursorZoomOut',
            keyboard: true, // allow escape-key close
            backdrop: true, // allow off-click close
            controller: function ($scope) {
               $scope.orderState = orderState
            },
         })
      }

      function rewardsExplainer(isProgramIntroduction) {
         return $uibModal.open({
            size: '600px', // custom max-width (requires one-off class – `.modal-{size}`)
            templateUrl: 'partials/modal.rewards-explainer.htm',
            windowTopClass: 'Modal--cursorZoomOut',
            keyboard: true, // allow escape-key close
            backdrop: true, // allow off-click close
            controller: function ($scope, appState) {
               $scope.isProgramIntroduction = isProgramIntroduction
               $scope.standardRate = appState.userState.priceSettings.rewardsRate
               $scope.bonusRate = $scope.standardRate + 5
            },
         })
      }

      function selectPaymentMethod(orderState) {
         return $uibModal.open({
            controller: function ($scope) {
               $scope.orderState = orderState
            },
            size: 'md',
            windowTopClass: 'Modal--cursorZoomOut',
            keyboard: true, // allow escape-key close
            backdrop: true, // allow off-click close
            templateUrl: 'partials/modal.payment_method_select.htm',
         })
      }

      function selectShipment(options) {
         /*
         options = {
            parentFeaturePath
            orderState
            context: 'newOrder' || 'editOrder'
            // Note: This 'context' property isn't strictly necessary, as we're not yet supporting
            // multiple open orders, but since this allows for more flexibility it seems preferable.
         }
         */
         return $uibModal.open({
            size: 'lg',
            windowTopClass: 'Modal--cursorZoomOut',
            keyboard: true, // allow escape-key close
            backdrop: true, // allow off-click close
            templateUrl: 'partials/modal.shipment_select.htm',
            controller: function ($scope) {
               $scope.featurePath = options.parentFeaturePath + ':selectShipmentModal'
               $scope.orderState = options.orderState
               $scope.context = options.context
               $scope.changeShipment = function (destination) {
                  return $scope.orderState.changeShipment(destination)
               }
            },
         })
      }

      function signIn(parentFeaturePath) {
         return $uibModal.open({
            size: 'md',
            keyboard: true, // allow escape-key close
            templateUrl: 'partials/modal.sign_in.htm',
            controller: function ($scope) {
               $scope.featurePath = parentFeaturePath + ':signInModal'
            },
         })
      }

      function settleOutstandingBalance(actionString) {
         return $uibModal.open({
            size: 'md',
            keyboard: true, // allow escape-key close
            templateUrl: 'partials/modal.outstanding_balance.htm',
            controller: function ($scope, appState, accountEntryService) {
               accountEntryService.getAccountBalance(appState.userState.id).then(function (accountBalance) {
                  $scope.accountBalance = accountBalance
               })

               $scope.actionString = actionString
               $scope.makePaymentModal = function () {
                  return makePayment($scope.accountBalance).result
               }
            },
         })
      }

      function shipmentChangeItemRemovalPrompt(err) {
         return $uibModal.open({
            /* @ngInject */
            controller: function ($scope, dropData) {
               $scope.itemsToBeChanged = err.data.changes

               // Note that if the new destination is a drop then no address is returned (only the drop ID)
               if (err.data.order.parcelCarrierDeliveryAddress) {
                  $scope.parcelCarrierDeliveryAddressToUpdateTo = err.data.order.parcelCarrierDeliveryAddress
               } else if (err.data.order.drop) {
                  // Note that the drop here is being loaded in the background and we're not showing any type of loading
                  // indicator in the UI. This seems acceptable because the drop should already be in the runtime cache
                  // (in which case there's no content layout shift). However, if it's not cached for some reason then
                  // a content layout shift does happen (test this by passing `true` as a second parameter here to bypass
                  // the cache).
                  dropData.getDrop(err.data.order.drop).then(function (drop) {
                     $scope.dropToUpdateTo = drop
                  })
               }
            },
            templateUrl: 'partials/modal.shipment_change_item_removal_prompt.htm',
         })
      }

      function freightHandlingRequired() {
         return $uibModal.open({
            size: 'md',
            backdrop: true, // allow off-click close
            keyboard: true, // allow escape-key close
            templateUrl: 'partials/modal.freight_item_info.htm',
         })
      }

      function lateUpdateToD2h(d2hEstimates) {
         return $uibModal.open({
            size: '500px',
            templateUrl: 'partials/modal.late_update_to_d2h.htm',
            /* @ngInject */
            controller: function ($scope) {
               $scope.d2hEstimates = d2hEstimates

               $scope.onD2hEstimateClick = function (estimate, index) {
                  $scope.selectedEstimateIndex = index
                  $scope.selectedEstimate = estimate
               }
            },
         })
      }

      //================================================================================

      return {
         // Modals - Generic
         prompt: prompt,
         alert: alert,
         textarea: textarea,
         textInput: textInput,
         video: video,

         // Modals - Specific
         addSmsUi: addSmsUi,
         addPaymentMethod: addPaymentMethod,
         addressBook: addressBook,
         addressForm: addressForm,
         dcGuardEmailListCopy: dcGuardEmailListCopy,
         cameraScanner: cameraScanner,
         dropToHomeCompleteDelivery: dropToHomeCompleteDelivery,
         cart: cart,
         closeCart: closeCart,
         checkForNewVersion: checkForNewVersion,
         confirmDelete: confirmDelete,
         confirmJoiningClosedDrop: confirmJoiningClosedDrop,
         insufficientStockUi: insufficientStockUi,
         itemPriceChangesPrompt: itemPriceChangesPrompt,
         makePayment: makePayment,
         mobileProductZoom: mobileProductZoom,
         orderStatusChangePrompt: orderStatusChangePrompt,
         guardDropRemoval: guardDropRemoval,
         packagingOptions: packagingOptions,
         placingOrderAsGuestUpgradePrompt: placingOrderAsGuestUpgradePrompt,
         productSubstitutions: productSubstitutions,
         promptUserPassword: promptUserPassword,
         resetPassword: resetPassword,
         rewardsBreakdown: rewardsBreakdown,
         rewardsExplainer: rewardsExplainer,
         selectPaymentMethod: selectPaymentMethod,
         selectShipment: selectShipment,
         settleOutstandingBalance: settleOutstandingBalance,
         shipmentChangeItemRemovalPrompt: shipmentChangeItemRemovalPrompt,
         signIn: signIn,
         freightHandlingRequired: freightHandlingRequired,
         lateUpdateToD2h: lateUpdateToD2h,
      }
   }
})(angular, window.Azure)
