;(function (angular) {
   'use strict'

   /**
    * @ngdoc directive
    * @name app.directive:asProductListItem
    * @description
    * # asProductListItem
    * Display a single item in list of products as a list item.
    */

   angular.module('app').directive('asProductListItem', productListItem)

   function productListItem() {
      return {
         controller: productListItemController,
         scope: {
            product: '=',
            productList: '@?',
            position: '=',
            parentFeaturePath: '@',
            showAllSizes: '=',
         },
         replace: true,
         templateUrl: 'partials/product_list_item.htm',
      }
   }

   function productListItemController($scope) {
      $scope.featurePath = $scope.parentFeaturePath + ':productListItem'
      $scope.packaging = $scope.showAllSizes ? $scope.product.packaging : $scope.product.filteredPackaging
   }
})(angular)
