;(function (angular, undefined) {
   'use strict'

   angular.module('app').directive('asAddressForm', addressForm)

   function addressForm() {
      return {
         controller: addressFormController,
      }
   }

   function addressFormController(
      $scope,
      $q,
      $filter,
      $timeout,
      $http,
      addressService,
      alerts,
      modalService,
      regionData,
      countryData,
      appState,
      config
   ) {
      var _maxAutocompleteResultCount = 10
      var _existingTextNumber = $scope.address.text
      var _isEditingExistingAddress = $scope.address.id
      var _autocompleteInput

      $scope.addressMatches = []
      $scope.localState = {
         selectedRegion: undefined,
         selectedCountry: undefined,
         selectedAutocompleteIndex: undefined,
         isAddressAutocompleteInputFocused: false,
         allowShowingAddressAutocomplete: false,
      }
      var _localState = $scope.localState

      //================================================================================
      // Address autocomplete handling
      //================================================================================

      function queryMapbox(query) {
         var mapboxGeocodeParams = {
            q: encodeURIComponent(query),
            country: 'US', // Limits results to the United States
            autocomplete: true,
            limit: _maxAutocompleteResultCount, // Max number of results to return (default is 5, max 10).
            types: 'address',
            access_token: config.mapbox.tokenForAddressAutocomplete,
         }

         return $http
            .get(
               config.mapbox.geocodeUrlV6 +
                  '?' +
                  Object.keys(mapboxGeocodeParams)
                     .map(function (key) {
                        return key + '=' + mapboxGeocodeParams[key]
                     })
                     .join('&')
            )
            .then(function (response) {
               if (response.data.features.length) {
                  return $q.resolve(response.data.features)
               }
            })
      }

      // Note: We're effectively using a custom debounce approach here (instead of something
      // like `ng-model-options="{debounce: 300}"` in the template) because we want the input's
      // value to always be up to date (without being debounced).
      // This allows things like "enter" and "escape" key presses to happen immediately while
      // still preventing excess calls to Mapbox.

      var _searchQueryTimeout
      $scope.searchQueryChanged = function () {
         $timeout.cancel(_searchQueryTimeout)

         if (!$scope.address['street-address'] || !$scope.address['street-address'].length) {
            $scope.addressMatches = []
            return
         }

         _searchQueryTimeout = $timeout(handleSearchQueryChange, 300)
      }

      var _previousSearchQuery
      function handleSearchQueryChange() {
         // Exit early if the input is not focused (to prevent unnecessary calls to Mapbox) (handles browser auto-filling the input)
         if (!_localState.isAddressAutocompleteInputFocused) {
            return
         }

         var queryInput = $scope.address['street-address']
         if (!queryInput) {
            return
         }

         var query = queryInput.trim()
         if (!query) {
            return
         }

         // Exit early if the query hasn't changed
         if (query === _previousSearchQuery) {
            return
         }
         _previousSearchQuery = query

         // Guard for at least two tokens
         if (query.split(/\s+/).length < 2) {
            return
         }

         queryMapbox(query).then(function (addressMatches) {
            $scope.addressMatches = addressMatches
         })
      }

      function getMaybeFocusedEntry() {
         return _localState.selectedAutocompleteIndex !== undefined && $scope.addressMatches.length
            ? $scope.addressMatches[_localState.selectedAutocompleteIndex]
            : undefined
      }

      $scope.onSearchKeydown = function ($event) {
         if (!$scope.addressMatches || !$scope.addressMatches.length) {
            return
         }

         if ($event.key === 'Escape') {
            $event.preventDefault()
            _localState.allowShowingAddressAutocomplete = false
            _localState.selectedAutocompleteIndex = undefined
            return
         }

         if ($event.key === 'Enter') {
            $event.preventDefault()
            if (_localState.allowShowingAddressAutocomplete) {
               selectAutocompleteEntry(getMaybeFocusedEntry(), true)
            }
            return
         }

         if ($event.key === 'Tab') {
            if (_localState.allowShowingAddressAutocomplete) {
               selectAutocompleteEntry(getMaybeFocusedEntry(), false)
            }
            return
         }

         if ($event.key === 'ArrowUp' || $event.key === 'ArrowDown') {
            $event.stopPropagation()
            $event.preventDefault()

            if (!_localState.allowShowingAddressAutocomplete) {
               return
            }

            var searchQueriesLength = Math.min($scope.addressMatches.length, _maxAutocompleteResultCount)
            var oldIndex = _localState.selectedAutocompleteIndex

            if (oldIndex === undefined) {
               _localState.selectedAutocompleteIndex = $event.key === 'ArrowDown' ? 0 : searchQueriesLength - 1
            } else {
               var maybeNewIndex = $event.key === 'ArrowDown' ? oldIndex + 1 : oldIndex - 1
               if (maybeNewIndex < 0) {
                  _localState.selectedAutocompleteIndex = searchQueriesLength - 1
               } else if (maybeNewIndex >= searchQueriesLength) {
                  _localState.selectedAutocompleteIndex = 0
               } else {
                  _localState.selectedAutocompleteIndex = maybeNewIndex
               }
            }

            return
         }

         // If alphanumeric key was pressed, allow showing the autocomplete
         if (/^[a-z0-9]$/i.test($event.key)) {
            _localState.allowShowingAddressAutocomplete = true
         }
      }

      $scope.onSearchFocus = function () {
         _localState.isAddressAutocompleteInputFocused = true
         // Remove autocomplete from the input to prevent plugin autocomplete UIs from conflicting with ours
         enableOrDisableLine1PluginAutocomplete()
      }

      $scope.onSearchBlur = function () {
         // This timeout is necessary for the click event (on the suggested address) to be registered before the blur event
         $timeout(function () {
            // This effectively closes the autocomplete
            _localState.isAddressAutocompleteInputFocused = false

            // Clear the selected autocomplete index (to prevent showing a selection if/when the input is focused again)
            _localState.selectedAutocompleteIndex = undefined

            // Re-enable autocomplete
            enableOrDisableLine1PluginAutocomplete(true)
         }, 100)
      }

      function enableOrDisableLine1PluginAutocomplete(shouldEnable) {
         // Note that this only handles plugins (we still want the browser's native autocomplete to work).
         // Also note that the reason we are programmatically enabling/disabling plugin autocomplete
         // is because this is necessary for them to autocomplete the address-line-1 field when
         // selecting an autocomplete option via a different field.
         if (!_autocompleteInput) {
            return
         }
         if (shouldEnable) {
            _autocompleteInput.removeAttribute('data-1p-ignore')
            _autocompleteInput.removeAttribute('data-lpignore')
            _autocompleteInput.removeAttribute('data-bwignore')
            _autocompleteInput.classList.remove('keeper-ignore')
         } else {
            _autocompleteInput.setAttribute('data-1p-ignore', '')
            _autocompleteInput.setAttribute('data-lpignore', 'true')
            _autocompleteInput.setAttribute('data-bwignore', '')
            _autocompleteInput.classList.add('keeper-ignore')
         }
      }

      $scope.hoveredSuggestedAddress = function ($index) {
         _localState.selectedAutocompleteIndex = $index
      }

      $scope.clickedSuggestedAddress = function (addressMatch) {
         return selectAutocompleteEntry(addressMatch, true)
      }

      function selectAutocompleteEntry(addressMatch, maybeFocusPhoneInput) {
         if (!addressMatch) {
            return
         }

         // Set the address fields based on the selected address match
         $scope.address['street-address'] = addressMatch.properties.context.address.name
         $scope.address.locality = addressMatch.properties.context.place.name
         $scope.address['postal-code'] = addressMatch.properties.context.postcode.name

         // Note that here we're guarding here to ensure that the region data has loaded.
         _localState.selectedRegion =
            $scope.regions &&
            $scope.regions.find(function (region) {
               return addressMatch.properties.context.region.region_code === region.abbreviation
            })
         // Note that here we're guarding here to ensure that the country data has loaded.
         _localState.selectedCountry =
            $scope.countries &&
            $scope.countries.find(function (country) {
               return addressMatch.properties.context.country.country_code_alpha_3 === country.iso3
            })

         // If the phone input is empty and the "same for calls and text" option is checked (which is default), set
         // it via the user's phone.
         if (!$scope.ui.phoneFormatted && $scope.ui.syncTextToPrimary && appState.userState.user.phone) {
            $scope.ui.phoneFormatted = addressService.stripCountryFromPhone(
               addressService.cleanPhone(appState.userState.user.phone)
            )
         }

         // Maybe focus the phone input (which still needs to be entered manually)
         if (maybeFocusPhoneInput && $scope.includePhone && !_isEditingExistingAddress && !$scope.ui.phoneFormatted) {
            var phoneInput = document.getElementById('js-phoneInput')
            if (phoneInput) {
               phoneInput.focus()
            }
         } else {
            // Effectively close the autocomplete
            $scope.addressMatches = []
         }
      }

      //================================================================================

      $scope.submitAddressForm = function (invalid) {
         if (invalid) {
            alerts.error({
               message: 'Your address contains one or more errors. Fix them and try saving again.',
               autoClose: true,
            })
            return $q.reject()
         }

         $scope.address.country = _localState.selectedCountry.name
         $scope.address.region = _localState.selectedRegion ? _localState.selectedRegion.name : undefined

         if ($scope.includePhone) {
            if ($scope.ui.maybeFormatPhone) {
               $scope.address.phone = addressService.addCountryToPhone(
                  addressService.cleanPhone($scope.ui.phoneFormatted)
               )
            }

            // Maybe set text number via primary phone, otherwise clean up the value entered for text
            if ($scope.ui.syncTextToPrimary) {
               $scope.address.text = $scope.address.phone
            } else if ($scope.address.text) {
               $scope.address.text = addressService.addCountryToPhone(addressService.cleanPhone($scope.address.text))
            } else {
               $scope.address.text = undefined
            }

            // Prompt if this is the primary address and the text number has been removed
            if ($scope.isPrimary && _existingTextNumber && !$scope.address.text) {
               var promptModalOptions = {
                  heading: 'Heads up!',
                  message:
                     'Saving your primary address with no text number will deactivate any text notifications you may have set.',
               }
               return modalService.prompt(promptModalOptions).result.then(function () {
                  return $scope.address
               })
            }
         }

         return $q.resolve($scope.address)
      }

      //================================================================================
      // Init
      //================================================================================

      function setRegions() {
         if (!_localState.selectedCountry) {
            return
         }
         regionData.getRegionsByCountryIso3(_localState.selectedCountry.iso3).then(function (regions) {
            $scope.regions = regions
            if ($scope.address.region) {
               _localState.selectedRegion = $filter('filter')($scope.regions, {
                  name: $scope.address.region,
               })[0]
            }
         })
      }
      $scope.setRegions = setRegions

      function initCountriesAndRegions() {
         countryData.getCountries().then(function (countries) {
            $scope.countries = countries
            _localState.selectedCountry = $filter('filter')(countries, {
               name: $scope.address.country,
            })[0]
            setRegions()
         })
      }

      function initPhoneFormatting() {
         // Check to see if existing number is a special case and should not be formatted
         $scope.ui.maybeFormatPhone = $filter('phone')($scope.address.phone).length === 14

         // Maybe "Use same number for texting and phone calls" - TRUE if phone and text are equal (including empty)
         $scope.ui.syncTextToPrimary =
            (!$scope.address.text && !$scope.address.phone) || $scope.address.text === $scope.address.phone

         if ($scope.address.text) {
            $scope.address.text = addressService.stripCountryFromPhone(addressService.cleanPhone($scope.address.text))
         }

         if ($scope.address.phone) {
            $scope.ui.phoneFormatted = addressService.stripCountryFromPhone(
               addressService.cleanPhone($scope.address.phone)
            )
         }
      }

      function initPhoneWatcher() {
         $scope.$watch('ui.syncTextToPrimary', function (syncTextToPrimary) {
            // When using the same number for text and phone:
            if (syncTextToPrimary) {
               // the phone number must be formatted
               $scope.ui.maybeFormatPhone = true
               // clear the text number since it will be synced when the form is submitted
               $scope.address.text = ''
            }
         })
      }

      function initPhoneSupport() {
         $scope.ui = {
            syncTextToPrimary: true,
            phoneFormatted: undefined,
            maybeFormatPhone: true,
         }
         initPhoneFormatting()
         initPhoneWatcher()
      }

      //================================================================================
      // Initialization
      //================================================================================

      function init() {
         initCountriesAndRegions()
         if ($scope.includePhone) {
            initPhoneSupport()
         }
         // If creating a new address, focus the autocomplete input
         if (!_isEditingExistingAddress) {
            $timeout(function () {
               // If the name field is empty, set it via the user's name
               if (!$scope.address.name && appState.userState.user.name) {
                  $scope.address.name = appState.userState.user.name
               }

               _autocompleteInput = document.getElementById('js-addressAutocompleteInput')
               if (_autocompleteInput) {
                  _autocompleteInput.focus()
               }
            })
         }
      }

      init()
   }
})(angular)
