;(function (angular, undefined) {
   'use strict'

   angular.module('app').factory('packagedProductTagService', packagedProductTagService)

   function packagedProductTagService(util) {
      // NOTE:
      // "tag" is from the backend.
      // The api for all tags is https://api.azurestandard.com/packaged-product-tags
      // "label" is for the frontend, created by mapping tags (tag slugs) to labels (via label slugs).

      //================================================================================
      // Labels
      //================================================================================

      var _labels = Object.freeze({
         discontinued: {
            iconClass: 'icon-ban-circle',
            'background-color': '#E03621',
            name: 'Discontinued',
            initial: 'D',
         },
         'soon-to-be-discontinued': {
            'background-color': '#686868',
            name: 'Soon Discontinued',
            initial: 'SD',
         },
         'in-house': {
            iconClass: 'icon-house',
            'background-color': '#0b99d4',
            name: 'In House',
            initial: 'IH',
         },
         'out-for-season': {
            iconClass: 'icon-alert',
            'background-color': '#E65D20',
            name: 'Out for Season',
            initial: 'OFS',
         },
         'out-long-term': {
            iconClass: 'icon-alert',
            'background-color': '#E34A20',
            name: 'Out (Long-Term)',
            initial: 'OLT',
         },
         'bargain-bin': {
            iconClass: 'icon-tag',
            'background-color': '#7A9039',
            name: 'Bargain Bin',
            initial: 'BB',
         },
         new: {
            'background-color': '#ECA221',
            name: 'New',
            initial: 'N',
         },
         'on-sale': {
            iconClass: 'icon-tag',
            'background-color': '#9F3122',
            name: 'On Sale',
            initial: 'S',
         },
         'not-for-sale': {
            'background-color': '#909090',
            name: 'Not For Sale',
            initial: 'NFS',
         },
      })

      //================================================================================
      // Helper Methods
      //================================================================================

      function getLabel(labelSlug) {
         var label
         if (_labels.hasOwnProperty(labelSlug)) {
            label = Object.assign({slug: labelSlug}, _labels[labelSlug])
            if (label.name === undefined) {
               label.name = labelSlug.replace(/-/g, ' ')
            }
         }
         return label
      }

      function getLabels(packageView, priceLevel) {
         var onSaleTag = 'on-sale-' + priceLevel
         var labels = packageView.tags.map(function (tagSlug) {
            var labelSlug = tagSlug === onSaleTag ? 'on-sale' : tagSlug
            return getLabel(labelSlug)
         })
         if (packageView.isNotForSale) {
            labels.push(getLabel('not-for-sale'))
         }
         return util.compact(labels)
      }

      function getCombinedLabelsAndDiscount(packages, priceLevel) {
         var onSaleTag = 'on-sale-' + priceLevel
         var labels = []
         var maxDiscountPercent = 0

         var result = {
            labels: labels,
         }

         packages.forEach(function (pack) {
            if (!pack.tags) {
               return
            }

            // Set combined labels (for overview views)
            pack.tags.forEach(function (tagSlug) {
               var isUserSaleSlug = tagSlug === onSaleTag
               var labelSlug = isUserSaleSlug ? 'on-sale' : tagSlug

               var maybeExistingLabel = util.findByPropertyValue(labels, 'slug', labelSlug)

               if (maybeExistingLabel) {
                  maybeExistingLabel.count += 1
               } else {
                  var newLabel = getLabel(labelSlug)
                  if (newLabel) {
                     newLabel.count = 1
                     labels.push(newLabel)
                  }
               }
               if (
                  isUserSaleSlug &&
                  pack.userPrice &&
                  pack.userPrice.discountPercent &&
                  pack.userPrice.discountPercent > maxDiscountPercent
               ) {
                  maxDiscountPercent = pack.userPrice.discountPercent
               }
            })
         })

         if (maxDiscountPercent) {
            result.discountPercent = maxDiscountPercent
         }

         return result
      }

      //================================================================================

      return {
         // Helper Methods
         getLabels: getLabels,
         getCombinedLabelsAndDiscount: getCombinedLabelsAndDiscount,
      }
   }
})(angular)
