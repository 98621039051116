;(function (angular, undefined) {
   'use strict'

   angular.module('app').directive('asPaymentMethodForm', paymentMethodForm)

   function paymentMethodForm() {
      return {
         controller: paymentMethodFormController,
      }
   }

   function paymentMethodFormController(
      $scope,
      $window,
      $ocLazyLoad,
      $uibModalStack,
      $q,
      addressService,
      paymentMethodData,
      paymentMethodService,
      appState,
      alerts,
      modalService
   ) {
      $scope.paymentMethodFormData = {}

      function setBillingAddress(address) {
         $scope.billingAddress = address
         $scope.paymentMethodFormData.address = address ? address.id : undefined
      }

      $scope.openAddressBookModal = function () {
         modalService.addressBook($scope.billingAddress).result.then(setBillingAddress, angular.noop)
      }

      $scope.handleNewPaymentMethodFormSubmit = function () {
         $scope.isFormBusy = true

         $window.turnstile.execute('#js-recaptcha', {
            // -----------------------------------------------------------------------------------------------------------
            // Site key (including ones for testing from https://developers.cloudflare.com/turnstile/reference/testing/)
            // Only one should be uncommented.
            // -----------------------------------------------------------------------------------------------------------
            // sitekey: '1x00000000000000000000BB', // TEST (always passes)
            // sitekey: '2x00000000000000000000BB', // TEST (always fails)
            sitekey: '0x4AAAAAAAOgWmwzpYDPGoIO', // PRODUCTION (for Turnstile entry titled "azurestandard - add payment method form")
            // -----------------------------------------------------------------------------------------------------------
            retry: 'never',
            'error-callback': function (error) {
               $scope.isFormBusy = false
               alerts.error(error)
            },
            callback: function (captchaToken) {
               function handleError(error) {
                  alerts.error(error)
                  $scope.isFormBusy = false
               }

               var redirectPath = paymentMethodService.buildStepOneFormRedirectUrl()

               var step1RequestParams = {
                  person: appState.userState.id,
                  redirectPath: redirectPath,
                  captchaToken: captchaToken,
               }

               var validatationStepIfAch

               if ($scope.isMethodTypeAch) {
                  step1RequestParams.nickname = $scope.paymentMethodFormData.nickname

                  validatationStepIfAch = paymentMethodData.validateAch(
                     appState.userState.id,
                     $scope.paymentMethodFormData['billing-routing-number'],
                     $scope.paymentMethodFormData['billing-account-number'],
                     $scope.paymentMethodFormData['billing-first-name'],
                     $scope.paymentMethodFormData['billing-last-name']
                  )
               }

               return $q
                  .resolve(validatationStepIfAch)
                  .then(function () {
                     return paymentMethodData
                        .getNmiFormSubmitUrl(step1RequestParams)
                        .then(function (nmiFormSubmitUrl) {
                           try {
                              // Trigger submit on the payment form.
                              // Note that using this approach (rather than an AJAX request) to avoid CORS issues with NMI's API.
                              var paymentMethodFormEl = document.getElementById('js-paymentMethodForm')
                              paymentMethodFormEl.action = nmiFormSubmitUrl
                              paymentMethodFormEl.submit()
                           } catch (error) {
                              console.log(error)
                              alerts.error(error)
                           }
                        })
                        .catch(handleError)
                  })
                  .catch(handleError)
            },
         })
      }

      //================================================================================
      // Initialization
      //================================================================================

      function init() {
         $scope.isTurnstileLoading = true
         $scope.isFormInitializing = true

         // Attempt to load the Turnstile (captcha) script.
         // If it fails we close the modal and display an error message (since keeping it open implies it can be filled out).
         $ocLazyLoad
            .load('https://challenges.cloudflare.com/turnstile/v0/api.js')
            .then(function () {
               $scope.isTurnstileLoading = false
            })
            .catch(function () {
               alerts.errorMessage('There was a problem opening the form. Please try again.')

               // Close the add-payment modal
               var topModal = $uibModalStack.getTop()
               if (topModal) {
                  $uibModalStack.dismiss(topModal.key)
               }
            })

         if ($scope.isMethodTypeCard) {
            // Init addresses
            addressService
               .getUserPrimaryAddress(appState.userState.id)
               .then(setBillingAddress)
               .finally(function () {
                  $scope.isFormInitializing = false
               })
         } else {
            // If not `$scope.isMethodTypeCard` then it's ACH (no need for another if check here since there are only two options)
            $scope.paymentMethodFormData['billing-account-type'] = 'checking'
            $scope.paymentMethodFormData['billing-entity-type'] = 'personal'
            $scope.isFormInitializing = false
         }
      }

      init()
   }
})(angular)
