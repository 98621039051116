;(function (angular) {
   'use strict'

   angular.module('app').directive('asHeader', header)

   function header() {
      return {
         templateUrl: 'partials/header.htm',
         controller: headerController,
      }
   }

   function headerController($scope, $rootScope, categoryData, viewService) {
      $scope.featurePath = 'header'

      //================================================================================
      // Menu (generic)
      //================================================================================

      $scope.toggleMenu = function (isOpenVarAsString) {
         if ($scope[isOpenVarAsString]) {
            $scope[isOpenVarAsString] = false
         } else {
            $scope[isOpenVarAsString] = true
         }
      }

      $scope.closeMenu = function (isOpenVarAsString) {
         $scope[isOpenVarAsString] = false
         $rootScope.removeUnderlayClass()
      }

      //================================================================================
      // Shop Menu
      //================================================================================
      $scope.childMenuOpen = false
      $scope.activeSubcategoryId = null
      $scope.childCategories = []

      $scope.resetShopMenu = function () {
         $scope.childMenuOpen = false
         $scope.childCategories = []
         $scope.closeMenu('isShopMenuOpen')
      }

      $scope.toggleChildCats = function ($event, parent) {
         $event.stopPropagation()
         $scope.childMenuOpen =
            $scope.childMenuOpen && $scope.activeParentId !== parent.id ? $scope.childMenuOpen : !$scope.childMenuOpen
         $scope.activeParentId = parent.id
         categoryData
            .getCategoriesByParent(parent.id)
            .then(viewService.toCategoryViews)
            .then(function (categoryViews) {
               $scope.childCategories = categoryViews
            })
      }
   }
})(angular)
