;(function (angular) {
   'use strict'

   angular.module('app').directive('asQuantityBtnGroup', quantityBtnGroup)

   function quantityBtnGroup() {
      return {
         controller: quantityBtnGroupController,
         templateUrl: 'partials/quantity_btn_group.htm',
         scope: {
            orderLine: '=?',
            pack: '=',
            buttonClick: '&',
            inOrderItem: '@?',
            editorAlignment: '@?',
            parentFeaturePath: '@',
         },
      }
   }

   function quantityBtnGroupController($scope, $timeout, $rootScope, appState, alerts) {
      var _localState = {
         showQuantityEditor: false,
         showConfirmDelete: false,
         quantityInputValue: null,
      }
      $scope.localState = _localState

      //================================================================================
      // Scope functions
      //================================================================================

      $scope.openQuantityEditor = openQuantityEditor
      $scope.closeQuantityEditor = closeQuantityEditor
      $scope.deleteItem = deleteItem
      $scope.closePromptOverlay = closePromptOverlay
      $scope.promptToDelete = promptToDelete

      $scope.shouldDisable = function () {
         return (
            !appState.activeOrderState ||
            !appState.activeOrderState.canShop ||
            ($scope.orderLine && !$scope.orderLine.productView)
         )
      }

      $scope.quantityEditorSubmit = function () {
         var oldQuantity = getCurrentQuantity()
         var newQuantity = _localState.quantityInputValue

         closeQuantityEditor()

         var childFeature = 'quantityEditor'
         $scope.childFeature = childFeature
         var featurePath = $scope.parentFeaturePath + ':' + childFeature

         if (newQuantity === oldQuantity) {
            alerts.info({
               message: 'No quantity change made.',
               autoClose: true,
               closeBtn: false,
            })
         } else if (newQuantity === 0) {
            promptToDelete()
         } else if ($scope.orderLine) {
            appState.activeOrderState.updateOrderLineQuantity($scope.orderLine, newQuantity, featurePath)
         } else {
            appState.activeOrderState.updateItemQuantity($scope.pack, oldQuantity, newQuantity, featurePath)
         }
      }

      $scope.increaseQuantity = function () {
         if ($scope.pack.busy) {
            return
         }

         if ($scope.buttonClick) {
            $scope.buttonClick()
         }

         var childFeature = 'increaseQuantity'
         $scope.childFeature = childFeature
         var featurePath = $scope.parentFeaturePath + ':' + childFeature

         if ($scope.orderLine) {
            appState.activeOrderState.incrementOrderLine($scope.orderLine, featurePath)
         } else {
            appState.activeOrderState.addItem($scope.pack, 1, false, featurePath)
         }
      }

      $scope.decreaseQuantity = function () {
         if ($scope.pack.busy) {
            return
         }

         if ($scope.buttonClick) {
            $scope.buttonClick()
         }

         var childFeature = 'decreaseQuantity'
         $scope.childFeature = childFeature
         var featurePath = $scope.parentFeaturePath + ':' + childFeature

         if (getCurrentQuantity() <= 1) {
            promptToDelete()
         } else if ($scope.orderLine) {
            appState.activeOrderState.decrementOrderLine($scope.orderLine, featurePath)
         } else {
            appState.activeOrderState.removeItem($scope.pack, 1, featurePath)
         }
      }

      //================================================================================
      // Internal functions
      //================================================================================

      function openQuantityEditor() {
         if ($scope.buttonClick) {
            $scope.buttonClick()
         }
         _localState.showQuantityEditor = true

         // set initial quantity to current quantity
         _localState.quantityInputValue = getCurrentQuantity()

         // focus input
         $timeout(function () {
            document.getElementById('js-buyBoxQuantityInput').focus()
         })
      }

      function closeQuantityEditor() {
         _localState.showQuantityEditor = false
         $rootScope.removeUnderlayClass()
      }

      function closePromptOverlay() {
         _localState.showConfirmDelete = false
         $rootScope.removeUnderlayClass()
      }

      function promptToDelete() {
         _localState.showConfirmDelete = true
         // focus (for keyboard support)
         $timeout(function () {
            document.getElementById('js-buyBoxQuantityDeleteConfirm').focus()
         })
      }

      function deleteItem() {
         var featurePath = [$scope.parentFeaturePath, $scope.childFeature, 'deleteItem'].join(':')
         if ($scope.orderLine) {
            appState.activeOrderState.deleteOrderLine($scope.orderLine, featurePath)
         } else {
            appState.activeOrderState.removeItem($scope.pack, getCurrentQuantity(), featurePath)
         }
      }

      function getCurrentQuantity() {
         var currentQuantity = $scope.orderLine
            ? $scope.orderLine['quantity-ordered']
            : appState.activeOrderState.order.getQuantity($scope.pack.code)
         return currentQuantity
      }
   }
})(angular)
